import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";
import { Typography, Paper, Box, List, ListItem, ListItemText, ListItemIcon, Divider, Button } from '@material-ui/core';
import { ArrowForward, Add } from '@material-ui/icons';

import { fetchMyEvents } from "../Actions/coreActions";

var moment = require('moment');

class EventsList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMyEvents(this.props.currentUser.uid));
  }

  render() {
    var events = this.props.myEvents;

    function EventsList({events}) {
      if(events.length > 0) {
        const eventsList = events.map((event) => {
          let runningLink = event.stageNumber === 2 ? "/events/" + event.id + "/pods" : "/events/" + event.id + "/round/" + (event.stageNumber - 2);
          if (event.structure === "Single-elimination") {
            runningLink = "/events/" + event.id + "/single-elimination"
          }
          return (
            <ListItem
              button
              key={event.id}
              component={Link}
              to={event.stage !== "Running" ? "/events/" + event.id : runningLink}
            >
              <ListItemText
                primary={event.title}
                secondary={moment(event.date.toDate()).fromNow() + " | " + event.stage + " | " + event.location}
              />
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
            </ListItem>
          )
        }
        );
        return (
          <List>{eventsList}</List>
        );
      } else {
        return (
          <Box p={2}>
            <Typography>You don't have any events planned</Typography>
          </Box>
        )
      }
    }

    return (
      <Box mb={4}>
        <Paper>
          <Box p={2}>
            <Typography variant="h2">Current events</Typography>
          </Box>
          <Divider />
          {this.props.isFetchingMyEvents ? (
            <Box display="flex" justifyContent="center" alignItems="center" p={2} width={1}>
              <CircularProgress />
            </Box>
          ):(
            <EventsList events={events} />
          )}
          <Box p={2}>
            <Button startIcon={<Add />} component={Link} to={"/new-event"} variant="contained" color="primary" href="/new-event">Create event</Button>
          </Box>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  let currentEvents = state.coreReducer.myEvents.filter(event => event.stage !== "Finished");
  return {
    currentUser: state.authReducer.currentUser,
    myEvents: currentEvents,
    isFetchingMyEvents: state.coreReducer.isFetchingMyEvents
  };
}

export default connect(mapStateToProps)(EventsList);
