import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyDhOv_nnOSidbGnJnhbgtwwLxzQp9uGTN8",
    authDomain: "mtg-event.firebaseapp.com",
    databaseURL: "https://mtg-event.firebaseio.com",
    projectId: "mtg-event",
    storageBucket: "mtg-event.appspot.com",
    messagingSenderId: "71151754174",
    appId: "1:71151754174:web:f0ce9873da195e4557b7ef",
    measurementId: "G-9TGDEH7SGC"
  };

var devFirebaseConfig = {
    apiKey: "AIzaSyCNMCJZ9bk8BpX1hn_c2tEadUBSPIC8QEk",
    authDomain: "mtg-event-dev.firebaseapp.com",
    databaseURL: "https://mtg-event-dev.firebaseio.com",
    projectId: "mtg-event-dev",
    storageBucket: "mtg-event-dev.appspot.com",
    messagingSenderId: "778736202988",
    appId: "1:778736202988:web:de42074d8be02e47aea1c9",
    measurementId: "G-9TGDEH7SGC"
}

  if (process.env.NODE_ENV !== 'development') {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.initializeApp(firebaseConfig);
  }

  export default firebase;
