import React, { Component } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@material-ui/core';

class MatchesListForPrint extends Component {

  render() {

    return (
      <Box p={5}>
        <Box mb={2}>
          <Typography variant="h2">{this.props.title}</Typography>
          <Box mt={1}>
            <Typography>Round {this.props.round}</Typography>
          </Box>
        </Box>
        {this.props.currentMatches.map((match, index) => {
          if (match.player2.name === "Bye") {
            return (
              <Box mb={2}>
                <Typography>Byes</Typography>
                <Paper>
                  <Box p={2}>
                    <Typography>{match.player1.name}</Typography>
                  </Box>
                </Paper>
              </Box>
            )
          }
          return (
            <Box mb={2}>
              <Typography>Table {index+1}</Typography>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Player</TableCell>
                        <TableCell align="right">Score</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="left">Score</TableCell>
                        <TableCell align="right">Player</TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">{match.player1.name}</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="center">VS</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="right">{match.player2.name}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
          )
        })}
      </Box>
    )
  }
}

export default MatchesListForPrint;
