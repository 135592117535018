import update from 'immutability-helper';

const initialState = {
  currentEvent: {},
  isCreatingEvent: false,
  isGettingEvent: false,
  isSettingMatches: false,
  temporaryPods: null,
  matches: [],
  currentPods: [],
  error: null,
  isInvitingPlayer: false,
  invitePlayerError: null,
  currentLeague: {},
  isCreatingLeague: false,
  isGettingLeague: false,
  currentLeagueEvents: []
};

export default (state = initialState, action) => {
  let matchToUpdate;
 switch (action.type) {
  case 'CREATE_EVENT_REQUEST':
    return {
      ...state,
      isCreatingEvent: true
    };
  case 'CREATE_EVENT_SUCCESS':
    return {
      ...state,
      isCreatingEvent: false,
    };
  case "CREATE_EVENT_ERROR":
    return {
      ...state,
      isCreatingEvent: false,
      error: action.error
    };
  case 'CREATE_LEAGUE_REQUEST':
    return {
      ...state,
      isCreatingLeague: true
    };
  case 'CREATE_LEAGUE_SUCCESS':
    return {
      ...state,
      isCreatingLeague: false,
    };
  case "CREATE_LEAGUE_ERROR":
    return {
      ...state,
      isCreatingLeague: false,
      error: action.error
    };
  case "GET_EVENT_REQUEST":
    return {
      ...state,
      isGettingEvent: true
    }
  case "GET_EVENT_SUCCESS":
    return {
      ...state,
      isGettingEvent: false,
      currentEvent: action.eventData
    }
  case "GET_LEAGUE_REQUEST":
    return {
      ...state,
      isGettingLeague: true
    }
  case "GET_LEAGUE_SUCCESS":
    return {
      ...state,
      isGettingLeague: false,
      currentLeague: action.leagueData
    }
  case "SET_CURRENT_LEAGUE_EVENTS":
    return {
      ...state,
      currentLeagueEvents: action.currentLeagueEvents
    }
  case "SET_TEMPORARY_PODS":
    return {
      ...state,
      temporaryPods: action.pods
    }
  case "GET_PODS_REQUEST":
    return {
      ...state,
      isGettingPods: true
    }
  case "GET_PODS_SUCCESS":
    return {
      ...state,
      isGettingPods: false,
      currentPods: action.pods
    }
  case "SET_MATCHES_REQUEST":
    return {
      ...state,
      isSettingMatches: true,
    }
  case "SET_MATCHES_SUCCESS":
    return {
      ...state,
      isSettingMatches: false,
      matches: action.matches
    }
  case "SET_MATCHES_ERROR":
    return {
      ...state,
      isSettingMatches: false,
      error: action.error
    }
  case 'SET_MATCH_SCORE' :
    matchToUpdate = state.matches.findIndex((match => match.id === action.matchId));
    return update(state, {
      matches: {
        [matchToUpdate]: {
          [action.player]: {
            matchScore: {$set: action.score}
          }
        }
      }
    });
  case 'SET_PLAYER_DROPPED' :
    matchToUpdate = state.matches.findIndex((match => match.id === action.matchId));
    return update(state, {
      matches: {
        [matchToUpdate]: {
          [action.player]: {
            dropped: {$set: action.dropped}
          }
        }
      }
    });
  case 'REQUEST_INVITE_PLAYER' :
    return {
      ...state,
      isInvitingPlayer: true,
      invitePlayerError: null
    }
  case 'INVITE_PLAYER_SUCCESS' :
    return {
      ...state,
      isInvitingPlayer: false
    }
  case 'INVITE_PLAYER_ERROR' :
    return {
      ...state,
      isInvitingPlayer: false,
      invitePlayerError: action.error
    }
  default:
   return state
 }
}
