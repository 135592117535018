import { Button, Typography, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Box, FormControl, Select, MenuItem, Divider } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { saveMatchScore } from "../Actions/eventActions";
import TagManager from 'react-gtm-module';

function EditPairingsModal(props) {

  // let players = useSelector(state => state.playersReducer.players)
  // let currentGame = useSelector(state => state.gameReducer.currentGame)
  const [modifiedMatches, setModifiedMatches] = useState([]);

  const dispatch = useDispatch()

  useEffect(() => {
      // Update the document title using the browser API
      setModifiedMatches(props.matches)
    });

  let players = [];

  props.matches.forEach((match, i) => {
    match.player1.pod = match.pod;
    match.player2.pod = match.pod;
    players.push(match.player1)
    players.push(match.player2)
  });

  const onChange = (event, matchIndex, playerNumber, currentPlayer) => {
    let uidToMove = event.target.value;
    let playerToMove = players.find(player => player.uid === uidToMove);

    let stateCopy = modifiedMatches;
    stateCopy.forEach((match, i) => {
      if (match.player1.uid === playerToMove.uid) {
        match.player1 = currentPlayer;
      } else if (match.player2.uid === playerToMove.uid) {
        match.player2 = currentPlayer;
      }
    });
    stateCopy[matchIndex][playerNumber] = playerToMove;

    setModifiedMatches([...stateCopy])
  };

  let eventId = props.eventId;

  function updateMatches() {
    modifiedMatches.forEach((match, i) => {
      match.player1.matchScore = 0;
      match.player2.matchScore = 0;
    });

    dispatch(saveMatchScore(modifiedMatches, props.eventId))
    props.onClose();
    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "Pairings edited",
        eventCategory: "Event"
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Edit pairings"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Pairings are based on previous results. Modifying pairings can affect following rounds. All saved scores for this round will be reset.
        </DialogContentText>
        {modifiedMatches.map((match, index) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              mt={2}
              pb={2}
              key={match.id}
            >
              <FormControl>
                <Select
                  id="demo-simple-select"
                  value={match.player1.uid}
                  onChange={(event) => onChange(event, index, "player1", match.player1)}
                >
                  {players.map((player, index) => {
                    if (player.uid !== match.player2.uid && player.pod === match.player2.pod) {
                      return (
                        <MenuItem key={player.uid} value={player.uid}>{player.name}</MenuItem>
                      )
                    }
                  })}
                </Select>
              </FormControl>
              <Typography>vs</Typography>
              <FormControl>
                <Select
                  id="demo-simple-select"
                  value={match.player2.uid}
                  onChange={(event) => onChange(event, index, "player2", match.player2)}
                >
                  {players.map((player, index) => {
                    if (player.uid !== match.player1.uid && player.pod === match.player1.pod) {
                      return (
                        <MenuItem key={player.uid} value={player.uid}>{player.name}</MenuItem>
                      )
                    }
                  })}
                </Select>
              </FormControl>
              <Divider />
            </Box>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => updateMatches()} color="primary" variant="contained">
          Save and update
        </Button>
        <Button onClick={props.onClose} color="secondary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPairingsModal;
