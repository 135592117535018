import React from 'react';
import { Link } from "react-router-dom";
import { Tabs, Tab, Box, Typography, Collapse, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import AddPlayerForm from './AddPlayerForm';
import InvitePlayerForm from './InvitePlayerForm';

export default function AddPlayers(props) {
  const [value, setValue] = React.useState(0);
  const [opened, setOpened] = React.useState(true);

  const handleChange = (event, newValue) => {
    if (value === newValue) {
      setValue(false);
      setOpened(false);
    } else {
      setValue(newValue);
      setOpened(true);
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index || opened === false}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={2}>{children}</Box>}
      </Typography>
    );
  }

  return (
    <>
      <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Add custom player" />
          <Tab label="Invite player" />
      </Tabs>
      <Collapse in={opened}>
        <TabPanel value={value} index={0}>
          {!props.eventFull &&
            <Box>
              {props.hasLeague &&
                <Typography>Note that only players with an account will be included in the League standings.</Typography>
              }
              <AddPlayerForm />
            </Box>
          }
          {props.eventFull &&
            <>
              <AddPlayerForm />
              <Box mt={2}>
                <Alert
                  icon={<LockOpenIcon fontSize="inherit" />}
                  severity="info"
                  action={
                    <Button component={Link} to={"/pro"} color="inherit" size="medium" href="/pro">Learn more</Button>
                  }
                >
                  Please upgrade to Pro for unlimited participants
                </Alert>
              </Box>
            </>
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!props.eventFull &&
            <InvitePlayerForm />
          }
          {props.eventFull &&
            <>
              <InvitePlayerForm />
              <Box mt={2}>
                <Alert
                  icon={<LockOpenIcon fontSize="inherit" />}
                  severity="info"
                  action={
                    <Button component={Link} to={"/pro"} color="inherit" size="medium" href="/pro">Learn more</Button>
                  }
                >
                  Please upgrade to Pro for unlimited participants
                </Alert>
              </Box>
            </>
          }
        </TabPanel>
      </Collapse>
    </>
  )
};
