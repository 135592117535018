import React, { Component } from 'react';
import { Typography, Box, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    boxShadow: theme.shadows[2],
    borderRadius: "5px"
  },
  button: {
    backgroundColor: "#EFEFEF",
    borderRadius: "0px",
    "&disabled": {
      backgroundColor: "#EFEFEF",
    },
  },
  winner: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    transition: "background-color .3s"
  },
  number: {
    fontSize: "20px"
  }
});

class ScoreStepper extends Component {

  render() {
    let props = this.props;
    const { classes } = this.props;

    return (
      <Box className={classes.container} display="flex" alignItems="stretch">
          <IconButton className={classes.button} onClick={() => props.changeMatchScore((props.match[props.player].matchScore-1), props.match.id, props.player)} disabled={props.match[props.player].matchScore < 1} aria-label="subtract from score">
            <Remove fontSize="small"/>
          </IconButton>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" className={props.winner ? classes.winner : null} px={2}>
            <Typography className={classes.number}>{props.match[props.player].matchScore}</Typography>
          </Box>
          <IconButton className={classes.button} onClick={() => props.changeMatchScore((props.match[props.player].matchScore+1), props.match.id, props.player)} disabled={props.match[props.player].matchScore > (props.bestOf/2) || (props.match.player2.matchScore + props.match.player1.matchScore) === (props.bestOf)} aria-label="add to score">
            <Add fontSize="small" />
          </IconButton>
      </Box>
    )
  }
}

export default withStyles(styles)(ScoreStepper);
