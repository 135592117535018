import React, {Component} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

// Import components
import Home from './Containers/Home/Home';
import SignIn from './Containers/SignIn/SignIn';
import SignUp from './Containers/SignUp/SignUp';
import ResetPassword from './Containers/ResetPassword/ResetPassword';
import NewEvent from './Containers/NewEvent/NewEvent';
import NewLeague from './Containers/NewLeague/NewLeague';
import Event from './Containers/Event/Event';
import Stats from './Containers/Stats/Stats';
import League from './Containers/League/League';
import Profile from './Containers/Profile/Profile';
import UserSettings from './Containers/UserSettings/UserSettings';
import Pro from './Containers/Pro/Pro';
import NoSuchPage from './Components/NoSuchPage';
import Events from './Containers/Events/Events';
import Leagues from './Containers/Leagues/Leagues';
import ThankYou from './Containers/ThankYou/ThankYou';

import Header from "./Components/Header";
import Footer from "./Components/Footer";

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  currentUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isVerifying ? (
        <Box width={1} height={"100vh"} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : isAuthenticated ? (
        <>
          <Header />
          <Box py={5}>
            <Component {...props} />
          </Box>
          <Footer />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class Routes extends Component {
  render() {
    return (
       <React.Fragment>
           <Switch>
             <ProtectedRoute exact path='/' component={Home} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute exact path='/new-event' component={NewEvent} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute exact path='/new-league' component={NewLeague} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute path='/leagues/:id' component={League} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute path='/leagues' component={Leagues} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute path='/events/:id' component={Event} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute path='/events' component={Events} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute path='/statistics' component={Stats} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute exact path='/user/settings' component={UserSettings} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute path='/user/:uid' component={Profile} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute exact path='/pro' component={Pro} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <ProtectedRoute path='/pro/thank-you' component={ThankYou} isAuthenticated={this.props.isAuthenticated} isVerifying={this.props.isVerifying} currentUser={this.props.currentUser} />
             <Route path='/signup' component={SignUp}/>
             <Route path='/signin' component={SignIn}/>
             <Route path='/reset-password' component={ResetPassword}/>
             <Route component={NoSuchPage}/>
           </Switch>
       </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    isVerifying: state.authReducer.isVerifying,
    currentUser: state.authReducer.currentUser
  };
}
export default connect(mapStateToProps)(Routes);
