import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme.js';
import history from './history';
import 'typeface-roboto';
import configureStore from './store';
import HttpsRedirect from 'react-https-redirect';
import TagManager from 'react-gtm-module';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import './index.css';

import Routes from './routes';
const store = configureStore();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const tagManagerArgs = {
    gtmId: 'GTM-PPQJ7K7',
    dataLayer: {
      event: "pageView"
    },
    dataLayerName: 'dataLayer'
}
TagManager.initialize(tagManagerArgs);

history.listen(location => {
  TagManager.dataLayer({dataLayer:{event: "pageView", page: location.pathname}});
});

class App extends Component {

  render() {
    return (
      <HttpsRedirect>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Elements stripe={stripePromise}>
            <Provider store={store}>
              <Router history={history}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>MTG Event</title>
                </Helmet>
                <Routes />
              </Router>
            </Provider>
          </Elements>
        </ThemeProvider>
      </HttpsRedirect>
    );
  }
}

export default App;
