import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

class SignInForm extends Component {

  render() {
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required')
            .email('Invalid email'),
          password: Yup.string()
            .min(6, "Password needs to be at least 6 characters")
            .required('Required'),
        })}
        onSubmit={(values, actions) => {
          this.props.onSubmit(values.email, values.password);
          actions.setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <Field disabled={this.props.isLoggingIn} component={TextField} margin="normal" fullWidth label="Email" variant="outlined" type="email" name="email" />
            <Field disabled={this.props.isLoggingIn} component={TextField} margin="normal" fullWidth label="Password" variant="outlined" type="password" name="password" />
            <Button disabled={this.props.isLoggingIn} size="large" type="submit" variant="contained" color="primary">Sign in</Button>
          </Form>
        )}
      </Formik>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.authReducer.isLoggingIn,
  };
}

export default connect(mapStateToProps)(SignInForm);
