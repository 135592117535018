import React, { Component } from 'react';
import { connect } from "react-redux";
import firebase from "../firebase.js";
import { Typography, Box, Button, Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PlayArrow, Replay } from '@material-ui/icons';

import Countdown from 'react-countdown';

import { updateEvent } from "../Actions/eventActions";

const styles = theme => ({
  snackbar: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    boxShadow: theme.shadows[10],
    borderRadius: "5px",
    zIndex: 1200,
    [theme.breakpoints.up('md')]: {
      minWidth: "300px"
    }
  },
  time: {
    fontSize: "24px",
    fontWeight: 800,
    [theme.breakpoints.up('md')]: {
      fontSize: "30px",
    }
  },
  button: {
    color: "#FFFFFF"
  }
});

class CountdownTimer extends Component {

  render() {

    const { classes } = this.props;

    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Typography>Time is up!</Typography>;
      } else {
        // Render a countdown
        return <Typography className={classes.time}>{hours > 0 && hours + ":"}{minutes}:{seconds}</Typography>;
      }
    };

    let isAdmin = this.props.currentUser.uid === this.props.currentEvent.owner;

    return (
      <Snackbar
        open={!this.props.disabled}
        className={classes.snackbar}
      >
        <Box display="flex" alignItems="center" p={1}>
          {this.props.currentEvent.roundEnds ? (
            <Countdown date={this.props.currentEvent.roundEnds.toDate()} renderer={renderer} />
          ) : (
            <Typography className={classes.time}>{this.props.currentEvent.roundTimeLimit + ":00"}</Typography>
          )}
          <Box ml={3}>
            {isAdmin &&
              <Button className={classes.button} onClick={() => this.props.startTimer(this.props.currentEvent)} disabled={this.props.disabled}>{this.props.currentEvent.roundEnds ? (<Replay />) : (<PlayArrow />)}</Button>
            }
          </Box>
        </Box>
      </Snackbar>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startTimer: (event) => {
      let t = new Date();
      t.setSeconds(t.getSeconds() + (event.roundTimeLimit * 60));
      event.roundEnds = firebase.firestore.Timestamp.fromDate(t);
      dispatch(updateEvent(event));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CountdownTimer));
