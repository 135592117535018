import React from 'react';
import { Link } from "react-router-dom";
import { IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, ListSubheader, Badge, Typography, ListItemSecondaryAction } from '@material-ui/core';
import { NotificationsNone, NotificationsActive, ChevronRight, Close } from '@material-ui/icons';
var moment = require('moment');

export default function HeaderNotificationsMenu(props) {
  const [state, setState] = React.useState({
    open: false,
  });

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props.markNotificationsAsRead(props.notifications, props.uid)
    setState({ ...state, open });
  };

  const sideList = (notifications) => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Notifications
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
              <Close />
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }
      >
      {notifications.length === 0 &&
        <ListItem
          divider
          key="blank"
        >
          <ListItemText
            secondary="Hmm... nothing to see here"
          />
        </ListItem>
      }
        {notifications.map((notification, index) => (
          <ListItem
            divider
            button
            key={notification.created}
            component={Link}
            to={notification.link}
          >
            <ListItemText
              primary={notification.title}
              secondary={
                <>
                  <Typography variant="body2">
                    {notification.body}
                  </Typography>
                  <Typography variant="overline" display="block">
                    {moment(notification.created.toDate()).fromNow()}
                  </Typography>
                </>
              }
            />
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
          </ListItem>
        )
      )}
      </List>
    </div>
  );

  const countUnreadContifications = (notifications) => {
    var unreadNotifications = 0;
    for (var i = 0; i < notifications.length; i++) {
      if(notifications[i].read === false) {
        unreadNotifications++;
      }
    }
    return unreadNotifications;
  }

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={toggleDrawer(true)}
      >
        {countUnreadContifications(props.notifications) > 0 ? (
          <Badge badgeContent={countUnreadContifications(props.notifications)} color="error">
            <NotificationsActive />
          </Badge>
        ) : (
          <NotificationsNone />
        )}
      </IconButton>
      {props.notifications &&
        <Drawer anchor="right" open={state.open} onClose={toggleDrawer(false)} elevation={1}>
          {sideList(props.notifications)}
        </Drawer>
      }
    </>
  )
};
