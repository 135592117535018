import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container, Box, Typography } from '@material-ui/core';
import AllEvents from "../../Components/AllEvents";
import {Helmet} from "react-helmet";

import { getCurrentEvent } from "../../Actions/eventActions";

class Events extends Component {

  render() {
    return (
      <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>My events | MTG Event</title>
        </Helmet>
        <Box mb={4}>
          <Typography variant="h1">Events</Typography>
          <Box mt={1}>
            <Typography variant="body2">Events are the heart of MTG Event. This is where you can create Magic tounaments, invite your playgroup and get draft pods, pairings, results and tiebreakers sorted in just a few clicks.</Typography>
          </Box>
        </Box>
        <Box>
          <AllEvents />
        </Box>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCurrentEvent: () => {
      dispatch(getCurrentEvent());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
