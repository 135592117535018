import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Box } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import * as Yup from 'yup';

class EditProfileForm extends Component {

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{
            name: this.props.currentUser.name,
            arenaId: this.props.currentUser.arenaId
           }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(2, 'Too Short!')
              .max(50, 'Too Long!')
              .required('Required'),
          })}
          onSubmit={(values) => {
            this.props.onSubmit(values);
          }}
        >
          {({ isSubmitting, setFieldValue, isValid, handleReset, values, initialValues }) => (
            <Form>
              <Field required component={TextField} margin="normal" fullWidth label="Your name" variant="outlined" type="text" name="name" />
              <Field component={TextField} margin="normal" fullWidth label="Arena ID" variant="outlined" type="text" name="arenaId" />
              <Box display="flex">
                <Box mr={1}>
                  <Button disabled={!isValid || (values === initialValues)} size="large" type="submit" variant="contained" color="primary">Update</Button>
                </Box>
                <Button disabled={(values === initialValues)} size="large" onClick={handleReset}>Reset</Button>
              </Box>
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);
