import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";
import { Typography, Box, List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

var moment = require('moment');

class LeagueEventsList extends Component {

  render() {
    var events = this.props.events;

    function EventsList({events, emptyText}) {
      if(events.length > 0) {
        const eventsList = events.map((event) =>
          <ListItem
            button
            key={event.id}
            component={Link}
            to={"/events/" + event.id}
          >
            <ListItemText
              primary={event.title}
              secondary={moment(event.date.toDate()).fromNow() + " | " + event.stage + " | " + event.location}
            />
            <ListItemIcon>
              <ArrowForward />
            </ListItemIcon>
          </ListItem>
        );
        return (
            <List>{eventsList}</List>
        );
      } else {
        return (
          <Box p={2}>
            <Typography>{emptyText}</Typography>
          </Box>
        )
      }
    }

    return (
      <>
        <Box p={2}>
          <Typography variant="h5">{this.props.title}</Typography>
        </Box>
        <Divider />
        {this.props.isFetchingMyEvents ? (
          <Box display="flex" justifyContent="center" alignItems="center" p={2} width={1}>
            <CircularProgress />
          </Box>
        ):(
          <EventsList events={events} emptyText={this.props.emptyText} />
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    myEvents: state.coreReducer.myEvents,
  };
}

export default connect(mapStateToProps)(LeagueEventsList);
