import React, { Component } from 'react';
import { connect } from "react-redux";
import history from '../../history';
import { Typography, Box, Paper, Divider } from '@material-ui/core';
import ConsoleHelper from "../../ConsoleHelper.js"

import EventSettingsForm from '../../Components/EventSettingsForm';
import EventHeader from "../../Components/EventHeader";

import { updateEvent, createPods } from "../../Actions/eventActions";
import { changeUsersCredits } from "../../Actions/authActions";

class EventSettings extends Component {

  render() {

    let isPremium = false;
    if(this.props.currentUser.premium.status === "active") {
      isPremium = true;
    }

    ConsoleHelper(this.props.currentEvent);
    return (
      <Box>
        <EventHeader event={this.props.currentEvent} />
        <Paper>
          <Box p={2}>
            <Typography variant="h4">Event settings</Typography>
            <Typography variant="body1">Please review the settings below before starting the event. These settings can not be changed later.</Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <EventSettingsForm
              onSubmit={(values) => this.props.updateEvent(values, this.props.currentEvent, isPremium, this.props.currentUser)}
            />
          </Box>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  let currentEvent = state.eventReducer.currentEvent;
  // Remove players that are not attending event
  let currentPlayers = currentEvent.players.filter(player => player.status === 'Attending')
  currentEvent.playerIds = [];
  currentPlayers.forEach(player => currentEvent.playerIds.push(player.uid));
  currentEvent.players = currentPlayers;

  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEvent: (values, currentEvent, isPremium, currentUser) => {
      values.stage = "Running";
      if (currentEvent.type === "Draft") {
        values.stageNumber = 2;
      } else {
        values.stageNumber = 3;
      }
      let updatedEvent = {
        ...currentEvent,
        ...values
      }
      if (updatedEvent.type === "Draft") {
        dispatch(updateEvent(updatedEvent));
        dispatch(createPods(updatedEvent));
        history.push("/events/" + updatedEvent.id + "/pods")
      } else {
        dispatch(createPods(updatedEvent));
        if (updatedEvent.structure === "Single-elimination") {
          history.push("/events/" + updatedEvent.id + "/single-elimination")
        } else {
          history.push("/events/" + updatedEvent.id + "/round/1")
        }
      }
      // Charge credits if needed
      if(!isPremium) {
        let creditsCost = updatedEvent.players.length - 8;
        if (creditsCost > 0) {
          dispatch(changeUsersCredits((0-creditsCost), currentUser));
        }
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSettings);
