import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box } from '@material-ui/core';
import MatchPlayerOptions from "./MatchPlayerOptions";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    boxShadow: theme.shadows[2],
    borderRadius: "5px"
  },
  winner: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    transition: "background-color .3s"
  },
  number: {
    fontSize: "20px"
  }
});

class ByePlayer extends Component {

  render() {
    let dropped = this.props.player.dropped;
    let isAdmin = this.props.currentUser.uid === this.props.currentEvent.owner;
    return (
      <Box p={2} display="flex" alignItems="center">
        {(this.props.roundHasPassed && isAdmin) &&
          <Box mr={1}>
            <MatchPlayerOptions
              togglePlayerDropped={() => this.props.togglePlayerDropped(dropped = !dropped, this.props.match.id, "player1")}
              player={this.props.player}
            />
          </Box>
        }
        <Typography variant="body1"  color={dropped ? "error" : "initial"} style={{textDecoration: dropped ? 'line-through' : 'none'}}>{this.props.player.name}</Typography>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ByePlayer));
