import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Paper, Box, Divider, Button, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactToPrint from 'react-to-print';
import Print from '@material-ui/icons/Print';
import TagManager from 'react-gtm-module';

import { changeMatchScore, saveSingleMatchScore, saveAndCalculatePoints, togglePlayerDropped, saveMatchScore } from "../Actions/eventActions";
import MatchPlayer from "./MatchPlayer";
import ByePlayer from "./ByePlayer";
import EditPairingsModal from "./EditPairingsModal";
import MatchesListForPrint from "./MatchesListForPrint";

const styles = theme => ({
  myMatch: {
    border: "2px solid #494E5C",
  }
});

class MatchesList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ownMatchSubmitted: false,
      editing: false,
      displayEditingModal: false,
      displayEditPairingsModal: false
    };
  }

  render() {
    const { classes } = this.props;
    let currentMatches = this.props.currentMatches;
    let allMatches = this.props.allMatches;
    let roundHasPassed = this.props.currentEvent.stageNumber - 2 <= this.props.round;
    let byes = [];
    let tableNumber = 0;
    if(currentMatches.length > 0) {
      return (
        <Box>
          <div style={{ display: "none" }}>
            <MatchesListForPrint
              ref={el => (this.componentRef = el)}
              currentMatches={currentMatches}
              title={this.props.currentEvent.title}
              round={this.props.round}
            />
          </div>
          {currentMatches.map((match, index) => {
            if(match.player2.uid === "Bye" || match.player1.uid === "Bye") {
              byes.push(match);
              return null;
            }
            tableNumber++;
            let isCurrentUsersMatch = (match.player1.uid === this.props.currentUser.uid || match.player2.uid === this.props.currentUser.uid);
            return (
              <Box mb={2} key={match.player1.name + match.player2.name}>
                <Typography variant="h6">Table {tableNumber}</Typography>
                <Paper className={isCurrentUsersMatch ? classes.myMatch : null}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box flexGrow="1">
                      <MatchPlayer isCurrentUsersMatch={isCurrentUsersMatch} editing={this.state.editing} winner={match.player1.matchScore > match.player2.matchScore} roundHasPassed={roundHasPassed} match={match} player="player1" changeMatchScore={this.props.changeMatchScore} bestOf={this.props.bestOf} togglePlayerDropped={this.props.togglePlayerDropped} />
                      <Divider />
                      <MatchPlayer isCurrentUsersMatch={isCurrentUsersMatch} editing={this.state.editing} winner={match.player1.matchScore < match.player2.matchScore} roundHasPassed={roundHasPassed} match={match} player="player2" changeMatchScore={this.props.changeMatchScore} bestOf={this.props.bestOf} togglePlayerDropped={this.props.togglePlayerDropped} />
                    </Box>
                  </Box>
                  <div>
                    {!this.props.isAdmin && ((match.player1.uid === this.props.currentUser.uid && (this.props.currentEvent.stageNumber - 2) <= this.props.round) || (match.player2.uid === this.props.currentUser.uid && (this.props.currentEvent.stageNumber - 2) <= this.props.round)) &&
                      <Box>
                        <Divider />
                        <Box p={2}>
                          <Button
                            onClick={
                              () => {
                                this.props.saveSingleMatchScore(match, this.props.currentEvent);
                                this.setState({ownMatchSubmitted: true});
                             }
                            }
                            disabled={(match.player1.matchScore === 0 && match.player2.matchScore === 0)}
                            variant="contained"
                            color="primary"
                          >
                            {this.state.ownMatchSubmitted ? "Result submitted" : "Submit match results"}
                          </Button>
                        </Box>
                      </Box>
                    }
                  </div>
                </Paper>
              </Box>
            )
          }
          )}
          {byes.length > 0 &&
            <Box mb={2}>
              <Typography variant="h6">Byes</Typography>
              <Paper>
                {byes.map((match, index) => {
                  if(match.player2.uid === "Bye") {
                    return (
                      <ByePlayer player={match.player1} roundHasPassed={roundHasPassed} match={match} togglePlayerDropped={this.props.togglePlayerDropped}/>
                    )
                  } else if (match.player1.uid === "Bye") {
                    return (
                      <ByePlayer player={match.player2} roundHasPassed={roundHasPassed} match={match} togglePlayerDropped={this.props.togglePlayerDropped}/>
                    )
                  }
                }
                )}
              </Paper>
            </Box>
          }
          {/* Hide buttons if round has passed */}
          {(roundHasPassed && this.props.isAdmin) &&
            <Box display="flex">
              {/* Show end event or pair next round */}
              {this.props.round === this.props.currentEvent.numberOfRounds ? (
                <Button
                  component={Link}
                  to={"/events/" + this.props.currentEvent.id + "/results"}
                  href={"/events/" + this.props.currentEvent.id + "/results"}
                  onClick={() => this.props.saveAndCalculatePoints(this.props.currentEvent, this.props.round, currentMatches, allMatches, true, false)}
                  variant="contained"
                  color="primary"
                >
                  {this.props.currentEvent.structure === "Swiss with Single-elim finals" ? ("Finish swiss rounds") : ("Finish event")}
                </Button>
              ) : (
                <Box display="flex">
                  <Button
                    component={Link}
                    to={"/events/" + this.props.currentEvent.id + "/round/" + (this.props.round+1)}
                    href={"/events/" + this.props.currentEvent.id + "/round/" + (this.props.round+1)}
                    onClick={() => this.props.saveAndCalculatePoints(this.props.currentEvent, this.props.round, currentMatches, allMatches, false, false)}
                    variant="contained"
                    color="primary"
                  >
                    Submit all results
                  </Button>
                  <Box ml={2} display={{ xs: 'none', sm: 'block' }}>
                    <ReactToPrint
                      onBeforePrint={() => {
                        const tagManagerArgs = {
                          dataLayer: {
                            genericEvent: true,
                            event: "Matches printed",
                            eventCategory: "Event"
                          }
                        }
                        TagManager.dataLayer(tagManagerArgs);
                      }}
                      trigger={() => {
                        return (
                          <IconButton size="small" aria-label="print">
                            <Print />
                          </IconButton>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                  </Box>
                </Box>
              )}
              <Box ml={2}>
                <Button
                  onClick={() => this.setState({displayEditPairingsModal: true})}
                  color="secondary"
                >
                  Edit pairings
                </Button>
              </Box>
            </Box>
          }
          {(!roundHasPassed && !this.state.editing && this.props.isAdmin) &&
            <Box>
              <Button
                component={Link}
                onClick={() => this.setState({displayEditingModal: true})}
                variant="contained"
                color="danger"
              >
                Edit results
              </Button>
            </Box>
          }
          {(this.state.editing) &&
            <Box display="flex">
              <Box mr={2}>
                <Button
                  component={Link}
                  onClick={() => this.setState({editing: false})}
                  variant="contained"
                  color="danger"
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  component={Link}
                  onClick={() => { this.props.saveAndCalculatePoints(this.props.currentEvent, this.props.round, currentMatches, allMatches, false, true); this.setState({editing: false}) }}
                  variant="contained"
                  color="primary"
                >
                  Update results
                </Button>
              </Box>
            </Box>
          }
          <EditPairingsModal
            open={this.state.displayEditPairingsModal}
            onClose={() => this.setState(prevState => ({displayEditPairingsModal: !prevState.displayEditPairingsModal}))}
            matches={currentMatches}
            players={this.props.currentEvent.players}
            eventId={this.props.currentEvent.id}
          />
          <Dialog
            open={this.state.displayEditingModal}
            onClose={() => this.setState(prevState => ({displayEditingModal: !prevState.displayEditingModal, editing: false}))}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tournaments pairings are based on previous results. This means that if you edit a completed round future pairings may be incorrect. If you're super serious about rules, you should not edit results.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState(prevState => ({displayEditingModal: !prevState.displayEditingModal, editing: false}))} color="primary">
                OK, never mind
              </Button>
              <Button onClick={() => this.setState(prevState => ({displayEditingModal: !prevState.displayEditingModal, editing: true}))} color="primary" autoFocus>
                Just let me edit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent,
    bestOf: state.eventReducer.currentEvent.bestOf,
    pods: state.eventReducer.currentPods
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeMatchScore: (score, matchId, player) => {
      dispatch(changeMatchScore(score, matchId, player));
    },
    togglePlayerDropped: (dropped, matchId, player) => {
      dispatch(togglePlayerDropped(dropped, matchId, player))
    },
    saveSingleMatchScore: (match, event) => {
      dispatch(saveSingleMatchScore(match, event));
    },
    saveAndCalculatePoints: (event, round, currentMatches, allMatches, lastRound, update) => {
      // Reset round timer
      event.roundEnds = null;
      dispatch(saveAndCalculatePoints(event, round, currentMatches, allMatches, lastRound, update));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MatchesList));
