import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Typography, Box } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import history from '../history';
import ConsoleHelper from "../ConsoleHelper.js"

import * as Yup from 'yup';

const FormikDatePicker = ({
  name,
  label,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // ConsoleHelper(rest);
  return (
      <DatePicker
        autoOk
        name={name}
        label={label}
        onChange={value => {
          setFieldValue("endDate", value._d);
        }}
        value={value}
        inputVariant="outlined"
        margin="normal"
        fullWidth
        animateYearScrolling={false}
        minDate={new Date()}
      />
  );
};

const FormikSelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <FormControl variant="outlined" margin="normal" fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          ConsoleHelper(event.target.value)
          setFieldValue("type", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={90}
      >
        <MenuItem value="Constructed">Constructed</MenuItem>
        <MenuItem value="Draft">Draft</MenuItem>
        <MenuItem value="Sealed">Sealed</MenuItem>
        <MenuItem value="Mixed">Mixed formats</MenuItem>
      </Select>
    </FormControl>
  )
}

const FormikSlider = ({
  label,
  form: { setFieldValue },
  ...rest
}) => {
  return (
    <>
      <Typography id="discrete-slider">End after {rest.field.value || 5} events</Typography>
      <Slider
        defaultValue={rest.defaultValue}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        marks
        step={rest.step}
        min={rest.min}
        max={rest.max}
        value={rest.field.value}
        onChange={(event, value) => {
          setFieldValue(rest.field.name, value);
        }}
      />
    </>
  )
}

class NewLeagueForm extends Component {

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{ title: '', type: 'Constructed', endType: 'manual', endDate: new Date(), eventNumberLimit: 5 }}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .min(2, 'Too Short!')
              .max(50, 'Too Long!')
              .required('Required'),
          })}
          onSubmit={(values) => {
            this.props.onSubmit(values);
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <Field required component={TextField} margin="normal" fullWidth label="League title" variant="outlined" type="text" name="title" />
              <Field component={FormikSelect} label="League type" name="type" />
              <Field component={TextField} multiline margin="normal" fullWidth label="Description" variant="outlined" type="text" name="description" />
              <Field component={RadioGroup} name="endType">
                <FormControlLabel
                  value="manual"
                  control={<Radio disabled={isSubmitting} />}
                  label="End league manually"
                  disabled={isSubmitting}
                  checked={values.endType === "manual"}
                  onChange={event => {
                    ConsoleHelper(event.target.value)
                    setFieldValue("endType", event.target.value);
                  }}
                />
                {/* <FormControlLabel
                  value="date"
                  control={<Radio disabled={isSubmitting} />}
                  label="End on a set date"
                  disabled={isSubmitting}
                  checked={values.endType === "date"}
                  onChange={event => {
                    ConsoleHelper(event.target.value)
                    setFieldValue("endType", event.target.value);
                  }}
                /> */}
                <FormControlLabel
                  value="numberOfEvents"
                  control={<Radio disabled={isSubmitting} />}
                  label="End after a set number of finished events"
                  checked={values.endType === "numberOfEvents"}
                  onChange={event => {
                    ConsoleHelper(event.target.value)
                    setFieldValue("endType", event.target.value);
                  }}
                />
              </Field>
              {values.endType === "date" &&
                <Field component={FormikDatePicker} label="End date" name="endDate" />
              }
              {values.endType === "numberOfEvents" &&
                <Field
                  component={FormikSlider}
                  name="eventNumberLimit"
                  unit={null}
                  defaultValue={5}
                  step={1}
                  min={2}
                  max={25}
                />
              }
              <Box my={2} display="flex">
                <Box mr={1}>
                  <Button size="large" type="submit" variant="contained" color="primary">Create league</Button>
                </Box>
                <Button size="large" variant="outlined" color="secondary" onClick={() => history.goBack()}>Cancel</Button>
              </Box>
              {this.props.error &&
                <Typography>{this.props.error.message}</Typography>
              }
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    )
  }
}


export default connect()(NewLeagueForm);
