import React, { Component } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@material-ui/core';

class PodsForPrint extends Component {

  render() {

    function Pod(pod) {
      return (
        <List>
          {pod.players.map((player, index) =>
            <ListItem
              key={player.name}
            >
              <ListItemAvatar>
                <Avatar>
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={player.name} />
            </ListItem>
          )}
        </List>
      );
    }

    return (
      <Box p={5}>
        <Box mb={2}>
          <Typography variant="h2">{this.props.title}</Typography>
          <Box mt={1}>
            <Typography>Drafting pods</Typography>
          </Box>
        </Box>
        {this.props.pods.map((pod, index) =>
          <Box mb={2}>
            <Paper key={index}>
              <Box p={2}>
                <Typography variant="h6">Pod {index + 1}</Typography>
              </Box>
              <Divider />
              <Pod players={pod} />
            </Paper>
          </Box>
        )}
      </Box>
    )
  }
}

export default PodsForPrint;
