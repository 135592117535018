import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box, Paper, Button, Backdrop, Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckoutForm from './CheckoutForm';
import { withStyles } from '@material-ui/core/styles';
import stripe from "../stripe.png";

const styles = theme => ({
  icon: {
    fontSize: "40px",
    color: theme.palette.secondary.main
  },
  button: {
    width: "100%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  purchaseContainer: {
    maxWidth: "600px"
  },
  purchasePaper: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF"
  },
  features: {
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  feature: {
    [theme.breakpoints.down('sm')]: {
      marginTop: "20px"
    }
  }
});

class CreditsBackdrop extends Component {

  render() {
    const { classes } = this.props;
    return (
        <Backdrop className={classes.backdrop} open={this.props.creditsBackdropOpen}>
          <Box
            display="flex"
            direction="row"
            justify="center"
            alignItems="center"
            textAlign="center"
            className={classes.purchaseContainer}
            px={1}
          >
            <Paper className={classes.purchasePaper}>
              <Box px={2} py={3}>
                <Typography variant="h3">{this.props.volume} MTG Event Credits</Typography>
                {this.props.purchaseSuccessful ? (
                  <Collapse in={this.props.purchaseSuccessful}>
                    <Box mt={2}>
                      <Alert severity="success">Your purchase is complete! Thank you and enjoy!</Alert>
                    </Box>
                  </Collapse>
                ) : (
                  <>
                    <Typography variant="body1">Your card will be charged ${this.props.amount}</Typography>
                    <CheckoutForm
                      product="credits"
                      volume={this.props.volume}
                      amount={this.props.amount}
                      triggerPaymentIntent={this.props.creditsBackdropOpen}
                    />
                    <Collapse in={this.props.purchaseError}>
                      <Box mt={2}>
                        <Alert severity="error">{this.props.purchaseError}</Alert>
                      </Box>
                    </Collapse>
                  </>
                )}
                <Box mt={2}>
                  <Button
                    onClick={this.props.handleClose}
                    disabled={this.props.isPurchasing}
                  >
                    Close
                  </Button>
                </Box>
                <Box my={2}>
                  <Typography variant="body2">This purchase will add {this.props.volume} credits to your account. Credits do not have an expiry date. Unused credits cannot be refunded.</Typography>
                </Box>
                <img alt="Powered by Stripe" style={{width: "130px"}} src={stripe} />
              </Box>
            </Paper>
          </Box>
        </Backdrop>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.authReducer.currentUser,
    isPurchasing: state.authReducer.isPurchasing,
    purchaseError: state.authReducer.purchaseError,
    purchaseSuccessful: state.authReducer.purchaseSuccessful
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreditsBackdrop));
