import React, { Component } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';

class ResultsTableForPrint extends Component {

  render() {

    return (
      <Box p={5}>
        <Box mb={2}>
          <Typography variant="h2">{this.props.currentEvent.title}</Typography>
          <Box mt={1}>
            <Typography>Results</Typography>
          </Box>
        </Box>
        <Box mb={2}>
          <Paper>
            <TableContainer component={Paper}>
              <Table aria-label="Results table">
                <TableHead>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && this.props.currentEvent.stage === "Swiss finished") &&
                      <TableCell align="center">Qualified</TableCell>
                    }
                    <TableCell align="right">Player</TableCell>
                    <TableCell align="right">Record</TableCell>
                    <TableCell align="right">OMW%</TableCell>
                    <TableCell align="right">GW%</TableCell>
                    <TableCell align="right">OGW%</TableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {this.props.players.map((player, index) => (
                      <TableRow key={player.name}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && this.props.currentEvent.stage === "Swiss finished") &&
                          <TableCell align="center">
                            {index + 1 <= this.props.currentEvent.numberOfPlayersInFinal && <CheckCircle color="primary" />}
                          </TableCell>
                        }
                        <TableCell align="right">{player.name}</TableCell>
                        <TableCell align="right">{player.wins} - {player.losses} - {player.draws}</TableCell>
                        <TableCell align="right">{(player.opponentMatchWinPercentage * 100).toFixed(2)} %</TableCell>
                        <TableCell align="right">{(player.gameWinPercentage*100).toFixed(2)} %</TableCell>
                        <TableCell align="right">{(player.opponentGameWinPercentage*100).toFixed(2)} %</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
      </Box>
    )
  }
}

export default ResultsTableForPrint;
