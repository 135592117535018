import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";
import { Typography, Paper, Box, List, ListItem, ListItemText, ListItemIcon, Button, Divider } from '@material-ui/core';
import { ArrowForward, Add, LockOpen } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { fetchMyLeagues } from "../Actions/coreActions";

class LeaguesList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMyLeagues(this.props.currentUser.uid));
  }

  render() {
    var leagues = this.props.myLeagues;
    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }

    function LeaguesList({leagues}) {
      if(leagues.length > 0) {
        const leaguesList = leagues.map((league) =>
          <ListItem
            button
            key={league.id}
            component={Link}
            to={"/leagues/" + league.id}
          >
            <ListItemText
              primary={league.title}
            />
            <ListItemIcon>
              <ArrowForward />
            </ListItemIcon>
          </ListItem>
        );
        return (
          <List>{leaguesList}</List>
        );
      } else {
        return (
          <Box p={2}>
            <Typography>You don't have any leagues planned</Typography>
          </Box>
        )
      }
    }

    return (
      <Box mb={4}>
        <Paper>
          <Box p={2}>
            <Typography variant="h2">Current leagues</Typography>
          </Box>
          <Divider />
          {this.props.isFetchingMyLeagues ? (
            <Box display="flex" justifyContent="center" alignItems="center" p={2} width={1}>
              <CircularProgress />
            </Box>
          ):(
            <LeaguesList leagues={leagues} />
          )}
          <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
            <Box mr={2}>
              <Button disabled={!isPremium} startIcon={<Add />} component={Link} to={"/new-league"} variant="contained" color="primary" href="/new-league">Create league</Button>
            </Box>
            {!isPremium &&
              <Alert
                icon={<LockOpen fontSize="inherit" />}
                severity="info"
                action={
                  <Button component={Link} to={"/pro"} color="inherit" size="medium" href="/pro">Learn more</Button>
                }
              >
                Upgrade to Pro to create leagues
              </Alert>
            }
          </Box>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  let currentLeagues = state.coreReducer.myLeagues.filter(league => league.stage !== "Finished");

  return {
    currentUser: state.authReducer.currentUser,
    myLeagues: currentLeagues,
    isFetchingMyLeagues: state.coreReducer.isFetchingMyLeagues,
  };
}

export default connect(mapStateToProps)(LeaguesList);
