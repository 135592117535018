import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Box, Paper, Button, Container } from '@material-ui/core';
import {Helmet} from "react-helmet";
import { withStyles } from '@material-ui/core/styles';
import firebase from "../../firebase.js"
import TagManager from 'react-gtm-module'

const styles = theme => ({
  icon: {
    fontSize: "40px",
    color: theme.palette.secondary.main
  },
  button: {
    width: "100%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  purchaseContainer: {
    maxWidth: "600px"
  },
  purchasePaper: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF"
  },
  features: {
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  feature: {
    [theme.breakpoints.down('sm')]: {
      marginTop: "20px"
    }
  }
});

class ThankYou extends Component {

  render() {
    const { classes } = this.props;

    firebase.analytics().logEvent('purchase', {
      transactions_id: this.props.currentUser.premium.subscriptionId,
      value: 5.99
    });
    const tagManagerArgs = {
      dataLayer: {
        event: "purchase"
      }
    }
    TagManager.dataLayer(tagManagerArgs);

    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }

    return (
      <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Thank you | MTG Event</title>
        </Helmet>
        <Box textAlign="center" mb={5} mt={3}>
          <Typography variant="h1">Thank you for your purchase!</Typography>
        </Box>
        <Box textAlign="center" mb={5} mt={3}>
          <Typography variant="body1">Your pro subscription is now activated.</Typography>
        </Box>
        <Box textAlign="center" mb={5} mt={3}>
        <Button component={Link} to={"/"} variant="contained" color="primary" href="/">To front page</Button>
        </Box>
      </Container>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.authReducer.currentUser,
    isPurchasing: state.authReducer.isPurchasing,
    purchaseError: state.authReducer.purchaseError,
    purchaseSuccessful: state.authReducer.purchaseSuccessful
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ThankYou));
