const initialState = {
  isFetchingMyEvents: false,
  fetchError: null,
  myEvents: [],
  isFetchingMyLeagues: false,
  myLeagues: [],
  myAdminLeagues: [],
  isFetchingMyAdminLeagues: false,
  creditsInfoBackdropOpen: false,
  purchaseBackdropOpen: false,
  clientSecret: null,
  paymentIntentId: null,
};

export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_MY_EVENTS_REQUEST':
    return {
      ...state,
      isFetchingMyEvents: true
    };
  case 'FETCH_MY_EVENTS_SUCCESS':
    return {
      ...state,
      isFetchingMyEvents: false,
      myEvents: action.events
    };
  case "FETCH_MY_EVENTS_FAILIURE":
    return {
      ...state,
      isFetchingMyEvents: false,
      fetchError: action.error
    };
  case 'FETCH_MY_LEAGUES_REQUEST':
    return {
      ...state,
      isFetchingMyLeagues: true
    };
  case 'FETCH_MY_LEAGUES_SUCCESS':
    return {
      ...state,
      isFetchingMyLeagues: false,
      myLeagues: action.leagues
    };
  case "FETCH_MY_LEAGUES_FAILIURE":
    return {
      ...state,
      isFetchingMyLeagues: false,
      fetchError: action.error
    };
  case 'FETCH_MY_ADMIN_LEAGUES_REQUEST':
    return {
      ...state,
      isFetchingMyAdminLeagues: true
    };
  case 'FETCH_MY_ADMIN_LEAGUES_SUCCESS':
    return {
      ...state,
      isFetchingMyAdminLeagues: false,
      myAdminLeagues: action.leagues
    };
  case "FETCH_MY_ADMIN_LEAGUES_FAILIURE":
    return {
      ...state,
      isFetchingMyAdminLeagues: false,
      fetchError: action.error
    };
  case "TOGGLE_CREDITS_INFO_BACKDROP":
    return {
      ...state,
      creditsInfoBackdropOpen: !state.creditsInfoBackdropOpen
    }
  case "TOGGLE_PURCHASE_BACKDROP":
    return {
      ...state,
      creditsInfoBackdropOpen: false,
      purchaseBackdropOpen: !state.purchaseBackdropOpen
    }
  case "SET_CLIENT_SECRET":
    return {
      ...state,
      clientSecret: action.clientSecret,
      paymentIntentId: action.paymentIntentId
    }
  default:
   return state
 }
}
