import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container, Box, Typography } from '@material-ui/core';
import AllLeagues from "../../Components/AllLeagues";
import {Helmet} from "react-helmet";

class Leagues extends Component {

  render() {
    return (
      <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Leagues | MTG Event</title>
        </Helmet>
        <Box mb={4}>
          <Typography variant="h1">Leagues</Typography>
          <Box mt={1}>
            <Typography variant="body2">Is one tournament not enough? Do you want to prove yourself over a set, or maybe an entire year? Connect your events to a League and start collecting points. The higher you place in a connected event, the more league points you will earn. All event participants with an account will automatically added to the connected League. Good luck!</Typography>
          </Box>
        </Box>
        <Box>
          <AllLeagues />
        </Box>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leagues);
