import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Box, Paper, Button, Backdrop, Grid, Collapse, Container, Divider, Hidden } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { GroupAdd, Timeline, FavoriteBorder, FormatListNumbered } from '@material-ui/icons';
import CheckoutForm from '../../Components/CheckoutForm';
import {Helmet} from "react-helmet";
import { withStyles } from '@material-ui/core/styles';
import mtgEventPro from "../../mtgEventPro.png";
import stripe from "../../stripe.png";
import firebase from "../../firebase.js"
import TagManager from 'react-gtm-module';

const styles = theme => ({
  icon: {
    fontSize: "40px",
    color: theme.palette.secondary.main
  },
  button: {
    width: "100%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  purchaseContainer: {
    maxWidth: "600px"
  },
  purchasePaper: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF"
  },
  features: {
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  feature: {
    [theme.breakpoints.down('sm')]: {
      marginTop: "20px"
    }
  }
});

class Pro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      backdropOpen: false
    };
  }

  render() {
    const { classes } = this.props;

    const handleToggle = () => {
      if(!this.state.backdropOpen) {

        const tagManagerArgs = {
          dataLayer: {
            genericEvent: true,
            event: "Pro checkout opened",
            eventCategory: "Purchase"
          }
        }
        TagManager.dataLayer(tagManagerArgs);
      }
      this.setState({
        backdropOpen: !this.state.backdropOpen
      });
    };

    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }

    return (
      <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Get Pro | MTG Event</title>
        </Helmet>
        <Box textAlign="center" mb={5} mt={3}>
          <Typography variant="h1">Organize better tournaments with MTG Event Pro!</Typography>
        </Box>
        <Grid
          container
        >
          <Grid item xs={12} md={4} className={classes.features}>
            <Box display="flex" flexDirection="column" justifyContent="space-evenly" height="1" textAlign="center">
              <Box className={classes.feature}>
                <GroupAdd className={classes.icon} />
                <Typography>Unlimited size events</Typography>
              </Box>
              <Hidden smDown>
                <Divider />
              </Hidden>
              <Box className={classes.feature}>
                <Timeline className={classes.icon} />
                <Typography>Detailed statistics</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={15}>
              <Box borderRadius={5} overflow="hidden">
                <img alt="" style={{width: "100%"}} src={mtgEventPro} />
              </Box>
              <Box p={2} textAlign="center">
                <Typography variant="h3">MTG Event Pro</Typography>
                <Typography>A Pro subscription gives you access to more features, and more of the stuff you already use. Bigger events, brand new Leagues and even detailed stats about your game. Unlock Pro now for only $5.99 per month (billed every 3 months)!</Typography>
                {!isPremium ? (
                  <Box mt={2}>
                    <Button className={classes.button} variant="contained" size="large" color="primary" onClick={handleToggle}>Subscribe now</Button>
                  </Box>
                ) : (
                  <Box mt={2}>
                    <Typography>You already have an acctive Pro subscription. Go to <Link to={"/user/settings"}>Account Settings</Link> if you wish to cancel.</Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} className={classes.features}>
            <Box display="flex" flexDirection="column" justifyContent="space-evenly" height="1" textAlign="center">
              <Box className={classes.feature}>
                <FormatListNumbered className={classes.icon} />
                <Typography>Create and manage Leagues</Typography>
              </Box>
              <Hidden smDown>
                <Divider />
              </Hidden>
              <Box className={classes.feature}>
                <FavoriteBorder className={classes.icon} />
                <Typography>Support to keep the service running</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={this.state.backdropOpen}>
          <Box
            display="flex"
            direction="row"
            justify="center"
            alignItems="center"
            textAlign="center"
            className={classes.purchaseContainer}
            px={1}
          >
            <Paper className={classes.purchasePaper}>
              <Box px={2} py={3}>
                <Typography variant="h3">MTG Event Pro Subscription</Typography>
                {this.props.purchaseSuccessful ? (
                  <Collapse in={this.props.purchaseSuccessful}>
                    <Box mt={2}>
                      <Alert severity="success">Your purchase is complete! Thank you and enjoy your new features!</Alert>
                    </Box>
                  </Collapse>
                ) : (
                  <>
                    <Typography variant="body1">The billing period is 3 months. You will be charged $17.97 every 3 months until cancelled.</Typography>
                    <CheckoutForm
                      product="subscription"
                    />
                    <Collapse in={this.props.purchaseError}>
                      <Box mt={2}>
                        <Alert severity="error">{this.props.purchaseError}</Alert>
                      </Box>
                    </Collapse>
                  </>
                )}
                <Box mt={2}>
                  <Button
                    onClick={handleToggle}
                    disabled={this.props.isPurchasing}
                  >
                    Close
                  </Button>
                </Box>
                <Box my={2}>
                  <Typography variant="body2">Your subscription can be cancelled at any time through your Account Settings page. You will keep your Pro status and features until the end of the current 3 month billing period.</Typography>
                </Box>
                <img alt="Powered by Stripe" style={{width: "130px"}} src={stripe} />
              </Box>
            </Paper>
          </Box>
        </Backdrop>
      </Container>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.authReducer.currentUser,
    isPurchasing: state.authReducer.isPurchasing,
    purchaseError: state.authReducer.purchaseError,
    purchaseSuccessful: state.authReducer.purchaseSuccessful
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Pro));
