import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

import { addCustomPlayer } from '../Actions/eventActions';

import * as Yup from 'yup';

class AddPlayerForm extends Component {

  render() {
    return (
        <Formik
          initialValues={{ name: '' }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required('Required')
          })}
          onSubmit={(values) => {
            this.props.addCustomPlayer(values.name, this.props.currentEvent);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Field component={TextField} margin="normal" fullWidth label="Player name" variant="outlined" type="text" name="name" />
              <Button size="large" type="submit" variant="contained" color="primary">Add player</Button>
            </Form>
          )}
        </Formik>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addCustomPlayer: (playerName, currentEvent) => {
      dispatch(addCustomPlayer(playerName, currentEvent))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPlayerForm);
