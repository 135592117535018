import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Box, Button, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, IconButton } from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import Print from '@material-ui/icons/Print';
import EventHeader from "../../Components/EventHeader";
import PodsForPrint from "../../Components/PodsForPrint";
import TagManager from 'react-gtm-module';

import { createPods, savePods } from "../../Actions/eventActions";

class Pods extends Component {

  componentDidMount() {
  //  this.props.createPods(this.props.currentEvent);
  }

  render() {
    function Pod(pod) {
      return (
        <List>
          {pod.players.map((player, index) =>
            <ListItem
              key={player.name}
            >
              <ListItemAvatar>
                <Avatar>
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={player.name} />
            </ListItem>
          )}
        </List>
      );
    }

    if(this.props.pods) {
      return(
        <Box>
          <div style={{ display: "none" }}>
            <PodsForPrint
              pods={this.props.pods}
              title={this.props.currentEvent.title}
              ref={el => (this.componentRef = el)}
            />
          </div>
          <EventHeader event={this.props.currentEvent} />
          <Box mb={1}>
            <Typography variant="h2">Drafting pods</Typography>
          </Box>
          {this.props.pods.map((pod, index) =>
            <Box mb={2}>
              <Paper key={index}>
                <Box p={2}>
                  <Typography variant="h6">Pod {index + 1}</Typography>
                </Box>
                <Divider />
                <Pod players={pod} />
              </Paper>
            </Box>
          )}
          <Box display="flex">
            <Box mr={2}>
              <Button startIcon={<Loop />} variant="outlined" color="primary" onClick={() => this.props.createPods(this.props.currentEvent)}>Shuffle pods</Button>
            </Box>
            <Box>
            {this.props.currentEvent.structure === "Single-elimination" ? (
              <Button component={Link} to={"/events/" + this.props.currentEvent.id + "/single-elimination"} onClick={() => this.props.savePods(this.props.pods, this.props.currentEvent)} variant="contained" color="primary" href={"/events/" + this.props.currentEvent.id + "/single-elimination"}>Save and continue</Button>
            ) : (
              <Button component={Link} to={"/events/" + this.props.currentEvent.id + "/round/1"} onClick={() => this.props.savePods(this.props.pods, this.props.currentEvent)} variant="contained" color="primary" href={"/events/" + this.props.currentEvent.id + "/round/1"}>Save and continue</Button>
            )}
            </Box>
            <Box ml={2} display={{ xs: 'none', sm: 'block' }}>
              <ReactToPrint
                onBeforePrint={() => {
                  const tagManagerArgs = {
                    dataLayer: {
                      genericEvent: true,
                      event: "Matches printed",
                      eventCategory: "Event"
                    }
                  }
                  TagManager.dataLayer(tagManagerArgs);
                }}
                trigger={() => {
                  return (
                    <IconButton size="small" aria-label="print">
                      <Print />
                    </IconButton>
                  );
                }}
                content={() => this.componentRef}
              />
            </Box>
          </Box>
        </Box>
      )
    } else {
      this.props.createPods(this.props.currentEvent);
      return (
        <Box>
          <Typography variant="h4">Loading...</Typography>
        </Box>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent,
    pods: state.eventReducer.temporaryPods
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createPods: (currentEvent) => {
      dispatch(createPods(currentEvent));
    },
    savePods: (pods, currentEvent) => {
      dispatch(savePods(pods, currentEvent))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pods);
