import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

class ConfirmLoginPasswordForm extends Component {

  render() {
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(6, "Password needs to be at least 6 characters")
            .required('Required'),
        })}
        onSubmit={(values, actions) => {
          this.props.onSubmit(values.password);
          actions.setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <Field component={TextField} margin="normal" fullWidth label="Password" variant="outlined" type="password" name="password" />
            <Button size="large" type="submit" variant="contained" color="primary">Re-authenticate</Button>
          </Form>
        )}
      </Formik>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.authReducer.isLoggingIn,
    authError: state.authReducer.authError,
  };
}

export default connect(mapStateToProps)(ConfirmLoginPasswordForm);
