import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Container, Box, Paper, Button } from '@material-ui/core';
import NewLeagueForm from "../../Components/NewLeagueForm";
import {Helmet} from "react-helmet";
import ConsoleHelper from "../../ConsoleHelper.js"

import { createLeague } from "../../Actions/eventActions";

class NewLeague extends Component {

  render() {
    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }
    return(
      <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>New league | MTG Event</title>
        </Helmet>
        <Box mb={2}>
          <Typography variant="h1">Create new league</Typography>
        </Box>
        <Paper>
          <Box p={3}>
          {isPremium ? (
            <NewLeagueForm
              onSubmit={(values) => this.props.createLeague(values, this.props.currentUser, this.props.history)}
            />
          ) : (
            <Box>
              <Box mb={1}>
                <Typography variant="h3">Go Pro to create leagues</Typography>
              </Box>
              <Box mb={3}>
                <Typography>With a Pro account you get access not only to Leagues, but also to unlimited size events and advanced statistics!</Typography>
              </Box>
              <Button component={Link} to={"/pro"} color="primary" variant="contained" size="large" href="/pro">Learn more</Button>
            </Box>
          )}

          </Box>
        </Paper>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createLeague: (values, uid, history) => {
      dispatch(createLeague(values, uid)).then(result => {
        history.push("/leagues/" + result);
      }).catch(error => {
        ConsoleHelper(error);
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLeague);
