import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box, Button, Grid, Paper, Divider } from '@material-ui/core';
import { Link } from "react-router-dom";
import { ArrowForward } from '@material-ui/icons';
import firebase from "../../firebase.js";
import { withStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import EventHeader from "../../Components/EventHeader";
import ScoreStepper from "../../Components/ScoreStepper";
import EventStepper from "../../Components/EventStepper";

import { successSetMatches, generateSingleEliminationTree, changeMatchScore, saveSingleMatchScore, setNextMatch, endSingleEliminationTournament } from "../../Actions/eventActions";

const styles = theme => ({
  column: {
    flexGrow: 1,
    marginRight: 30,
    width: 280,
    flexShrink: 0
  },
  playerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  gridContainer: {
    overflowX: "scroll",
    overflowY: "hidden",
    paddingBottom: 50
  },
  matchPaper: {
    display: "flex",
    justifyContent: "space-between"
  },
  matchContainer: {
    flexGrow: 1
  },
  continueButton: {
    minWidth: 0,
    padding: 10,
    borderRadius: "0 5px 5px 0"
  },
  scoreContainer: {
    boxShadow: theme.shadows[2],
    borderRadius: "5px"
  },
  winner: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    transition: "background-color .3s"
  },
  number: {
    fontSize: "20px"
  },
  loserName: {
    color: "#777777",
    textDecoration: "line-through"
  }
});

class SingleElimination extends Component {

  componentDidMount() {
    let that = this;
    this.unsubscribeFromMatches = firebase.firestore().collection("events").doc(this.props.currentEvent.id).collection("matches")
    .onSnapshot(function(querySnapshot) {
      let matches = [];
      querySnapshot.forEach(function(doc) {
          matches.push(doc.data());
      });
      that.props.setMatches(matches);
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromMatches();
  }

  render() {
    let isAdmin = this.props.currentUser.uid === this.props.currentEvent.owner;
    let numberOfRounds = 0;
    if (this.props.currentMatches.length > 0) {
      numberOfRounds = Math.max.apply(Math, this.props.currentMatches.map(function(o) { return o.round; }))
    }
    this.props.currentMatches.sort((a, b) => (a.matchNumber > b.matchNumber) ? 1 : -1);
    const { classes } = this.props;
    return (
      <Box pb={6}>
        <EventHeader event={this.props.currentEvent} />
        {this.props.currentEvent.structure === "Swiss with Single-elim finals" &&
          <EventStepper round={this.props.currentEvent.numberOfRounds + 2} event={this.props.currentEvent} />
        }
        {(!this.props.currentMatches.length > 0 && isAdmin) &&
          <Box>
            {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && this.props.currentEvent.stage === "Running") ?
              (
                <Typography>You need to finish the swiss rounds before you can generate the single-elimination tree.</Typography>
              ) : (
                <Button variant="contained" color="primary" size="large" onClick={() => this.props.generateSingleEliminationTree(this.props.currentEvent)}>Generate matches</Button>
              )
            }
          </Box>
        }
        {this.props.currentMatches.length > 0 &&
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            wrap="nowrap"
            className={classes.gridContainer}
          >
            {[...Array(numberOfRounds)].map((e, i) => (
              <Grid item className={classes.column} key={"round"+i}>
                {(i+1) === numberOfRounds ? (
                  <Typography>Final</Typography>
                ) : (
                  <Typography>Round {i+1}</Typography>
                )}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  alignItems="stretch"
                  height={1}
                >
                  {this.props.currentMatches.map((match, index) => {
                    if (match.round === i + 1) {
                      let isFinal = (i+1) === numberOfRounds;
                      return(
                        <Box mb={1} key={"round_"+i+"match_"+index}>
                          <Paper className={classes.matchPaper}>
                            <Box className={classes.matchContainer}>
                              <Box p={1} className={classes.playerContainer}>
                                <Typography className={(match.player2?.matchScore > match.player1?.matchScore && classes.loserName)}>{(match.player1?.name && this.props.currentEvent.structure === "Swiss with Single-elim finals") && "(" + getPlayerPositionAfterSwiss(match.player1?.uid, this.props.currentEvent.players) + ")"} {match.player1?.name || "TBD"}</Typography>
                                {(match.player1 && match.player2 && !match.matchSaved) ? (
                                  <ScoreStepper match={match} player="player1" changeMatchScore={this.props.changeMatchScore} bestOf={this.props.currentEvent.bestOf} />
                                ) : (
                                  <Box className={`${classes.scoreContainer} ${(match.player2?.matchScore < match.player1?.matchScore && classes.winner)}`} px={2}>
                                    <Typography className={classes.number}>{match.player1?.matchScore}</Typography>
                                  </Box>
                                )}
                              </Box>
                              <Divider />
                              <Box p={1} className={classes.playerContainer}>
                                <Typography className={(match.player2?.matchScore < match.player1?.matchScore && classes.loserName)}>{(match.player2?.name && this.props.currentEvent.structure === "Swiss with Single-elim finals") && "(" + getPlayerPositionAfterSwiss(match.player2?.uid, this.props.currentEvent.players) + ")"} {match.player2?.name || "TBD"}</Typography>
                                {(match.player1 && match.player2 && !match.matchSaved) ? (
                                  <ScoreStepper match={match} player="player2" changeMatchScore={this.props.changeMatchScore} bestOf={this.props.currentEvent.bestOf} />
                                ) : (
                                  <Box className={`${classes.scoreContainer} ${(match.player2?.matchScore > match.player1?.matchScore && classes.winner)}`} px={2}>
                                    <Typography className={classes.number}>{match.player2?.matchScore}</Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            {(match.player1 && match.player2 && !match.matchSaved && !isFinal) &&
                              <Button
                                onClick={() => this.props.setNextMatch(match, this.props.currentMatches, this.props.currentEvent)}
                                disabled={(match.player1.matchScore === 0 && match.player2.matchScore === 0) || (match.player1.matchScore === match.player2.matchScore)}
                                variant="contained"
                                color="primary"
                                className={classes.continueButton}
                              >
                                <NavigateNextIcon />
                              </Button>
                            }
                          </Paper>
                          {(match.player1 && match.player2 && !match.matchSaved && isFinal) &&
                            <Box pt={2}>
                              <Button
                                onClick={() => this.props.endTournament(match, this.props.currentEvent)}
                                disabled={(match.player1.matchScore === 0 && match.player2.matchScore === 0)}
                                variant="contained"
                                color="primary"
                              >
                                End tournament
                              </Button>
                            </Box>
                          }
                          {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && this.props.currentEvent.stage === "Finished" && isFinal) &&
                            <Box pt={2}>
                              <Button endIcon={<ArrowForward />} component={Link} to={"/events/" + this.props.match.params.id + "/final-results"} color="primary" variant="contained">To final results</Button>
                            </Box>
                          }
                        </Box>
                      )
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Grid>
            ))}
          </Grid>
        }
      </Box>
    )
  }
}

function getPlayerPositionAfterSwiss(uid, players) {
  let index = players.findIndex(player => player.uid === uid);
  index++;
  if (index === 0) {
    return;
  }
  return index;
}

function mapStateToProps(state, ownProps) {
  let currentMatches = state.eventReducer.matches.sort((a, b) => (a.matchNumber > b.matchNumber) ? 1 : -1);
  // If this is a Swiss with Top X, remove matches that are part of the Swiss
  if (state.eventReducer.currentEvent.structure === "Swiss with Single-elim finals") {
    currentMatches = currentMatches.filter(match => match.isFinal === true);
  }
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent,
    currentPods: state.eventReducer.currentPods,
    currentMatches: currentMatches
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMatches: (matches) => {
      dispatch(successSetMatches(matches));
    },
    generateSingleEliminationTree: (currentEvent) => {
      dispatch(generateSingleEliminationTree(currentEvent));
    },
    changeMatchScore: (score, matchId, player) => {
      dispatch(changeMatchScore(score, matchId, player));
    },
    saveSingleMatchScore: (match, eventId) => {
      dispatch(saveSingleMatchScore(match, eventId));
    },
    setNextMatch: (currentMatch, matches, event) => {
      dispatch(saveSingleMatchScore(currentMatch, event));
      dispatch(setNextMatch(currentMatch, matches, event));
    },
    endTournament: (currentMatch, event) => {
      dispatch(saveSingleMatchScore(currentMatch, event));
      dispatch(endSingleEliminationTournament(event));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SingleElimination))
