import React from 'react';
import history from '../history';
import { Stepper, Step, StepButton, Button, Box, Hidden, MobileStepper } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

function getSteps(currentEvent) {
  let steps = [];
  for(let i = 0; i < currentEvent.numberOfRounds; i++) {
    steps.push({index: i, label: "Round " + (i+1), link: "/events/" + currentEvent.id + "/round/" + (i+1)});
  }
  if (currentEvent.structure === "Swiss with Single-elim finals") {
    steps.push({index: steps.length + 1, label: "Swiss results", link: "/events/" + currentEvent.id + "/results"});
    steps.push({index: steps.length + 1, label: "Finals", link: "/events/" + currentEvent.id + "/single-elimination"});
    steps.push({index: steps.length + 1, label: "Final results", link: "/events/" + currentEvent.id + "/final-results"});
  } else if (currentEvent.stage !== "Finished") {
    steps.push({index: steps.length + 1, label: "Standings", link: "/events/" + currentEvent.id + "/results"});
  } else {
    steps.push({index: steps.length + 1, label: "Results", link: "/events/" + currentEvent.id + "/results"});
  }
  return steps;
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const steps = getSteps(props.event);

  const handleClick = (index, link) => {
    history.push(link);
  };

  return (
    <Box mb={3}>
      <Hidden smDown>
        <Stepper nonLinear activeStep={parseInt(props.round) - 1} elevation={1}>
          {steps.map((step, index) => (
            <Step key={step.label} completed={props.event.stageNumber > (index + 3) || props.event.stage === "Finished"}>
              <StepButton onClick={() => handleClick(index, step.link)} >{step.label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <Hidden mdUp>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={props.round ? (parseInt(props.round) - 1) : (steps.length - 1)}
          nextButton={
            <Button size="small" disabled={!props.round} onClick={() => {
              let link = steps[parseInt(props.round)].link;
              handleClick(null, link);
            }}>
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" disabled={(parseInt(props.round) - 1) === 0} onClick={() => {
              let link;
              if (!props.round) {
                link = steps[steps.length - 2].link;
              } else {
                link = steps[parseInt(props.round) - 2].link;
              }
              handleClick(null, link);
            }}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Hidden>
    </Box>
  );
}
