import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box, Button } from '@material-ui/core';
import firebase from "../../firebase.js";

import EventStepper from "../../Components/EventStepper";
import MatchesList from '../../Components/MatchesList';
import CountdownTimer from '../../Components/CountdownTimer';
import EventHeader from "../../Components/EventHeader";

import { pairRoundOne, setCurrentPods, successSetMatches, pairNextRound } from "../../Actions/eventActions";

class Round extends Component {

  componentDidMount() {
    this.props.setCurrentPods(this.props.currentEvent);

    let that = this;
    this.unsubscribeFromMatches = firebase.firestore().collection("events").doc(this.props.currentEvent.id).collection("matches")
    .onSnapshot(function(querySnapshot) {
      let matches = [];
      querySnapshot.forEach(function(doc) {
          matches.push(doc.data());
      });
      that.props.setMatches(matches);
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromMatches();
    this.props.setCurrentPods(null);
  }

  render() {
    let isAdmin = this.props.currentUser.uid === this.props.currentEvent.owner;
    let roundNumber = parseInt(this.props.match.params.round);

    return (
      <Box pb={6}>
        <EventHeader event={this.props.currentEvent} />
        <EventStepper round={roundNumber} event={this.props.currentEvent} />
        <Box mb={3}>
          <Typography variant="h2">Round {roundNumber}</Typography>
        </Box>
        {(!this.props.currentMatches.length > 0 && isAdmin) &&
          <Box>
            {roundNumber === this.props.currentEvent.stageNumber - 2 ? (
              <>
                {/* Use the correct button if this is the first round */}
                {roundNumber === 1 ? (
                  <Button variant="contained" color="primary" size="large" onClick={() => this.props.pairRoundOne(this.props.currentEvent, this.props.currentPods)}>Generate matches</Button>
                ):(
                  <Button variant="contained" color="primary" size="large" onClick={() => this.props.pairNextRound(this.props.currentEvent, this.props.currentPods, roundNumber)}>Generate matches</Button>
                )}
              </>
            ):(
              <>
                <Typography variant="body1">Round {this.props.match.params.round - 1} is not finished yet</Typography>
              </>
            )
            }
          </Box>
        }
        <MatchesList isAdmin={isAdmin} round={roundNumber} currentMatches={this.props.currentMatches} allMatches={this.props.allMatches} />
        <CountdownTimer disabled={!this.props.currentMatches.length > 0 || roundNumber !== this.props.currentEvent.stageNumber - 2} />
      </Box>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let currentMatches = state.eventReducer.matches.filter(match => match.round === parseInt(ownProps.match.params.round) && match.isFinal !== true);
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent,
    currentPods: state.eventReducer.currentPods,
    currentMatches: currentMatches,
    allMatches: state.eventReducer.matches
  };
}

function mapDispatchToProps(dispatch) {
  return {
    pairRoundOne: (currentEvent, pods) => {
      dispatch(pairRoundOne(currentEvent, pods))
    },
    pairNextRound: (currentEvent, pods, roundNumber) => {
      dispatch(pairNextRound(currentEvent, pods, roundNumber))
    },
    setCurrentPods: (event) => {
      dispatch(setCurrentPods(event));
    },
    setMatches: (matches) => {
      dispatch(successSetMatches(matches));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Round);
