import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box, Paper, Grid, CircularProgress, Chip, Menu, MenuItem, ListSubheader } from '@material-ui/core';
import StatNumber from "./StatNumber";
import { Add } from '@material-ui/icons';
import { ResponsiveLine } from '@nivo/line';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { getFinishedEvents } from '../Actions/authActions';

class ProfileStats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredTypes: [],
      notFilteredTypes: ["Draft", "Sealed", "Constructed"]
    };
  }

  addTypeFilter = (type) => {
      // Remove from notFilteredTypes
      let notFilteredTypes = this.state.notFilteredTypes;
      const index = notFilteredTypes.indexOf(type);
      if (index > -1) {
        notFilteredTypes.splice(index, 1);
      }
      // Add to filteredTypes
      let filteredTypes = this.state.filteredTypes;
      filteredTypes.push(type);
      this.setState({notFilteredTypes, filteredTypes});
  }

  removeTypeFilter = (type) => {
      // Remove from filteredTypes
      let notFilteredTypes = this.state.notFilteredTypes;
      let filteredTypes = this.state.filteredTypes;
      const index = filteredTypes.indexOf(type);
      if (index > -1) {
        filteredTypes.splice(index, 1);
      }
      // Add to notFilteredTypes
      notFilteredTypes.push(type);
      this.setState({notFilteredTypes, filteredTypes});
  }

  componentDidMount() {
    this.props.getFinishedEvents(this.props.uid);
  }

  render() {
    const MyResponsiveLine = ({ data /* see data tab */ }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: '0', max: '100', stacked: false, reverse: false }}
        yFormat={value => value + "%"}
        curve="natural"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Events',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
        }}
        enableGridY={false}
        colors={['#60C1BB', '#494E5C']}
        lineWidth={4}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        enableCrosshair={false}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)

    let that = this;
    if(this.props.isFetchingFinishedEvents) {
      return (
        <Box>
          <CircularProgress />
        </Box>
      )
    } else {
      let events = this.props.currentFinishedEvents;
      if (events.length === 0) {
        return (
          <Box>
            <Typography>You have not finished any events yet.</Typography>
          </Box>
        )
      }

      let filteredEvents = events;
      if(that.state.filteredTypes.length !== 0) {
        filteredEvents = filteredEvents.filter(function (event) {
          return (
            that.state.filteredTypes.length !== 0 && that.state.filteredTypes.includes(event.type)
          );
        });
      }

      let stats = {
        matchesWon: 0,
        matchesLost: 0,
        matchesDrawn: 0,
        winRate: 0,
        gamesWon: 0,
        gamesLost: 0,
        gamesWinRate: 0,
      }
      let graphData = [
        {
          id: "Game Win Percentage",
          data: []
        },
        {
          id: "Match Win Percentage",
          data: []
        }
      ];
      filteredEvents.forEach((event, i) => {
        stats.matchesWon =  stats.matchesWon + event.matchesWon;
        stats.matchesLost = stats.matchesLost + event.matchesLost;
        stats.matchesDrawn = stats.matchesDrawn + event.matchesDrawn;
        stats.gamesWon = stats.gamesWon + event.gamesWon;
        stats.gamesLost = stats.gamesLost + event.gamesLost;
        let gamesWinRate = {
          x: i+1,
          y: Math.round(stats.gamesWon / (stats.gamesWon+stats.gamesLost) * 100)
        };
        let winRateDataPoint = {
          x: i+1,
          y: Math.round(stats.matchesWon / (stats.matchesWon+stats.matchesLost+stats.matchesDrawn) * 100)
        }
        graphData[0].data.push(gamesWinRate);
        graphData[1].data.push(winRateDataPoint);
      });
      stats.winRate = Math.round(stats.matchesWon / (stats.matchesWon+stats.matchesLost+stats.matchesDrawn) * 100);
      stats.gamesWinRate = Math.round(stats.gamesWon / (stats.gamesWon+stats.gamesLost) * 100);
      return (
        <Box>
          <Box my={2}>
            <Paper>
              <Box p={2} display="flex">
                <Box display="flex" alignItems="center">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Chip color="primary" label="Add filter" icon={<Add />} {...bindTrigger(popupState)}/>
                        <Menu {...bindMenu(popupState)}>
                          <ListSubheader>Event type</ListSubheader>
                          {this.state.notFilteredTypes.map(type => {
                            return (
                              <MenuItem key={type} onClick={() => this.addTypeFilter(type)}>
                                <Typography>{type}</Typography>
                              </MenuItem>
                            )
                          })}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                  {this.state.filteredTypes.map(type => {
                    return (
                      <Box ml={1}>
                        <Chip key={type} label={type} variant="outlined" color="secondary" size="small" onDelete={() => this.removeTypeFilter(type)} />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box my={2}>
            <Paper>
              <Box py={2}>
                <Grid container>
                  <Grid item md={3} sm={6} xs={12}>
                    <StatNumber title="Number of events played" number={filteredEvents.length} />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <StatNumber title="Match record" number={stats.matchesWon + "-" + stats.matchesLost + "-" + stats.matchesDrawn} />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <StatNumber title="Match Win Percentage" number={stats.winRate + "%"} />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <StatNumber title="Game Win Percentage" number={stats.gamesWinRate + "%"} />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          <Box my={2}>
            <Paper>
              <Box height="400px" p={2}>
                <MyResponsiveLine data={graphData} />
              </Box>
            </Paper>
          </Box>
        </Box>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    isFetchingFinishedEvents: state.authReducer.isFetchingFinishedEvents,
    finishedEventsError: state.authReducer.finishedEventsError,
    currentFinishedEvents: state.authReducer.currentFinishedEvents
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFinishedEvents: (uid) => {
      dispatch(getFinishedEvents(uid));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileStats);
