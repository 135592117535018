import React, { Component } from 'react';
import { connect } from "react-redux";
import history from '../history';
import { Button, Typography, Slider, Box, Switch } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import Alert from '@material-ui/lab/Alert';

import * as Yup from 'yup';

const FormikSlider = ({
  label,
  form: { setFieldValue },
  ...rest
}) => {
  return (
    <>
      <Typography id="discrete-slider">{label}: {rest.field.value} {rest.unit}</Typography>
      <Slider
        defaultValue={rest.defaultValue}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        marks
        step={rest.step}
        min={rest.min}
        max={rest.max}
        value={rest.field.value}
        onChange={(event, value) => {
          setFieldValue(rest.field.name, value);
        }}
      />
    </>
  )
}

const FormikSwitch = ({
  label,
  form: { setFieldValue },
  ...rest
}) => {
  return (
    <>
      <Typography>{label}</Typography>
      <Switch
        defaultValue={rest.defaultValue}
        value={rest.field.value}
        color="primary"
        onChange={(event, value) => {
          setFieldValue(rest.field.name, value);
        }}
      />
    </>
  )
}

class EventSettingsForm extends Component {

  cancelForm = (handleReset) => {
    history.goBack();
    handleReset();
  }

  render() {
    return (
      <Formik
        initialValues={{
          roundTimeLimit: this.props.currentEvent.roundTimeLimit,
          numberOfRounds: this.props.currentEvent.numberOfRounds,
          numberOfPods: this.props.currentEvent.numberOfPods,
          bestOf: this.props.currentEvent.bestOf,
          numberOfPlayersInFinal: (this.props.currentEvent.players.length / 2) < 8 ? 4 : 8,
          allowCrossPodPairings: this.props.currentEvent.allowCrossPodPairings,
         }}
        validationSchema={Yup.object().shape({

        })}
        onSubmit={(values) => {
          this.props.onSubmit(values);
        }}
      >
        {({ isSubmitting, setFieldValue, handleReset, dirty, values }) => (
          <Form>
            {this.props.currentEvent.type === "Draft" &&
              <Field
                component={FormikSlider}
                label="Number of pods"
                name="numberOfPods"
                unit=""
                defaultValue={this.props.currentEvent.numberOfPods}
                step={1}
                min={1}
                max={this.props.currentEvent.numberOfPods + 1}
              />
            }
            {this.props.currentEvent.structure !== "Single-elimination" &&
              <Field
                component={FormikSlider}
                label="Number of rounds"
                name="numberOfRounds"
                unit=""
                defaultValue={this.props.currentEvent.numberOfRounds}
                step={1}
                min={1}
                max={this.props.currentEvent.numberOfRounds + 2}
              />
            }
            {this.props.currentEvent.structure === "Swiss with Single-elim finals" &&
              <Field
                component={FormikSlider}
                label="Number of players in final"
                name="numberOfPlayersInFinal"
                unit=""
                defaultValue={(this.props.currentEvent.players.length / 2) < 8 ? 4 : 8}
                step={2}
                min={2}
                max={(this.props.currentEvent.players.length - 2) < 16 ? (this.props.currentEvent.players.length - 2) : 16}
              />
            }
            <Field
              component={FormikSlider}
              label="Best of"
              name="bestOf"
              unit="games"
              defaultValue={this.props.currentEvent.bestOf}
              step={2}
              min={1}
              max={5}
            />
            <Field
              component={FormikSlider}
              label="Round time limit"
              name="roundTimeLimit"
              unit="min"
              defaultValue={this.props.currentEvent.roundTimeLimit}
              step={5}
              min={10}
              max={90}
            />
            {this.props.currentEvent.type === "Draft" &&
              <Field
                component={FormikSwitch}
                label="Allow cross-pod pairings"
                name="allowCrossPodPairings"
                defaultValue={this.props.currentEvent.allowCrossPodPairings}
              />
            }
            <Box display="flex" mt={2}>
              <Box mr={2}>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>Continue</Button>
              </Box>
              <Button onClick={() => this.cancelForm(handleReset)} disabled={isSubmitting}>Cancel</Button>
            </Box>
            {(values.numberOfPods > 1 && this.props.currentEvent.structure === "Single-elimination") &&
              <Box mt={2}>
                <Alert severity="info">Having multiple pods in a single-elimination event means that players will eventually face opponents from different pods.</Alert>
              </Box>
            }
          </Form>
        )}
      </Formik>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentEvent: state.eventReducer.currentEvent
  };
}

export default connect(mapStateToProps)(EventSettingsForm);
