import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Container, Box } from '@material-ui/core';
import history from '../../history';
import { setRedirectUrl } from "../../Actions/authActions";

import MyEvents from "../../Components/MyEvents";
import MyLeagues from "../../Components/MyLeagues";

class Home extends Component {

  componentDidMount() {
    if(this.props.redirectUrl) {
      history.push(this.props.redirectUrl);
      this.props.setRedirectUrl();
    }
  }

  render() {
    return(
      <Container>
        <Box mb={4}>
          <Typography variant="h1">Welcome, {this.props.currentUser.name}</Typography>
        </Box>
        <MyEvents />
        <MyLeagues />
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    redirectUrl: state.authReducer.redirectUrl
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRedirectUrl: () => {
      dispatch(setRedirectUrl(null));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
