import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Toolbar, AppBar, Box, Button, Hidden, Typography } from '@material-ui/core';
import firebase from "../firebase.js"
import { withStyles } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import logo from "../logo.png";
import HeaderProfileMenu from "./HeaderProfileMenu";
import HeaderNotificationsMenu from "./HeaderNotificationsMenu";
import HeaderMobileMenu from "./HeaderMobileMenu";
import CreditsIcon from "./CreditsIcon";
import CreditsInfoBackdrop from "./CreditsInfoBackdrop";

import { signOut, setNotifications, markNotificationsAsRead, receiveLogin } from "../Actions/authActions";
import { toggleCreditsInfoBackdrop } from "../Actions/coreActions";

const styles = theme => ({
  navLink: {
    color: "#FFFFFF",
    margin: "0 10px",
    "&.active": {
      color: theme.palette.secondary.main,
      backgroundColor: "rgba(255,255,255,0.7)"
    }
  }
});

class Header extends Component {

  componentDidMount() {
    let that = this;
    this.unsubscribeFromNotifications = firebase.firestore().collection("users").doc(this.props.currentUser.uid).collection("notifications").orderBy("created", "desc")
    .onSnapshot(function(querySnapshot) {
      let notifications = [];
      querySnapshot.forEach(function(doc) {
          let notification = doc.data();
          notification.id = doc.id;
          notifications.push(notification);
      });
      that.props.setNotifications(notifications);
    });

    this.unsubscribeFromUser = firebase.firestore().collection("users").doc(this.props.currentUser.uid)
    .onSnapshot(function(doc) {
      that.props.receiveLogin(doc.data());
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromNotifications();
    this.unsubscribeFromUser();
    this.props.setNotifications([]);
  }

  render() {
    let isPremium = false;
    let currentCredits = null;
    if (this.props.currentUser.premium) {
      isPremium = (this.props.currentUser.premium.status === "active" || false);
      currentCredits = this.props.currentUser.premium.credits;
    }

    const tagManagerArgs = {
      gtmId: 'GTM-PPQJ7K7',
      dataLayer: {
        uid: this.props.currentUser.uid,
        proSubscriber: isPremium,
        email: this.props.currentUser.email,
        event: "userDataLoaded",
        userName: this.props.currentUser.name
      },
      dataLayerName: 'dataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);

    const { classes } = this.props;

    return (
      <Box>
        <CreditsInfoBackdrop />
        <AppBar position="static" color="secondary" elevation={1}>
          <Toolbar>
            <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
              <Link to="/"><img alt="MTG Event logo" style={{maxWidth: 50}} src={logo} /></Link>
              <Hidden smDown>
                <Box>
                  <Button className={classes.navLink} component={NavLink} to={"/events"} href="/events">Events</Button>
                  <Button className={classes.navLink} component={NavLink} to={"/leagues"} href="/leagues">Leagues</Button>
                  <Button className={classes.navLink} component={NavLink} to={"/user/" + this.props.currentUser.uid} href={"/user/" + this.props.currentUser.uid}>My stats</Button>
                </Box>
              </Hidden>
              <Box
                display="flex"
                alignItems="center"
              >
                {!isPremium &&
                  <Box mr={2}>
                    <Button startIcon={<MonetizationOnIcon />} onClick={() => this.props.toggleCreditsInfoBackdrop()} variant="contained" color="primary">{currentCredits ? (currentCredits) : ("0")}</Button>
                  </Box>
                }
                {!isPremium &&
                  <Hidden smDown>
                    <Box>
                      <Button component={Link} to={"/pro"} variant="contained" color="primary" href="/pro">Upgrade to Pro</Button>
                    </Box>
                  </Hidden>
                }
                <HeaderNotificationsMenu uid={this.props.currentUser.uid} markNotificationsAsRead={this.props.markNotificationsAsRead} notifications={this.props.notifications} />
                <Hidden smDown>
                  <HeaderProfileMenu uid={this.props.currentUser.uid} handleSignout={this.props.signOut} />
                </Hidden>
                <Hidden mdUp>
                  <HeaderMobileMenu isPremium={isPremium} uid={this.props.currentUser.uid} handleSignout={this.props.signOut} />
                </Hidden>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    notifications: state.authReducer.notifications,
    myEvents: state.coreReducer.myEvents,
    isFetchingMyEvents: state.coreReducer.isFetchingMyEvents,
    creditsBackdropState: state.coreReducer.creditsInfoBackdropOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => {
      dispatch(signOut());
    },
    setNotifications: (notifications) => {
      dispatch(setNotifications(notifications))
    },
    markNotificationsAsRead: (notifications, uid) => {
      dispatch(markNotificationsAsRead(notifications, uid))
    },
    receiveLogin: (user) => {
      dispatch(receiveLogin(user));
    },
    toggleCreditsInfoBackdrop: () => {
      dispatch(toggleCreditsInfoBackdrop());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header))
