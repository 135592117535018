import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { Grid, Container, Button, Typography, Paper, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles} from '@material-ui/core/styles';
import logo from "../../logo.png";

import { registerWithGoogle, signUpWithEmail, registerWithFacebook } from "../../Actions/authActions";

import SignUpForm from "../../Components/SignUpForm";
import FacebookButton from "../../Components/FacebookButton";
import GoogleButton from "../../Components/GoogleButton";

const styles = theme => ({
  grid: {
    minHeight: "100vh"
  },
  paper: {
    padding: '30px',
    textAlign: "center"
  },
});

class SignUp extends Component {

  registerWithGoogle = () => {
    const { dispatch } = this.props;
    dispatch(registerWithGoogle());
  }

  registerWithFacebook = () => {
    const { dispatch } = this.props;
    dispatch(registerWithFacebook());
  }

  signUpWithEmail = (name, email, password) => {
    const { dispatch } = this.props;
    dispatch(signUpWithEmail(name, email, password));
  }

  render() {
    const { classes, isAuthenticated } = this.props;
    if(isAuthenticated) {
      return <Redirect to="/" />
    } else {
      return(
        <Grid
          className={classes.grid}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Container maxWidth="sm">
            <Box my={2}>
              <Paper className={classes.paper}>
                <Box mb={2}>
                  <img alt="MTG Event logo" style={{maxWidth: 100}} src={logo} />
                </Box>
                <Typography variant="h3" gutterBottom>Create a new account</Typography>
                <FacebookButton onClick={this.registerWithFacebook} text="Register with Facebook"  />
                <GoogleButton onClick={this.registerWithGoogle} text="Register with Google"  />
                <Typography>Or register with your email</Typography>
                <SignUpForm onSubmit={this.signUpWithEmail} />
                {this.props.registerError &&
                  <Alert severity="error">{this.props.registerError}</Alert>
                }
                <Box mt={2}>
                  <Button component={RouterLink} to={"/signin"} variant="outlined" color="secondary" href="/signin">I already have an account</Button>
                </Box>
              </Paper>
            </Box>
          </Container>
        </Grid>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.authReducer.isLoggingIn,
    registerError: state.authReducer.registerError,
    isAuthenticated: state.authReducer.isAuthenticated
  };
}

export default withStyles(styles)(connect(mapStateToProps)(SignUp));
