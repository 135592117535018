import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";
import { Typography, Paper, Box, List, ListItem, ListItemText, ListItemIcon, ListSubheader, Divider, Button, Menu, MenuItem, IconButton, Checkbox } from '@material-ui/core';
import { ArrowForward, Add, Tune, LockOpen } from '@material-ui/icons';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Alert from '@material-ui/lab/Alert';

import { fetchMyLeagues } from "../Actions/coreActions";

class AllLeagues extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stages: ["Planned", "Running", "Finished"]
    };
  }

  toggleStage = (stage) => {
    if (this.state.stages.includes(stage)) {
      // Remove from array
      let stages = this.state.stages;
      const index = stages.indexOf(stage);
      if (index > -1) {
        stages.splice(index, 1);
      }
      this.setState({stages: stages});
    } else {
      // Add to array
      let stages = this.state.stages;
      stages.push(stage);
      this.setState({stages: stages});
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMyLeagues(this.props.currentUser.uid));
  }

  render() {
    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }

    var leagues = this.props.myLeagues;
    let that = this;

    function LeaguesList({leagues}) {
      if(leagues.length > 0) {
        let filteredLeagues = leagues.filter(function (league) {
          return (
            that.state.stages.includes(league.stage)
          );
        });
        const leaguesList = filteredLeagues.map((league) =>
          <ListItem
            button
            key={league.id}
            component={Link}
            to={"/leagues/" + league.id}
          >
            <ListItemText
              primary={league.title}
              secondary={league.stage}
            />
            <ListItemIcon>
              <ArrowForward />
            </ListItemIcon>
          </ListItem>
        );
        if (leaguesList.length === 0) {
          return (
            <Box p={2}>
              <Typography>No leagues match your filters</Typography>
            </Box>
          );
        } else {
          return (
            <List>{leaguesList}</List>
          );
        }
      } else {
        return (
          <Box p={2}>
            <Typography>You don't have any leagues</Typography>
          </Box>
        )
      }
    }

    return (
      <Box mb={4}>
        <Paper>
          <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">My leagues</Typography>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <IconButton aria-label="filter" {...bindTrigger(popupState)}>
                    <Tune />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <ListSubheader>League stages</ListSubheader>
                    <MenuItem onClick={() => this.toggleStage("Planned")}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={this.state.stages.includes("Planned")}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                      <Typography>Planned</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => this.toggleStage("Running")}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={this.state.stages.includes("Running")}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <Typography>Running</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => this.toggleStage("Finished")}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={this.state.stages.includes("Finished")}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <Typography>Finished</Typography>
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Box>
          <Divider />
          {this.props.isFetchingMyLeagues ? (
            <Box display="flex" justifyContent="center" alignItems="center" p={2} width={1}>
              <CircularProgress />
            </Box>
          ):(
            <LeaguesList leagues={leagues} />
          )}
          <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
            <Box mr={2}>
              <Button disabled={!isPremium} startIcon={<Add />} component={Link} to={"/new-league"} variant="contained" color="primary" href="/new-league">Create league</Button>
            </Box>
            {!isPremium &&
              <Alert
                icon={<LockOpen fontSize="inherit" />}
                severity="info"
                action={
                  <Button component={Link} to={"/pro"} color="inherit" size="medium" href="/pro">Learn more</Button>
                }
              >
                Upgrade to Pro to create leagues
              </Alert>
            }
          </Box>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    myLeagues: state.coreReducer.myLeagues,
    isFetchingMyLeagues: state.coreReducer.isFetchingMyLeagues
  };
}

export default connect(mapStateToProps)(AllLeagues);
