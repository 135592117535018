import React from 'react';
import { NavLink } from "react-router-dom";
import { IconButton, Drawer, List, ListItem, ListItemText, ListSubheader, ListItemSecondaryAction, Box, Button, Link } from '@material-ui/core';
import { Menu, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export default function HeaderMobileMenu(props) {
  const [state, setState] = React.useState({
    open: false,
  });

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, open });
  };

  const useStyles = makeStyles(theme => ({
    navLink: {
      color: theme.palette.secondary.main,
      "&.active": {
        color: "#FFFFFF",
        backgroundColor: "rgba(73,78,92,0.7)"
      }
    },
    drawer: {
      minWidth: "200px"
    }
  }));

  const classes = useStyles();

  const sideList = (notifications) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List
      className={classes.drawer}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Menu
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
              <Close />
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }
      >
        <ListItem
          className={classes.navLink}
          component={NavLink}
          to="/events"
          button
        >
          <ListItemText primary="Events" />
        </ListItem>
        <ListItem
          className={classes.navLink}
          component={NavLink}
          to="/leagues"
          button
        >
          <ListItemText primary="Leagues" />
        </ListItem>
        <ListItem
          className={classes.navLink}
          component={NavLink}
          to={"/user/" + props.uid}
          button
        >
          <ListItemText primary="My stats" />
        </ListItem>
        <Box mt={5}>
          <ListItem
            className={classes.navLink}
            component={NavLink}
            to={"/user/settings"}
            button
          >
            <ListItemText primary="Account settings" />
          </ListItem>
          <ListItem
            className={classes.navLink}
            onClick={props.handleSignout}
            button
          >
            <ListItemText primary="Sign out" />
          </ListItem>
          {!props.isPremium &&
            <ListItem
              className={classes.navLink}
              component={NavLink}
              to={"/pro"}
              button
            >
              <Button variant="contained" color="primary">Upgrade to Pro</Button>
            </ListItem>
          }
        </Box>
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={toggleDrawer(true)}
      >
        <Menu />
      </IconButton>
      <Drawer anchor="right" open={state.open} onClose={toggleDrawer(false)} elevation={1}>
        {sideList()}
      </Drawer>
    </>
  )
};
