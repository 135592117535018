import React, { Component } from 'react';
import { connect } from "react-redux";
import { Box } from '@material-ui/core';
import {CardElement} from '@stripe/react-stripe-js';

class CardDetails extends Component {

  render() {
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "20px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      <Box elevation={5} my={3} style={{ padding: "15px 5px", borderRadius: "5px", backgroundColor: "rgba(255,255,255,0.8)"}}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);
