import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { Grid, Container, Button, Typography, Paper, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles} from '@material-ui/core/styles';

import { resetPassword } from "../../Actions/authActions";

import ResetPasswordForm from "../../Components/ResetPasswordForm";

const styles = theme => ({
  grid: {
    minHeight: "100vh"
  },
  paper: {
    padding: '30px',
    textAlign: "center"
  },
});

class ResetPassword extends Component {

  render() {
    const { classes, isAuthenticated } = this.props;

    if(isAuthenticated) {
      return <Redirect to="/" />
    } else {
      return(
        <Grid
          className={classes.grid}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Container maxWidth="sm">
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h5">Reset password</Typography>
              <ResetPasswordForm onSubmit={this.props.resetPassword} />
              {this.props.resetPasswordError &&
                <Alert severity="error">{this.props.resetPasswordError}</Alert>
              }
              {this.props.resetPasswordSuccess &&
                <Alert severity="success">{this.props.resetPasswordSuccess}</Alert>
              }
              <Box mt={2}>
                <Button component={RouterLink} to={"/signin"} color="secondary" href="/signin">Go back</Button>
              </Box>
            </Paper>
          </Container>
        </Grid>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.authReducer.isLoggingIn,
    resetPasswordError: state.authReducer.resetPasswordError,
    resetPasswordSuccess: state.authReducer.resetPasswordSuccess,
    isAuthenticated: state.authReducer.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (values) => {
      dispatch(resetPassword(values));
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
