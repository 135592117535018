import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container, CircularProgress, Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import LeagueEventsList from "../../Components/LeagueEventsList";
import NoSuchPage from "../../Components/NoSuchPage";

import { getCurrentLeague, deleteLeague, updateLeague } from "../../Actions/eventActions";

class League extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      endDialogOpen: false
    };
  }

  componentDidMount() {
    let leagueId = this.props.match.params.id;
    this.props.getCurrentLeague(leagueId);
  }

  componentDidUpdate(prevProps, prevState) {
    // If we have recieved props, and the ID is not the same we need to fetch new data
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getCurrentLeague(this.props.match.params.id);
    }
  }


  render() {
    if(this.props.currentLeague === "404") {
      return(
        <NoSuchPage />
      )
    } else if(Object.keys(this.props.currentLeague).length === 0) {
      return(
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh" width={1}>
          <CircularProgress />
        </Box>
      )
    } else {

      let isAdmin = this.props.currentUser.uid === this.props.currentLeague.owner;

      const handleDeleteClickOpen = () => {
        this.setState({
          deleteDialogOpen: true
        });
      };

      const handleDeleteClose = () => {
        this.setState({
          deleteDialogOpen: false
        });
      };

      const handleEndClickOpen = () => {
        this.setState({
          endDialogOpen: true
        });
      };

      const handleEndClose = () => {
        this.setState({
          endDialogOpen: false
        });
      };

      return (
        <Container>
          <Helmet>
              <meta charSet="utf-8" />
              <title>{this.props.currentLeague.title} | MTG Event</title>
          </Helmet>
          <Box mb={3}>
            <Paper>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant="h1">{this.props.currentLeague.title}</Typography>
                </Box>
                <Typography variant="body1">Stage: {this.props.currentLeague.stage}</Typography>
              </Box>
            </Paper>
          </Box>
          {this.props.currentLeague.stage !== "Finished" &&
            <Box mb={3}>
              <Paper>
                <LeagueEventsList events={this.props.upcomingLeagueEvents} title="Upcoming events" emptyText="There are no events planned in this league"/>
                <Box p={2}>
                  <Button startIcon={<Add />} component={Link} to={{
                    pathname: "/new-event",
                    state: {
                      league: this.props.currentLeague
                    }
                  }} variant="contained" color="primary" href="/new-event">Create new event</Button>
                </Box>
              </Paper>
            </Box>
          }
          <Box mb={3}>
            <Paper>
              <LeagueEventsList events={this.props.finishedLeagueEvents} title="Finished events" emptyText="There are no finished events"/>
            </Paper>
          </Box>
          <Box mb={3}>
            <TableContainer component={Paper}>
              <Box p={2}>
                <Typography variant="h5">{this.props.currentLeague.stage === "Finished" ? "Final standings" : "Current standings"}</Typography>
              </Box>
              <Table aria-label="Results table">
                <TableHead>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell align="right">Player</TableCell>
                    <TableCell align="right">Events played</TableCell>
                    <TableCell align="right">Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.currentLeague.players.map((player, index) => (
                    <TableRow key={player.name}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right">{player.name}</TableCell>
                      <TableCell align="right">{player.eventsPlayed}</TableCell>
                      <TableCell align="right">{player.score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mb={3}>
            <TableContainer component={Paper}  style={{maxHeight: '300px'}}>
              <Box p={2}>
                <Typography variant="h5">League history</Typography>
              </Box>
              <Table aria-label="League history" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.currentLeague.history.map((action, index) => (
                    <TableRow key={action}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {isAdmin &&
            <Box display="flex">
              {(this.props.currentLeague.endType === "manual" && this.props.currentLeague.stage === "Running") &&
                <Box mr={2}>
                  <Button disabled={this.props.currentLeague.finishedEvents === 0} variant="contained" color="secondary" onClick={handleEndClickOpen}>End league</Button>
                </Box>
              }
              <Button color="secondary" onClick={handleDeleteClickOpen}>
                Delete league
              </Button>
            </Box>
          }
          <Dialog
            open={this.state.deleteDialogOpen}
            onClose={handleDeleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete league?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will delete the league and all results.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteClose} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.props.deleteLeague(this.props.currentLeague.id, this.props.history)} color="primary" autoFocus>
                Yes, delete league
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.endDialogOpen}
            onClose={handleEndClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"End league?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will end the league. It cannot be re-opened.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEndClose} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.props.endLeague(this.props.currentLeague, handleEndClose)} color="primary" autoFocus>
                Yes, end league
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )
    }
  }
}

function mapStateToProps(state) {
  let upcomingLeagueEvents = state.eventReducer.currentLeagueEvents.filter(event => event.stage === "Planned");
  let finishedLeagueEvents = state.eventReducer.currentLeagueEvents.filter(event => event.stage === "Finished");

  return {
    currentUser: state.authReducer.currentUser,
    currentLeague: state.eventReducer.currentLeague,
    upcomingLeagueEvents: upcomingLeagueEvents,
    finishedLeagueEvents: finishedLeagueEvents
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCurrentLeague: (leagueId) => {
      dispatch(getCurrentLeague(leagueId));
    },
    deleteLeague: (leagueId, history) => {
      dispatch(deleteLeague(leagueId));
      history.push("/");
    },
    endLeague: (league, close) => {
      league.stage = "Finished";
      dispatch(updateLeague(league));
      close();
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(League);
