import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Typography, Box, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => ({
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none"
  }
});

class EventHeader extends Component {

  render() {
    let event = this.props.event;
    let { classes } = this.props;
    return (
      <Box p={1} display="flex" alignItems="center">
        <IconButton component={Link} to={"/events/" + event.id}><ArrowBackIcon/></IconButton>
        <Typography variant="h3"><Link className={classes.link} to={"/events/" + event.id}>{event.title}</Link></Typography>
      </Box>
    )
  }
}

export default withStyles(styles)(EventHeader);
