

import React, { Component } from 'react';
import { connect } from "react-redux";
import { Box, Button, Typography, Dialog, DialogTitle, DialogActions } from '@material-ui/core';

import { updateUser, cancelSubscription, updateUserInMailerlite } from '../Actions/authActions';
var moment = require('moment');

class ProUserSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cancelDialogOpen: false
    };
  }

  render() {

    const handleClickOpen = () => {
      this.setState({
        cancelDialogOpen: true
      });
    };

    const handleClose = () => {
      this.setState({
        cancelDialogOpen: false
      });
    };

    return(
      <Box>
        <Typography variant="body1">Your next payment is on: {moment(this.props.currentUser.premium.current_period_end.toDate()).format('MMMM Do YYYY')} ({moment(this.props.currentUser.premium.current_period_end.toDate()).fromNow()})</Typography>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Cancel your subscription
        </Button>
        <Dialog
          open={this.state.cancelDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Cancel Pro subscription?"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus disabled={this.props.isCancellingSubscription}>
              Go back
            </Button>
            <Button
              color="secondary"
              disabled={this.props.isCancellingSubscription}
              onClick={() => this.props.cancelSubscription(this.props.currentUser.premium.customerId, this.props.currentUser)}
            >
              {this.props.isCancellingSubscription ? "Processing..." : "Yes, cancel my subscription"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    isCancellingSubscription: state.authReducer.isCancellingSubscription
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (values, uid) => {
      dispatch(updateUser(values, uid));
    },
    cancelSubscription: (customerId, user) => {
      dispatch(cancelSubscription(customerId, user));
      dispatch(updateUserInMailerlite({"email": user.email, "pro_status": "cancelled", "pro_cancelled_date": new Date()}));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProUserSettings);
