import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Typography, Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Tooltip, Button, IconButton } from '@material-ui/core';
import EventStepper from "../../Components/EventStepper";
import EventHeader from "../../Components/EventHeader";
import ResultsTableForPrint from "../../Components/ResultsTableForPrint";
import CheckCircle from '@material-ui/icons/CheckCircle';
import ReactToPrint from 'react-to-print';
import Print from '@material-ui/icons/Print';
import TagManager from 'react-gtm-module';

class Results extends Component {

  render() {
    let roundNumber = this.props.match.params.round;
    let isFinalResults = this.props.match.path === "/events/:id/final-results";

    return (
      <Box>
        <div style={{ display: "none" }}>
          <ResultsTableForPrint
            currentEvent={this.props.currentEvent}
            players={this.props.players}
            ref={el => (this.componentRef = el)}
          />
        </div>
        <EventHeader event={this.props.currentEvent} />
        <EventStepper round={roundNumber} event={this.props.currentEvent} />
        {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && !isFinalResults) &&
          <Typography variant="h2">Swiss results</Typography>
        }
        {(this.props.currentEvent.structure !== "Swiss with Single-elim finals" && !isFinalResults) &&
          <Typography variant="h2">Results</Typography>
        }
        {isFinalResults &&
          <Typography variant="h2">Final results</Typography>
        }
        {this.props.currentEvent.stage === "Running" &&
          <Typography variant="paragraph">These standings are preliminary as the tournament is not finished.</Typography>
        }
        {(isFinalResults && this.props.currentEvent.stage !== "Finished") ? (
          <Box mt={2}>
            <Typography>You need to finish the event before final results are available.</Typography>
          </Box>
        ) : (
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table aria-label="Results table">
                <TableHead>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && this.props.currentEvent.stage === "Swiss finished") &&
                      <TableCell align="center">Qualified</TableCell>
                    }
                    <TableCell align="right">Player</TableCell>
                    <Tooltip title="Wins - Losses - Draws" placement="top-end">
                      <TableCell align="right">Record</TableCell>
                    </Tooltip>
                    <Tooltip title="Opponent's match win percentage" placement="top-end">
                      <TableCell align="right">OMW%</TableCell>
                    </Tooltip>
                    <Tooltip title="Player's game win percentage" placement="top-end">
                      <TableCell align="right">GW%</TableCell>
                    </Tooltip>
                    <Tooltip title="Opponent's game win percentage" placement="top-end">
                      <TableCell align="right">OGW%</TableCell>
                    </Tooltip>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {this.props.players.map((player, index) => (
                      <TableRow key={player.name}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && this.props.currentEvent.stage === "Swiss finished") &&
                          <TableCell align="center">
                            {index + 1 <= this.props.currentEvent.numberOfPlayersInFinal && <CheckCircle color="primary" />}
                          </TableCell>
                        }
                        <TableCell align="right">{player.name}</TableCell>
                        <TableCell align="right">{player.wins} - {player.losses} - {player.draws}</TableCell>
                        <TableCell align="right">{(player.opponentMatchWinPercentage * 100).toFixed(2)} %</TableCell>
                        <TableCell align="right">{(player.gameWinPercentage*100).toFixed(2)} %</TableCell>
                        <TableCell align="right">{(player.opponentGameWinPercentage*100).toFixed(2)} %</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={2} display="flex">
                {(this.props.currentEvent.structure === "Swiss with Single-elim finals" && this.props.currentEvent.stage === "Swiss finished") &&
                  <Box mr={2}>
                    <Button component={Link} to={"/events/" + this.props.currentEvent.id + "/single-elimination"} variant="contained" color="primary">Continue to finals</Button>
                  </Box>
                }
                <Box display={{ xs: 'none', sm: 'block' }}>
                  <ReactToPrint
                    onBeforePrint={() => {
                      const tagManagerArgs = {
                        dataLayer: {
                          genericEvent: true,
                          event: "Results printed",
                          eventCategory: "Event"
                        }
                      }
                      TagManager.dataLayer(tagManagerArgs);
                    }}
                    trigger={() => {
                      return (
                        <IconButton size="small" aria-label="print">
                          <Print />
                        </IconButton>
                      );
                    }}
                    content={() => this.componentRef}
                  />
                </Box>
              </Box>
            </Box>
        )}
        </Box>
    )
  }
}

function compareSwiss(a,b) {
  if (a.score < b.score)
    return 1;
  if (a.score > b.score)
    return -1;
  if (a.opponentMatchWinPercentage < b.opponentMatchWinPercentage)
    return 1;
  if (a.opponentMatchWinPercentage > b.opponentMatchWinPercentage)
    return -1;
  if (a.gameWinPercentage < b.gameWinPercentage)
    return 1;
  if (a.gameWinPercentage > b.gameWinPercentage)
    return -1;
  return 0;
}

function compareFinals(a,b) {
  if (a.finalsPoints < b.finalsPoints)
    return 1;
  if (a.finalsPoints > b.finalsPoints)
    return -1;
  if (a.score < b.score)
    return 1;
  if (a.score > b.score)
    return -1;
  if (a.opponentMatchWinPercentage < b.opponentMatchWinPercentage)
    return 1;
  if (a.opponentMatchWinPercentage > b.opponentMatchWinPercentage)
    return -1;
  if (a.gameWinPercentage < b.gameWinPercentage)
    return 1;
  if (a.gameWinPercentage > b.gameWinPercentage)
    return -1;
  return 0;
}

function mapStateToProps(state, ownProps) {
  let isFinalResults = ownProps.match.path === "/events/:id/final-results";
  let players = [...state.eventReducer.currentEvent.players];
  if (isFinalResults) {
    players.sort(compareFinals);
  } else {
    players.sort(compareSwiss);
  }

  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent,
    players: players
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);
