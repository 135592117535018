import { combineReducers } from 'redux';
import authReducer from './authReducer';
import coreReducer from './coreReducer';
import eventReducer from './eventReducer';
import statsReducer from './statsReducer';

export default combineReducers({
 authReducer,
 coreReducer,
 eventReducer,
 statsReducer
});
