import React, { Component } from 'react';
import { connect } from "react-redux";
import { Box, Button, Typography, Container, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Backdrop } from '@material-ui/core';
import { Alert, AlertTitle} from '@material-ui/lab';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { withStyles } from '@material-ui/core/styles';

import { updateUser, deleteAccount, linkAuthProvider, unlinkAuthProvider, resetPassword, linkEmailAndPassword, reauthenticate, setAuthBackdropOpen } from '../../Actions/authActions';
import { toggleCreditsInfoBackdrop } from "../../Actions/coreActions";
import EditProfileForm from '../../Components/EditProfileForm';
import ProUserSettings from '../../Components/ProUserSettings';
import AddPasswordForm from '../../Components/AddPasswordForm';
import FacebookButton from '../../Components/FacebookButton';
import GoogleButton from '../../Components/GoogleButton';
import ConfirmLoginPasswordForm from "../../Components/ConfirmLoginPasswordForm";

var moment = require('moment');

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
});


class UserSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteAccountDialogOpen: false,
    };
  }

  render() {
    const { classes } = this.props;

    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }
    let user = this.props.currentUser;

    const handleClickOpen = () => {
      this.setState({
        deleteAccountDialogOpen: true
      });
    };

    const handleClose = () => {
      this.setState({
        deleteAccountDialogOpen: false
      });
    };

    return(
      <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>User settings | MTG Event</title>
        </Helmet>
        <Typography variant="h1">User settings</Typography>
        <Box my={3}>
          <Paper>
            <Box p={3}>
              <Typography variant="h2">Profile settings</Typography>
            </Box>
            <Divider />
            <Box p={3}>
              <EditProfileForm
                onSubmit={(values) => this.props.updateUser(values, this.props.currentUser)}
              />
            </Box>
          </Paper>
        </Box>
        <Box my={3}>
          <Paper>
            <Box p={3}>
              <Typography variant="h2">Pro status: {isPremium ? ("Active") : ("Inactive")}</Typography>
            </Box>
            <Divider />
            <Box p={3}>
              {isPremium ? (
                <Box>
                  {user.premium.cancel_at_period_end ? (
                    <Alert severity="warning">
                      <AlertTitle>Subscription canceled</AlertTitle>
                      Your subscription is canceled. Your Pro status will be removed on {moment(user.premium.current_period_end.toDate()).format('MMMM Do YYYY')}
                    </Alert>
                  ) : (
                    <ProUserSettings premium={user.premium} />
                  )}
                </Box>
              ) : (
                <>
                  <Typography variant="body1">With a Pro account you get access to more stats, bigger events and leagues!</Typography>
                  <Button component={Link} to={"/pro"} variant="contained" size="large" color="primary" href="/pro">Get Pro here</Button>
                </>
              )}
            </Box>
          </Paper>
        </Box>
        <Box my={3}>
          <Paper>
            <Box p={3}>
              <Typography variant="h2">Credits</Typography>
            </Box>
            <Divider />
            <Box p={3}>
              <Typography variant="body1">Credits can be used to create better events with more participants. If you run more events, check out MTG Event Pro!</Typography>
              <Typography variant="h2">Credits available: {this.props.currentUser.premium.credits ? (this.props.currentUser.premium.credits) : ("0")}</Typography>
              <Button onClick={() => this.props.toggleCreditsInfoBackdrop()} variant="contained" size="large" color="primary">Purchase credits</Button>
            </Box>
          </Paper>
        </Box>
        <Box my={3}>
          <Paper>
            <Box p={3}>
              <Typography variant="h2">Authentication</Typography>
            </Box>
            <Divider />
            <Box p={3}>
              {this.props.authProviders.includes("password") ? (
                <Button variant="contained" onClick={() => this.props.resetPassword(this.props.currentUser.email)}>Reset my password</Button>
              ) : (
                <Box>
                  <Typography variant="h4">Add a password</Typography>
                  <Typography>You currently don't have a password associated to your account. Set one here to be able to log in with {this.props.currentUser.email} and password.</Typography>
                  <AddPasswordForm onSubmit={(values) => this.props.linkEmailAndPassword(this.props.currentUser.email, values)} />
                  {this.props.confirmLoginRequired &&
                    <Box>
                    <Typography>Please confirm your authentication and then try again.</Typography>
                      <Box>
                      {this.props.authProviders.includes("facebook.com") &&
                        <Button variant="contained" onClick={() => this.props.reauthenticate("facebook.com")}>Confirm with Facebook</Button>
                      }
                      </Box>
                      <Box>
                      {this.props.authProviders.includes("google.com") &&
                        <Button variant="contained" onClick={() => this.props.reauthenticate("facebook.com")}>Confirm with Google</Button>
                      }
                      </Box>
                    </Box>
                  }
                </Box>
              )}
              <Box mt={3}>
                <Typography variant="h4">Auth providers</Typography>
                <Box mt={1}>
                  {this.props.authProviders.includes("facebook.com") ? (
                    <Button variant="contained" disabled={this.props.authProviders.length === 1} onClick={() => this.props.unlinkAuthProvider("facebook.com")}>Unlink Facebook</Button>
                  ) : (
                    <FacebookButton text="Link Facebook account" onClick={() => this.props.linkAuthProvider("facebook.com")} />
                  )}
                </Box>
                <Box mt={1}>
                  {this.props.authProviders.includes("google.com") ? (
                    <Button variant="contained" disabled={this.props.authProviders.length === 1} onClick={() => this.props.unlinkAuthProvider("google.com")}>Unlink Google</Button>
                  ) : (
                    <GoogleButton text="Link Google account" onClick={() => this.props.linkAuthProvider("google.com")} />
                  )}
                </Box>
              </Box>
              {this.props.linkAuthProviderError &&
                <Alert severity="error">{this.props.linkAuthProviderError}</Alert>
              }
              {this.props.resetPasswordError &&
                <Alert severity="error">{this.props.resetPasswordError}</Alert>
              }
              {this.props.resetPasswordSuccess &&
                <Alert severity="success">{this.props.resetPasswordSuccess}</Alert>
              }
            </Box>
          </Paper>
        </Box>
        <Box my={3}>
          <Paper>
            <Box p={3}>
              <Typography variant="h2">Danger zone</Typography>
            </Box>
            <Divider />
            <Box p={3}>
              <Button variant="outlined" color="secondary" onClick={handleClickOpen}>Delete my account</Button>
            </Box>
            <Dialog
              open={this.state.deleteAccountDialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Delete account?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This will delete your account, any planned or running events that you own and unsubscribe from any active Pro subscriptions. This action can not be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Cancel
                </Button>
                <Button onClick={() => {
                    this.props.deleteAccount(this.props.currentUser, this.props.currentUser.premium.customerId);
                    handleClose();
                  }} color="secondary">
                  Yes, delete my account
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Box>
        <Backdrop className={classes.backdrop} open={this.props.authBackdropOpen}>
          <Paper>
            <Box p={2}>
              <Typography variant="h3">Please confirm login and try again</Typography>
              {this.props.authProviders.includes("google.com") &&
                <GoogleButton onClick={() => this.props.reauthenticate("google.com")} text="Re-authenticate with Google"  />
              }
              {this.props.authProviders.includes("facebook.com") &&
                <FacebookButton onClick={() => this.props.reauthenticate("facebook.com")} text="Re-authenticate with Facebook"  />
              }
              {this.props.authProviders.includes("password") &&
                <Box>
                  <Typography>Or enter your password</Typography>
                  <ConfirmLoginPasswordForm onSubmit={(values) => this.props.reauthenticate("password", this.props.currentUser.email, values)} />
                </Box>
              }
              {this.props.reauthenticateError &&
                <Alert severity="error">{this.props.reauthenticateError}</Alert>
              }
              <Button onClick={() => this.props.setAuthBackdropOpen(false)} color="secondary">
                Cancel
              </Button>
            </Box>
          </Paper>
        </Backdrop>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  let authProviders = [];
  state.authReducer.currentAuthData.providerData.forEach((provider, i) => {
    authProviders.push(provider.providerId)
  });

  return {
    currentUser: state.authReducer.currentUser,
    authProviders: authProviders,
    linkAuthProviderError: state.authReducer.linkAuthProviderError,
    resetPasswordError: state.authReducer.resetPasswordError,
    resetPasswordSuccess: state.authReducer.resetPasswordSuccess,
    confirmLoginRequired: state.authReducer.confirmLoginRequired,
    authBackdropOpen: state.authReducer.authBackdropOpen,
    reauthenticateError: state.authReducer.reauthenticateError
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (values, user) => {
      dispatch(updateUser(values, user));
    },
    deleteAccount: (user, customerId) => {
      dispatch(deleteAccount(user, customerId));
    },
    linkAuthProvider: (provider) => {
      dispatch(linkAuthProvider(provider));
    },
    unlinkAuthProvider: (provider) => {
      dispatch(unlinkAuthProvider(provider));
    },
    resetPassword: (email) => {
      dispatch(resetPassword(email));
    },
    linkEmailAndPassword: (email, password) => {
      dispatch(linkEmailAndPassword(email, password));
    },
    reauthenticate: (provider, email, password) => {
      dispatch(reauthenticate(provider, email, password));
    },
    setAuthBackdropOpen: (state) => {
      dispatch(setAuthBackdropOpen(state));
    },
    toggleCreditsInfoBackdrop: () => {
      dispatch(toggleCreditsInfoBackdrop());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserSettings));
