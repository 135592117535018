import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box } from '@material-ui/core';
import MatchPlayerOptions from "./MatchPlayerOptions";
import ScoreStepper from "./ScoreStepper";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    boxShadow: theme.shadows[2],
    borderRadius: "5px"
  },
  winner: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    transition: "background-color .3s"
  },
  number: {
    fontSize: "20px"
  }
});

class MatchPlayer extends Component {

  render() {
    let dropped = this.props.match[this.props.player].dropped;
    let isAdmin = this.props.currentUser.uid === this.props.currentEvent.owner;
    const { classes } = this.props;
    return (
      <Box py={2} px={1} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {(this.props.roundHasPassed && isAdmin) &&
            <MatchPlayerOptions
              togglePlayerDropped={() => this.props.togglePlayerDropped(dropped = !dropped, this.props.match.id, this.props.player)}
              player={this.props.match[this.props.player]}
            />
          }
          <Box pl={1}>
            <Typography variant="body1"  color={dropped ? "error" : "initial"} style={{textDecoration: dropped ? 'line-through' : 'none'}}>{this.props.match[this.props.player].name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {(this.props.roundHasPassed || this.props.editing) && (isAdmin || this.props.isCurrentUsersMatch) ? (
            <ScoreStepper bestOf={this.props.bestOf} winner={this.props.winner} match={this.props.match} changeMatchScore={this.props.changeMatchScore} player={this.props.player} />
          ) : (
            <Box className={`${classes.container} ${(this.props.winner && classes.winner)}`} px={2}>
              <Typography className={classes.number}>{this.props.match[this.props.player].matchScore}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MatchPlayer));
