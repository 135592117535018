import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box, Paper } from '@material-ui/core';
import {Helmet} from "react-helmet";
import ConsoleHelper from "../../ConsoleHelper.js"

import EditEventForm from '../../Components/EditEventForm';

import { updateEvent } from '../../Actions/eventActions';
import { recieveFetchMyAdminLeagues } from '../../Actions/coreActions';

class EditEvent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false
    };
  }

  componentDidMount() {
    this.props.clearMyAdminLeagues();
  }

  render() {

    let isAdmin = this.props.currentUser.uid === this.props.currentEvent.owner;
    if (isAdmin) {
      return (
        <Box>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Edit event | MTG Event</title>
          </Helmet>
          <Box mb={2}>
            <Typography variant="h1">Edit event</Typography>
          </Box>
          <Paper>
            <Box p={3}>
            <EditEventForm
              currentEvent={this.props.currentEvent}
              onSubmit={(values) => this.props.updateEvent(values, this.props.currentUser, this.props.history, this.props.currentEvent.id)}
            />
            </Box>
          </Paper>
        </Box>
      )
    } else {
      return (
        <Box>
          <Typography variant="body1">Only the event admin can edit the event</Typography>
        </Box>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEvent: (values, uid, history, eventId) => {
      ConsoleHelper(values.league);
      if (values.league === "No league" || !values.league) {
        values.league = null;
      } else {
        values.league = {title: values.league.title, id: values.league.id};
      }
      values.id = eventId;
      dispatch(updateEvent(values));
      history.push("/events/" + values.id);
    },
    clearMyAdminLeagues: () => {
      dispatch(recieveFetchMyAdminLeagues([]));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
