import React, { Component } from 'react';
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  number: {
    fontSize: "36px",
    fontWeight: 400
  },
  title: {

  }
};

class StatNumber extends Component {

  render() {
    let { classes } = this.props;
    return (
      <Box textAlign="center" py={0.5}>
        <Typography variant="overline">{this.props.title}</Typography>
        <Typography className={classes.number}>{this.props.number}</Typography>
      </Box>
    )
  }
}


export default withStyles(styles)(StatNumber);
