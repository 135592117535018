import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#60C1BB",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#494E5C"
    },
    background: {
      default: "#EFEFEF"
    }
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontWeight: 400
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 400
    },
    h3: {
      fontSize: "1.5rem"
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 600
    }
  }
});

theme.shadows[1] = "0px 3px 15px rgba(0, 0, 0, 0.1)";

export default theme;
