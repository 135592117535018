import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Box } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Alert from '@material-ui/lab/Alert';

import { invitePlayer } from '../Actions/eventActions';

import * as Yup from 'yup';

class InvitePlayerForm extends Component {

  render() {
    return (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .min(2, 'Too Short!')
              .max(50, 'Too Long!')
              .required('Required')
              .email('Invalid email'),
          })}
          onSubmit={(values, actions) => {
            this.props.invitePlayer(values.email, this.props.currentEvent);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Field component={TextField} margin="normal" fullWidth label="Player email" variant="outlined" type="email" name="email" />
              <Button size="large" type="submit" variant="contained" color="primary">Invite player</Button>
              {this.props.error &&
                <Box mt={2}>
                  <Alert
                    severity="error"
                  >
                    {this.props.error}
                  </Alert>
                </Box>
              }
            </Form>
          )}
        </Formik>
    )
  }
}


function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent,
    error: state.eventReducer.invitePlayerError
  };
}

function mapDispatchToProps(dispatch) {
  return {
    invitePlayer: (playerName, currentEvent) => {
      dispatch(invitePlayer(playerName, currentEvent))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitePlayerForm);
