import React, { useState } from 'react';
import { Paper, Box, Typography, Button, Backdrop, Link, Dialog } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CreditsProduct from "./CreditsProduct";
import CreditsBackdrop from "./CreditsBackdrop";

import { toggleCreditsInfoBackdrop, togglePurchaseBackdrop, fetchClientSecret, setClientSecret } from "../Actions/coreActions";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "40px",
    color: theme.palette.secondary.main
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  purchaseContainer: {
    maxWidth: "800px"
  },
  purchasePaper: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF"
  },
  creditsPackage: {
    backgroundColor: "#FFFFFF",
  }
}))

export default function CreditsInfoBackdrop(props) {
  const classes = useStyles();
  const theme = useTheme();
  const currentUser = useSelector(state => state.authReducer.currentUser);
  const backdropOpen = useSelector(state => state.coreReducer.creditsInfoBackdropOpen);
  const purchaseCreditsBackdropOpen = useSelector(state => state.coreReducer.purchaseBackdropOpen);
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  let isPremium = false;
  let currentCredits = null;
  if (currentUser.premium) {
    isPremium = (currentUser.premium.status === "active" || false);
    currentCredits = currentUser.premium.credits;
  }

  const [volume, setVolume] = useState(0);
  const [amount, setAmount] = useState(0);

  const handlePurchaseCreditsOpen = (product) => {
    dispatch(togglePurchaseBackdrop());
    dispatch(fetchClientSecret(product.volume));
    setVolume(product.volume);
    setAmount(product.amount);
  }

  const closePurchaseBackdrop = () => {
    dispatch(togglePurchaseBackdrop());
    dispatch(setClientSecret(null));
  }

  return (
    <>
      <Dialog
        className={classes.backdrop}
        open={backdropOpen}
        fullScreen={fullScreen}
        maxWidth="lg"
      >
        <Box
          display="flex"
          direction="row"
          justify="center"
          alignItems="center"
          textAlign="center"
          className={classes.purchaseContainer}
        >
          <Paper className={classes.purchasePaper}>
            <Box px={2} py={3}>
              <Typography variant="h2">Add MTG Event Credits</Typography>
              <Typography>Your current balance is {currentCredits ? (currentCredits) : ("0")} credits.</Typography>
              <Box mt={2} mb={2}>
                <Typography variant="body2">Credits are used to run bigger events. All events with up to eight people are free. Every participant after that cost 1 credit. If you run many events or want other features such as leagues or statistics, check out MTG Event Pro!</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                mt={2}
                mb={2}
              >
                <CreditsProduct
                  volume={10}
                  amount={9.99}
                  onClick={() => handlePurchaseCreditsOpen({volume: 10, amount: 9.99})}
                />
                <CreditsProduct
                  volume={20}
                  amount={14.99}
                  onClick={() => handlePurchaseCreditsOpen({volume: 20, amount: 14.99})}
                />
                <CreditsProduct
                  volume={50}
                  amount={29.99}
                  onClick={() => handlePurchaseCreditsOpen({volume: 50, amount: 29.99})}
                />
              </Box>
              <Box>
                <Typography>OR</Typography>
              </Box>
              <Box mb={2} mt={2}>
                <Paper>
                  <Box p={2}>
                    <Typography variant="h6">Get MTG Event Pro</Typography>
                    <Typography variant="body2">With a Pro subscription your can run any number of tournaments of any size for only $5.99 per month (billed every 3 months)! It's ideal for anyone running more than just a few small tournaments. </Typography>
                    <Box mt={2}>
                      <Button component={Link} to={"/pro"} variant="contained" size="large" color="primary" href="/pro">Get Pro here</Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Button
                onClick={() => dispatch(toggleCreditsInfoBackdrop())}
              >
                Close
              </Button>
            </Box>
          </Paper>
        </Box>
      </Dialog>
      {purchaseCreditsBackdropOpen &&
        <CreditsBackdrop
          handleClose={() => closePurchaseBackdrop()}
          creditsBackdropOpen={purchaseCreditsBackdropOpen}
          volume={volume}
          amount={amount}
        />
      }
    </>
  )
};
