import firebase from "../firebase.js"
import ConsoleHelper from "../ConsoleHelper.js"

const requestFetchStats = () => {
  return {
    type: "FETCH_MY_STATS_REQUEST"
  };
};

const successFetchStats = (stats) => {
  return {
    type: "FETCH_MY_STATS_SUCCESS",
    stats
  };
};

export const fetchMyStats = (uid) => dispatch => {
  ConsoleHelper(uid);
  dispatch(requestFetchStats());
  let events = [];
  firebase.firestore().collection("events").where("playerIds", "array-contains", uid).where("stage", "==", "Finished")
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        events.push(doc.data());
      });
      let stats = calculateStats(events);
      dispatch(successFetchStats(stats));
    })
    .catch(function(error) {
        ConsoleHelper("Error getting documents: ", error);
    });

    function calculateAverage(arr) {
      const sum = arr.reduce((a, b) => a + b, 0);
      const avg = (sum / arr.length) || 0;
      return avg;
    }

    function calculateStats(events) {
      let stats = {};
      stats.numberOfEventsFinished = events.length;
      let finishes = [];
      let numberOfPlayers = [];
      // Loop all attended events and get data
      for (let i = 0; i < events.length; i++) {
        // Get every finishing position
        finishes.push(events[i].players.findIndex(player => player.uid === uid)+1);
        numberOfPlayers.push(events[i].players.length);
      }

      // Calculate average finish

      stats.averageFinish = calculateAverage(finishes);
      stats.averagePlayers = calculateAverage(numberOfPlayers);

      return stats;
    }
  };
