import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Typography, CircularProgress, Box } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import history from '../history';
import ConsoleHelper from "../ConsoleHelper.js"

import { fetchMyAdminLeagues } from "../Actions/coreActions";

import * as Yup from 'yup';

const FormikDatePicker = ({
  name,
  label,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // ConsoleHelper(rest);
  return (
      <DateTimePicker
        autoOk
        name={name}
        label={label}
        onChange={value => {
          setFieldValue("date", value._d);
        }}
        value={value}
        inputVariant="outlined"
        margin="normal"
        fullWidth
        animateYearScrolling={false}
        minDate={new Date()}
      />
  );
};

const TypeSelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <FormControl variant="outlined" margin="normal" fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          ConsoleHelper(event.target.value)
          setFieldValue("type", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={80}
      >
        <MenuItem value="Constructed">Constructed</MenuItem>
        <MenuItem value="Draft">Draft</MenuItem>
        <MenuItem value="Sealed">Sealed</MenuItem>
      </Select>
    </FormControl>
  )
}

const VisibilitySelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <FormControl variant="outlined" margin="normal" disabled={rest.disabled} fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          ConsoleHelper(event.target.value)
          setFieldValue("visibility", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={105}
      >
        <MenuItem value="request">Anyone with the link can request to join</MenuItem>
        <MenuItem value="open">Anyone with the link can join</MenuItem>
        <MenuItem value="hidden">Invite only</MenuItem>
      </Select>
    </FormControl>
  )
}

const StructureSelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <FormControl variant="outlined" margin="normal" disabled={rest.disabled} fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          ConsoleHelper(event.target.value)
          setFieldValue("structure", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={160}
      >
        <MenuItem value="Swiss">Swiss</MenuItem>
        <MenuItem value="Single-elimination">Single-elimination</MenuItem>
        <MenuItem value="Swiss with Single-elim finals">Swiss with single-elimination finals</MenuItem>
      </Select>
    </FormControl>
  )
}

const LeagueSelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  ConsoleHelper(rest);
  let disabled = false;

  if (rest.values.structure === "Single-elimination") {
    disabled = true;
  }

  return (
    <FormControl variant="outlined" margin="normal" fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          setFieldValue("league", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={140}
        onOpen={rest.onOpen}
        disabled={disabled}
        >
        {(rest.currentLeague && rest.leagues.length === 0) &&
          <MenuItem value={rest.currentLeague}>{rest.currentLeague.title}</MenuItem>
        }
        {(!rest.loading && rest.leagues.length > 0) &&
          <MenuItem value={"No league"}>Don't add to league</MenuItem>
        }
        {rest.leagues.map((league) =>
          <MenuItem value={league}>{league.title}</MenuItem>
        )}
        {(!rest.loading && rest.leagues.length === 0) &&
          <Typography>You don't have any elegible leagues</Typography>
        }
        {rest.loading &&
          <CircularProgress />
        }
      </Select>
    </FormControl>
  )
}

class EditEventForm extends Component {

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{
            title: this.props.currentEvent.title,
            location: this.props.currentEvent.location,
            date: this.props.currentEvent.date.toDate(),
            type: this.props.currentEvent.type,
            visibility: this.props.currentEvent.visibility,
            structure: this.props.currentEvent.structure || "Swiss",
            league: this.props.currentEvent.league }}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .min(2, 'Too Short!')
              .max(50, 'Too Long!')
              .required('Required'),
            location: Yup.string()
              .required('Required'),
          })}
          onSubmit={(values) => {
            this.props.onSubmit(values);
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <Field required component={TextField} margin="normal" fullWidth label="Title" variant="outlined" type="text" name="title" />
              <Field required component={TextField} margin="normal" fullWidth label="Event location" variant="outlined" type="text" name="location" />
              <Field component={FormikDatePicker} label="Event date" name="date" />
              <Field component={TypeSelect} label="Event type" name="type" />
              <Field component={StructureSelect} label="Tournament structure" name="structure" />
              <Field component={TextField} multiline margin="normal" fullWidth label="Description" variant="outlined" type="text" name="description" />
              <Field component={VisibilitySelect} label="Who can join?" name="visibility" />
              <Field component={LeagueSelect} label="Add event to league" name="league"
                onOpen={event => {
                    if (this.props.myAdminLeagues.length < 1) {
                      this.props.fetchMyAdminLeagues(this.props.currentUser.uid);
                    }
                  }}
                currentLeague={this.props.currentEvent.league}
                leagues={this.props.myAdminLeagues}
                loading={this.props.isFetchingMyAdminLeagues}
                values={values}
              />
              <Box my={3} display="flex">
                <Box mr={1}>
                  <Button size="large" type="submit" variant="contained" color="primary">Save</Button>
                </Box>
                <Button size="large" variant="outlined" color="secondary" onClick={() => history.goBack()}>Cancel</Button>
              </Box>
              {this.props.authError &&
                <Typography>{this.props.authError.message}</Typography>
              }
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    )
  }
}

function mapStateToProps(state) {
  // TODO: Make sure we only return leagues that isn't finished yet
  return {
    currentUser: state.authReducer.currentUser,
    myAdminLeagues: state.coreReducer.myAdminLeagues,
    isFetchingMyAdminLeagues: state.coreReducer.isFetchingMyAdminLeagues
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMyAdminLeagues: (playerId) => {
      dispatch(fetchMyAdminLeagues(playerId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEventForm);
