import firebase from "../firebase.js"
import ConsoleHelper from "../ConsoleHelper.js"
import TagManager from 'react-gtm-module';
// import ReactPixel from 'react-facebook-pixel'

// EVENT STAGES
//===============//
// 1 = Event Planned
// 2 = Creating pods
// 3 = Playing round 1
// 4 = Playing round 2
// 5 = Playing round 3 etc...


const requestCreateEvent = () => {
  return {
    type: "CREATE_EVENT_REQUEST"
  };
};

const successCreateEvent = () => {
  return {
    type: "CREATE_EVENT_SUCCESS"
  };
};

const errorCreateEvent = (error) => {
  return {
    type: "CREATE_EVENT_ERROR",
    error
  };
};

const requestCreateLeague = () => {
  return {
    type: "CREATE_LEAGUE_REQUEST"
  };
};

const successCreateLeague = () => {
  return {
    type: "CREATE_LEAGUE_SUCCESS"
  };
};

const errorCreateLeague = (error) => {
  return {
    type: "CREATE_LEAGUE_ERROR",
    error
  };
};

const requestGetEvent = () => {
  return {
    type: "GET_EVENT_REQUEST"
  };
};

const successGetEvent = (eventData) => {
  return {
    type: "GET_EVENT_SUCCESS",
    eventData
  }
}

const requestGetLeague = () => {
  return {
    type: "GET_LEAGUE_REQUEST"
  };
};

const successGetLeague = (leagueData) => {
  return {
    type: "GET_LEAGUE_SUCCESS",
    leagueData
  }
}

const requestGetPods = () => {
  return {
    type: "GET_PODS_REQUEST"
  };
};

const successGetPods = (pods) => {
  return {
    type: "GET_PODS_SUCCESS",
    pods
  }
}

const setTemporaryPods = (pods) => {
  return {
    type: "SET_TEMPORARY_PODS",
    pods
  }
}

export const successSetMatches = (matches) => {
  return {
    type: "SET_MATCHES_SUCCESS",
    matches
  };
};

const errorSetMatches = (error) => {
  return {
    type: "SET_MATCHES_ERROR",
    error
  };
};

const setMatchScore = (score, matchId, player) => {
  return {
    type: "SET_MATCH_SCORE",
    score,
    matchId,
    player
  }
}

const setPlayerDropped = (dropped, matchId, player) => {
  return {
    type: "SET_PLAYER_DROPPED",
    dropped,
    matchId,
    player
  }
}

const errorInvitePlayer = (error) => {
  return {
    type: "INVITE_PLAYER_ERROR",
    error
  }
}

const setCurrentLeagueEvents = (currentLeagueEvents) => {
  return {
    type: "SET_CURRENT_LEAGUE_EVENTS",
    currentLeagueEvents
  }
}

const calculateRounds = (numberOfPlayers, numberOfPods) => {
  let recommendedRounds = 3;
  if ((numberOfPlayers / numberOfPods) > 410) {
    recommendedRounds = 10;
  } else if((numberOfPlayers / numberOfPods) >= 227) {
    recommendedRounds = 9;
  } else if((numberOfPlayers / numberOfPods) >= 129) {
    recommendedRounds = 8;
  } else if((numberOfPlayers / numberOfPods) >= 65) {
    recommendedRounds = 7;
  } else if((numberOfPlayers / numberOfPods) >= 33) {
    recommendedRounds = 6;
  } else if((numberOfPlayers / numberOfPods) >= 17) {
    recommendedRounds = 5;
  } else if((numberOfPlayers / numberOfPods) >= 9) {
    recommendedRounds = 4;
  } else if ((numberOfPlayers / numberOfPods) >= 5) {
    recommendedRounds = 3;
  } else if ((numberOfPlayers / numberOfPods) >= 3) {
    recommendedRounds = 2;
  } else {
    recommendedRounds = 1;
  }

  return recommendedRounds;
}

const calculatePods = (numberOfPlayers, eventType) => {
  let recommendedPods = Math.round(numberOfPlayers / 8);
  if (numberOfPlayers < 10) {
    recommendedPods = 1;
  }
  if (eventType !== "Draft") {
    recommendedPods = 1;
  }
  return recommendedPods;
}

export const createLeague = (leagueData, player) => dispatch => {
  dispatch(requestCreateLeague());

  const ref = firebase.firestore().collection("leagues").doc();

  leagueData.owner = player.uid;
  leagueData.created = new Date();
  leagueData.stage = "Planned";
  leagueData.id = ref.id;
  leagueData.events = [];
  leagueData.playerIds = [player.uid];
  leagueData.players = [{uid: player.uid, score: 0, name: player.name, eventsPlayed: 0}];
  leagueData.finishedEvents = 0;
  leagueData.history = [];

  ref.set(leagueData)
    .then(function() {
      ConsoleHelper("Document successfully written!");
      dispatch(successCreateLeague());

      const tagManagerArgs = {
        dataLayer: {
          genericEvent: true,
          event: "League created",
          properties: {
            leagueId: leagueData.id
          },
          eventCategory: "League"
        }
      }
      TagManager.dataLayer(tagManagerArgs);

    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
      dispatch(errorCreateLeague(error));
    });

    return new Promise((res, rej) => {
      ref.set(leagueData)
        .then(function() {
          ConsoleHelper("Document successfully written!");
          dispatch(successCreateLeague());
          return res(leagueData.id);
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
          dispatch(errorCreateLeague(error));
          return rej(error);
        });
    });
};

export const getCurrentLeague = (leagueId) => dispatch => {
  dispatch(requestGetLeague());
  firebase.firestore().collection("leagues").doc(leagueId).get().then(function(doc) {
      if (doc.exists) {
          dispatch(successGetLeague(doc.data()));
          dispatch(getCurrentLeagueEvents(doc.data().id));
      } else {
          // doc.data() will be undefined in this case
          ConsoleHelper("No such document!");
          dispatch(successGetLeague("404"));
      }
  }).catch(function(error) {
      ConsoleHelper("Error getting document:", error);
  });
}

export const setCurrentLeague = (leagueData) => dispatch => {
  dispatch(successGetLeague(leagueData));
}

export const getCurrentLeagueEvents = (leagueId) => dispatch => {
  firebase.firestore().collection("events").where("league.id", "==", leagueId)
    .get()
    .then(function(querySnapshot) {
      if(!querySnapshot.empty) {
        let currentLeagueEvents = [];
        querySnapshot.forEach(function(doc) {
          currentLeagueEvents.push(doc.data());
        });
        dispatch(setCurrentLeagueEvents(currentLeagueEvents));
      } else {
        dispatch(setCurrentLeagueEvents([]));
      }
    })
    .catch(function(error) {
      ConsoleHelper("Error getting documents: ", error);
    });
}

export const createEvent = (eventData, player) => dispatch => {
  dispatch(requestCreateEvent());

  const ref = firebase.firestore().collection("events").doc();

  let firstPlayer = {
   name: player.name,
   uid: player.uid,
   status: "Attending",
   wins: 0,
   draws: 0,
   losses: 0,
   score: 0,
   gameScore: 0,
   gamesWon: 0,
   gamesLost: 0,
   matchWinPercentage: 0,
   gameWinPercentage: 0,
   opponentMatchWinPercentage: 0,
   opponentGameWinPercentage: 0,
   played: [],
   dropped: false,
   finalsPoints: 0
  }

  eventData.owner = player.uid;
  eventData.created = new Date();
  eventData.playerIds = [player.uid];
  eventData.stage = "Planned";
  eventData.stageNumber = 1;
  eventData.players = [firstPlayer];
  eventData.id = ref.id;
  eventData.roundTimeLimit = 45;
  eventData.numberOfRounds = 3;
  eventData.numberOfPods = 1;
  eventData.bestOf = 3;
  eventData.allowCrossPodPairings = false;
  eventData.logs = [{action: "Event created", time: new Date()}]

  ref.set(eventData)
    .then(function() {
      ConsoleHelper("Document successfully written!");
      dispatch(successCreateEvent());

      const tagManagerArgs = {
        dataLayer: {
          genericEvent: true,
          event: "Event created",
          properties: {
            eventType: eventData.type,
            eventStructure: eventData.structure,
            eventTitle: eventData.title
          },
          eventCategory: "Event"
        }
      }
      TagManager.dataLayer(tagManagerArgs);

    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
      dispatch(errorCreateEvent(error));
    });

    return new Promise((res, rej) => {
      ref.set(eventData)
        .then(function() {
          ConsoleHelper("Document successfully written!");
          dispatch(successCreateEvent());
          return res(eventData.id);
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
          dispatch(errorCreateEvent(error));
          return rej(error);
        });
    });
};

export const getCurrentEvent = () => dispatch => {
  dispatch(requestGetEvent());
}

export const setCurrentEvent = (eventData) => dispatch => {
  dispatch(successGetEvent(eventData));
}

export const addCustomPlayer = (newPlayer, currentEvent) => dispatch => {
  let idNum = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  let player = {
   name: newPlayer,
   uid: "BOT-" + idNum,
   status: "Attending",
   wins: 0,
   draws: 0,
   losses: 0,
   score: 0,
   gameScore: 0,
   gamesWon: 0,
   gamesLost: 0,
   matchWinPercentage: 0,
   gameWinPercentage: 0,
   opponentMatchWinPercentage: 0,
   opponentGameWinPercentage: 0,
   played: [],
   dropped: false,
   finalsPoints: 0
  }

  currentEvent.players.push(player);
  currentEvent.playerIds.push("BOT-" + idNum);

  currentEvent.numberOfPods = calculatePods(currentEvent.players.length, currentEvent.type);
  currentEvent.numberOfRounds = calculateRounds(currentEvent.players.length, currentEvent.numberOfPods);

  currentEvent.logs.push = {action: "Player " + newPlayer + " added", time: new Date()}

  const tagManagerArgs = {
    dataLayer: {
      genericEvent: true,
      event: "Player added",
      properties: {
        typeOfPlayer: "Bot"
      },
      eventCategory: "Event"
    }
  }
  TagManager.dataLayer(tagManagerArgs);

  dispatch(updateEvent(currentEvent));
}

export const invitePlayer = (playerEmail, currentEvent) => dispatch => {
  ConsoleHelper(playerEmail);
  firebase.firestore().collection("users").where("email", "==", playerEmail)
    .get()
    .then(function(querySnapshot) {
      if(!querySnapshot.empty) {
        querySnapshot.forEach(function(doc) {
          let invitedPlayer = doc.data();
          if(currentEvent.playerIds.includes(invitedPlayer.uid)) {
            // Player is already in event
            dispatch(errorInvitePlayer("This player is already invited"))
            return;
          }
          let player = {
             name: invitedPlayer.name,
             uid: invitedPlayer.uid,
             status: "Invite pending",
             wins: 0,
             draws: 0,
             losses: 0,
             score: 0,
             gameScore: 0,
             gamesWon: 0,
             gamesLost: 0,
             matchWinPercentage: 0,
             gameWinPercentage: 0,
             opponentMatchWinPercentage: 0,
             opponentGameWinPercentage: 0,
             played: [],
             dropped: false,
             finalsPoints: 0
          }
          currentEvent.players.push(player);
          currentEvent.playerIds.push(invitedPlayer.uid);
          currentEvent.logs.push = {action: "Player " + invitedPlayer.name + " was invited", time: new Date()}
          dispatch(updateEvent(currentEvent));

          const tagManagerArgs = {
            dataLayer: {
              genericEvent: true,
              event: "Player invited to event",
              properties: {
                typeOfPlayer: "User",
                eventId: currentEvent.id
              },
              eventCategory: "Event"
            }
          }
          TagManager.dataLayer(tagManagerArgs);

          let notification = {
            title: "You've been invited to an event!",
            body: "You're invited to attend the event " + currentEvent.title + ". Click here to join!",
            read: false,
            created: new Date(),
            link: "/events/" + currentEvent.id
          }
          dispatch(createNotification(invitedPlayer.uid, notification));
        });
      } else {
        // There is no user with this email
        dispatch(errorInvitePlayer("There is no user with this email"))
      }
    })
    .catch(function(error) {
      ConsoleHelper("Error getting documents: ", error);
    });
}

export const requestToJoin = (currentUser, currentEvent) => dispatch => {
  let player = {
     name: currentUser.name,
     uid: currentUser.uid,
     status: "Join request pending",
     wins: 0,
     draws: 0,
     losses: 0,
     score: 0,
     gameScore: 0,
     gamesWon: 0,
     gamesLost: 0,
     matchWinPercentage: 0,
     gameWinPercentage: 0,
     opponentMatchWinPercentage: 0,
     opponentGameWinPercentage: 0,
     played: [],
     dropped: false,
     finalsPoints: 0
  }
  currentEvent.players.push(player);
  currentEvent.playerIds.push(player.uid);
  currentEvent.logs.push = {action: "Player " + currentUser.name + " requested to join", time: new Date()}
  dispatch(updateEvent(currentEvent));

  const tagManagerArgs = {
    dataLayer: {
      genericEvent: true,
      event: "Player requested to join event",
      properties: {
        eventId: currentEvent.id
      },
      eventCategory: "Event"
    }
  }
  TagManager.dataLayer(tagManagerArgs);

  let notification = {
    title: currentUser.name + " have asked to join your event.",
    body: currentUser.name + " have asked to join your event " + currentEvent.title + ". Click here to accept or reject.",
    read: false,
    created: new Date(),
    link: "/events/" + currentEvent.id
  }
  dispatch(createNotification(currentEvent.owner, notification));
}

export const joinEvent = (currentUser, currentEvent) => dispatch => {
  ConsoleHelper(currentUser);
  let player = {
     name: currentUser.name,
     uid: currentUser.uid,
     status: "Attending",
     wins: 0,
     draws: 0,
     losses: 0,
     score: 0,
     gameScore: 0,
     gamesWon: 0,
     gamesLost: 0,
     matchWinPercentage: 0,
     gameWinPercentage: 0,
     opponentMatchWinPercentage: 0,
     opponentGameWinPercentage: 0,
     played: [],
     dropped: false,
     finalsPoints: 0
  }
  currentEvent.players.push(player);
  currentEvent.playerIds.push(player.uid);
  currentEvent.logs.push = {action: "Player " + currentUser.name + " joined the event", time: new Date()}
  dispatch(updateEvent(currentEvent));

  const tagManagerArgs = {
    dataLayer: {
      genericEvent: true,
      event: "Player joined event",
      properties: {
        eventId: currentEvent.id
      },
      eventCategory: "Event"
    }
  }
  TagManager.dataLayer(tagManagerArgs);
}

export const createNotification = (playerId, notification) => dispatch => {
  firebase.firestore().collection("users").doc(playerId).collection("notifications").add(notification);
}

export const removePlayer = (playerId, currentEvent) => dispatch => {
  let playerIds = currentEvent.playerIds;
  let players = currentEvent.players;
  playerIds.splice( playerIds.indexOf(playerId), 1 );
  players = players.filter(function(el) { return el.uid !== playerId; });

  currentEvent.players = players;
  currentEvent.playerIds = playerIds;

  currentEvent.numberOfPods = calculatePods(currentEvent.players.length, currentEvent.type);
  currentEvent.numberOfRounds = calculateRounds(currentEvent.players.length, currentEvent.numberOfPods);

  dispatch(updateEvent(currentEvent));
}

export const updatePlayers = (updatedPlayers, eventId) => dispatch => {
  firebase.firestore().collection("events").doc(eventId).update({
    players: updatedPlayers
  })
}

export const updateEvent = (updatedEvent) => dispatch => {
  ConsoleHelper("Updating event:")
  ConsoleHelper(updatedEvent);
  firebase.firestore().collection("events").doc(updatedEvent.id).set({
    ...updatedEvent
  }, {merge: true}).then(function() {
    ConsoleHelper("Event updated")
  })
}

export const deleteEvent = (eventId) => dispatch => {
  firebase.firestore().collection("events").doc(eventId).delete().then(function() {
    ConsoleHelper("Document successfully deleted!");
    dispatch(setCurrentEvent({}));
    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "Event deleted",
        properties: {
          eventId: eventId
        },
        eventCategory: "Event"
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }).catch(function(error) {
    console.error("Error removing document: ", error);
  });
}

export const deleteLeague = (leagueId) => dispatch => {
  firebase.firestore().collection("leagues").doc(leagueId).delete().then(function() {
    ConsoleHelper("Document successfully deleted!");
    dispatch(setCurrentLeague({}));
    dispatch(deleteLeagueFromEvents(leagueId))
    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "League deleted",
        properties: {
          eventId: leagueId
        },
        eventCategory: "League"
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }).catch(function(error) {
    console.error("Error removing document: ", error);
  });
}

// TODO: MOVE SERVER SIDE
export const deleteLeagueFromEvents = (leagueId) => dispatch => {
  let batch = firebase.firestore().batch();

  firebase.firestore().collection("events").where("league.id", "==", leagueId)
  .get()
  .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        // doc.data() is never undefined for query doc snapshots
        ConsoleHelper(doc.id, " => ", doc.data());
        let ref = firebase.firestore().collection("events").doc(doc.data().id);
        batch.update(ref, {"league": null});
      });
      batch.commit().then(function () {
        ConsoleHelper("Affected events updated");
      });
    })
    .catch(function(error) {
      ConsoleHelper("Error getting documents: ", error);
    });
}

export const createPods = (currentEvent) => dispatch => {

  if (currentEvent === null) {
    dispatch(setTemporaryPods(null));
    return;
  }

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  let pods = [];
  let players = currentEvent.players;
  let numberOfPods = currentEvent.numberOfPods;
  shuffle(players);
  for (let i = 0; i < numberOfPods; i++) {
    if ((i+1) === numberOfPods) {
      pods.push(players);
    } else {
      let pod = [];
      let sliceNumber = Math.round(players.length/numberOfPods);
      pod = players.splice(sliceNumber);
      pods.push(pod);
    }
  }
  currentEvent.players = [].concat.apply([], pods);
  currentEvent.logs.push = {action: "Pods were created", time: new Date()}

  if (currentEvent.type === "Draft") {
    dispatch(setTemporaryPods(pods));
  } else {
    dispatch(savePods(pods, currentEvent))
  }
}

export const savePods = (pods, currentEvent) => dispatch => {
  ConsoleHelper(currentEvent);
  // Loop each pod, and then each player in each pod
  // For each player, find the corresponding player in the event players object by id
  // Add the pod number for each player
  for (let i = 0; i < pods.length; i++) {
    for(let j = 0; j < pods[i].length; j++) {
      let playerToUpdate = currentEvent.players.findIndex((player => player.uid === pods[i][j].uid));
      currentEvent.players[playerToUpdate].pod = (i+1);
    }
  }
  currentEvent.stageNumber = 3;
  currentEvent.logs.push = {action: "Pods were saved", time: new Date()}
  dispatch(updateEvent(currentEvent))
  dispatch(setTemporaryPods(null));
}

export const getCurrentPods = () => dispatch => {
  dispatch(requestGetPods());
}

export const setCurrentPods = (event) => dispatch => {
  if(event === null) {
    dispatch(successGetPods([]));
    return;
  } 
  let pods = [];
  for(let i = 0; i < event.numberOfPods; i++) {
      pods.push([]);
  }

  for(let i = 0; i < event.players.length; i++) {
    pods[event.players[i].pod - 1].push(event.players[i]);
  }
  dispatch(successGetPods(pods));
}

export const generateSingleEliminationTree = (event) => dispatch => {

  function getBaseLog(x, y) {
    return Math.log(y) / Math.log(x);
  }

  function sortByMatchNumberReverse(a, b) {
    // Use toUpperCase() to ignore character casing
    const matchNumA = a.matchNumber;
    const matchNumB = b.matchNumber;

    let comparison = 0;
    if (matchNumA > matchNumB) {
      comparison = 1;
    } else if (matchNumA < matchNumB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  // Copying the players to a new object to get rid of the bug where they get deleted from state
  let eventPlayersCopy = JSON.parse(JSON.stringify(event.players));

  // If this is TopX remove players to get the right amount
  if (event.structure === "Swiss with Single-elim finals") {
    eventPlayersCopy.length = event.numberOfPlayersInFinal;
  }

  let coreNumberOfRounds = getBaseLog(2, eventPlayersCopy.length);
  let numberOfRounds = Math.ceil(coreNumberOfRounds);
  let next = Math.pow(2, Math.ceil(Math.log(eventPlayersCopy.length)/Math.log(2)));
  let numberOfByes = next - eventPlayersCopy.length;
  let playersLeft = eventPlayersCopy.length - numberOfByes;
  const numberOfMatchesFirstRound = playersLeft / 2;

  // Hitta nästa tal som är en jämn faktor av 2
  // Ta antal spelare minus denna siffra. Det blir antalet byes som behövs
  // Då behövs bara matcher för de spelare som är kvar i första rundan
  let matches = [];
  let roundNumber = 1;
  let matchNumber = 1;
    // Pair first round of matches - this should be tiny

    // TODO !!!!! There is a bug here where we don't set the correct nextMatchNumber if one or more of the matches in round 2 are empty

    for (let i = 0; i < numberOfMatchesFirstRound; i++) {
      let match = {
        round: roundNumber,
        isFinal: event.structure === "Swiss with Single-elim finals",
        player1: {
          name: eventPlayersCopy[0].name,
          matchScore: 0,
          uid: eventPlayersCopy[0].uid
        },
        player2: {
          name: eventPlayersCopy[eventPlayersCopy.length - 1].name,
          matchScore: 0,
          uid: eventPlayersCopy[eventPlayersCopy.length - 1].uid
        },
        matchNumber: matchNumber,
        nextMatchNumber: matchNumber,
        matchSaved: false
      }
      matches.push(match);
      eventPlayersCopy.shift();
      eventPlayersCopy.pop();
      matchNumber++;
    }
    roundNumber++;
    // Can we do something like this:
    // For each match in the first round, create a match in the second round with only one player
    // If there are players left when all matches are cycled through, create with the rest of our players.
    matchNumber = 1;
    let numberOfSingleMatches = matches.length;
    let singleMatchesCreated = 0;
    for (let i = 0; i < numberOfSingleMatches; i++) {
      if (eventPlayersCopy.length > 0) {
        let match = {
          round: roundNumber,
          isFinal: event.structure === "Swiss with Single-elim finals",
          player1: {
            name: eventPlayersCopy[0].name,
            matchScore: 0,
            uid: eventPlayersCopy[0].uid
          },
          player2: null,
          matchNumber: matchNumber,
          nextMatchNumber: Math.ceil(matchNumber/2),
          matchSaved: false
        }
        matches.push(match);
        eventPlayersCopy.shift();
        matchNumber++;
        singleMatchesCreated++;
      }
    }

    if (eventPlayersCopy.length > 0) {
      let numberOfMatchesLeft = eventPlayersCopy.length/2;
      for (let i = 0; i < numberOfMatchesLeft; i++) {
        let match = {
          round: roundNumber,
          isFinal: event.structure === "Swiss with Single-elim finals",
          player1: {
            name: eventPlayersCopy[0].name,
            matchScore: 0,
            uid: eventPlayersCopy[0].uid
          },
          player2: {
            name: eventPlayersCopy[1].name,
            matchScore: 0,
            uid: eventPlayersCopy[1].uid
          },
          matchNumber: matchNumber,
          nextMatchNumber: Math.ceil(matchNumber/2),
          matchSaved: false
        }
        matches.push(match);
        eventPlayersCopy.shift();
        eventPlayersCopy.shift();
        matchNumber++;
      }
    }

    let emptyMatchesToCreate = (numberOfMatchesFirstRound - singleMatchesCreated)/2;
    if(emptyMatchesToCreate > 0) {
      for (let i = 0; i < emptyMatchesToCreate; i++) {
        let match = {
          round: roundNumber,
          isFinal: event.structure === "Swiss with Single-elim finals",
          player1: null,
          player2: null,
          matchNumber: matchNumber,
          nextMatchNumber: Math.ceil(matchNumber/2),
          matchSaved: false
        }
        matches.push(match);
        // Don't increase in the last iteration
        if ((i+1) !== emptyMatchesToCreate) {
          matchNumber++;
        }
      }
      // IDEA Go over all matches in round 1 here. Iterate through them by matchNumber backwards. Assign nextMatchNumber corresponding to the empty we created now.
      matches.sort(sortByMatchNumberReverse);
      emptyMatchesToCreate = emptyMatchesToCreate*2;
      matches.forEach((match, i) => {
        if(match.round === 1 && emptyMatchesToCreate !== 0) {
          // Set nextMatchNumber for match with highest matchNumber in round one to the last empty match.
          match.nextMatchNumber = Math.ceil(matchNumber);
          // Lower the matchNumber with .5 in order to give the same number to two matches in round 1
          matchNumber = matchNumber - 0.5;
          // Lower emptyMatchesToCreate to only modify matchNumber on relevant matches in round 1
          emptyMatchesToCreate = emptyMatchesToCreate - 1;
        }
      });
  }

  // Create placeholder matches for the rest of the tournament
  // We start with the final and then work our way backwards
  let numberOfMatchesInRound = 1;
  while (numberOfRounds > roundNumber) {
    matchNumber = 1;
    while(numberOfMatchesInRound >= matchNumber) {
      let match = {
        round: numberOfRounds,
        isFinal: event.structure === "Swiss with Single-elim finals",
        player1: null,
        player2: null,
        matchNumber: matchNumber,
        nextMatchNumber: Math.ceil(matchNumber/2),
        matchSaved: false
      }
      matchNumber++;
      matches.push(match);
    }
    numberOfMatchesInRound = numberOfMatchesInRound*2;
    numberOfRounds--;
  }
  matches.sort(sortByMatchNumberReverse);
  dispatch(setMatches(matches, event.id));
}

export const endSingleEliminationTournament = (event) => dispatch => {
  event.stage = "Finished";

  // Calculate points for League
  dispatch(calculateLeaguePoints(event));
  dispatch(calculatePlayerStats(event));
  if (process.env.NODE_ENV !== 'development') {
    firebase.analytics().logEvent('event_finished', {
      event_id: event.id,
      type: event.type,
      number_of_players: event.players.length,
      structure: event.structure
    });
  }
  event.logs.push = {action: "Event was finished", time: new Date()}

  dispatch(updateEvent(event));
}

export const pairRoundOne = (currentEvent, pods) => dispatch => {
  let matches = [];
  let player1;
  let player2;

  if (currentEvent.allowCrossPodPairings) {
    let mergedPods = [].concat.apply([], pods);
    pods = [mergedPods];
  }

  // Get number of pods for this event
  let numberOfPods = pods.length;
  // Run the matches generator for each pod, but run them seperately to only pair players within each pod
  for (let k = 0; k < numberOfPods; k++) {
    // Determine how many matches is in this pod
    let numberOfMatches = pods[k].length / 2;
    // Run the pairing randomizer as many times as we need matches
    for (let j = 0; j < numberOfMatches; j++) {
      // Create an empty match object
      let match = {
        pod: k+1,
        round: currentEvent.stageNumber-2
      }
      // Get two random players from the pod and add to the match object
      // For draft we need to get the player farthest away at the table
      if (currentEvent.type === "Draft") {
        ConsoleHelper("This is a draft, pairing opposite players");
        if(j + 1 > numberOfMatches && !Number.isInteger(numberOfMatches)) {
          // This is the last match to be paired and we need a Bye
          ConsoleHelper("This is a bye match");
          player1 = pods[k][pods[k].length - 1];
          player2 = {
            name: "Bye",
            matchScore: 0,
            uid: "Bye"
          }
        } else {
          player1 = pods[k][j];
          player2 = pods[k][j + Math.floor(numberOfMatches)];
          if(player2 === undefined) {
            player2 = {
              name: "Bye",
              matchScore: 0,
              uid: "Bye"
            }
          }
        }

        match.player1 = player1;
        match.player2 = player2;
        match.player1.matchScore = 0;
        match.player2.matchScore = 0;

      } else {
        for (let i = 0; i < 2; i++) {
          let randomNumber = Math.floor(Math.random() * pods[k].length);
          let randomPlayer = pods[k][randomNumber];
          // If we don't have an even number of players, add a bye
          if(randomPlayer === undefined) {
            randomPlayer = {
              name: "Bye",
              matchScore: 0,
              uid: "Bye"
            }
          }
          let num = i+1;
          randomPlayer.matchScore = 0;
          match["player"+num] = randomPlayer;
          pods[k].splice(randomNumber, 1);
        }
      }
      // Push the match into the matches array
      matches.push(match);
    }
  }
  // End of match pairing
  dispatch(setMatches(matches, currentEvent.id));
  const tagManagerArgs = {
    dataLayer: {
      genericEvent: true,
      event: "Round paired",
      properties: {
        roundNumber: 1
      },
      eventCategory: "Event"
    }
  }
  TagManager.dataLayer(tagManagerArgs);
}

export const pairNextRound = (event, pods, roundNumber) => dispatch => {
  if (event.allowCrossPodPairings) {
    let mergedPods = [].concat.apply([], pods);
    pods = [mergedPods];
  }

  let newMatches = [];
  // Create matches for all pods
  for (let i = 0; i < pods.length; i++) {
    // Temporarily remove any player that has dropped
    let droppedPlayers = [];
    for (let k = 0; k < pods[i].length; k++) {
      if (pods[i][k].dropped === true) {
        droppedPlayers.push(pods[i][k]);
        pods[i].splice(k, 1);
      }
    }
    ConsoleHelper("Dropped players:")
    ConsoleHelper(droppedPlayers);
    // Determine how many matches we need
    let numberOfMatches = pods[i].length / 2;
    // Take the first and second, etc, and create a match
    // TODO - Really, all rounds EXCEPT THE LAST should match players against another random player with same score. Last round should match according to standings. See https://www.reddit.com/r/magicTCG/comments/34kk0p/request_how_does_the_swiss_pairing_system_work/
    let trySuccess = true;
    let tryNumber = 0;
    let allowPreviousPairings = false;
    // Make an array to store matches for this pod and this attempt
    let podMatches = [];
    // Try to match until the pairings work out
    do {
      if(allowPreviousPairings) {
        ConsoleHelper("We're now allowing previous pairings");
      }

      // Make a copy of the pod to be able to remove players without destroying the original pod
      let podCopy = JSON.parse(JSON.stringify(pods[i]));
      // Reset list of matches to avoid duplicates
      podMatches = [];
      for ( let m = 0; m < podCopy.length; m++) {
        if (podCopy[m].dropped === true) {
          podCopy.splice(m, 1);
        }
      }
      // If we just can't find a solution, exit so we dont break anything
      let numOfPlayers = podCopy.length;
      let numOfTries = numOfPlayers * (numOfPlayers-1)/2-1;
      ConsoleHelper("This is attempt number " + (tryNumber + 1) + " to pair matches out of total of " + (numOfTries + 1));

      for (let j = 0; j < numberOfMatches; j++) {
        // This is jsut to make it stop if it tries to match when we dont have any players left
        if(podCopy.length === 0) {
          break;
        }
        // If we can't match on first try, match the first player against the third opponent to see if that works
        // TODO - Can we try something else? This can result in player 1 playing against someone much easier
        let opponent = 1;
        if(j === 0) {
          opponent = 1 + tryNumber;
        }
        ConsoleHelper("Opponent number is " + opponent);
        ConsoleHelper("Number of players left in pod: " + podCopy.length);
        // If there's only two players left and they've played each other, this attempt failed. Try again!
        if (podCopy.length === 2 && podCopy[0].played.includes(podCopy[opponent].uid) && !allowPreviousPairings) {
          ConsoleHelper(podCopy[0].name + " has already played " + podCopy[opponent].name);
          ConsoleHelper("This iteration of pairings failed - trying again");
          tryNumber++;
          trySuccess = false;
          if(tryNumber > numOfTries) {
            ConsoleHelper("We have tried " + tryNumber + " times, and was only going to try " + numOfTries + " times.");
            ConsoleHelper("No solution found");
            allowPreviousPairings = true;
            tryNumber = 0;
          }
          break;
        } else {
          trySuccess = true;
        }
        // As long as there are more than one player, check if player1 has already played player 2
        // If they have, instead match player1 against player3 and see if they've played
        if (!allowPreviousPairings) {
          if (podCopy.length > 1) {
            ConsoleHelper(podCopy[opponent]);
            while(podCopy[0].played.includes(podCopy[opponent].uid)) {
              ConsoleHelper(podCopy[0].name + " has already played " + podCopy[opponent].name);
              opponent++;
              if(opponent === podCopy.length) {
                ConsoleHelper("No solution found")
                allowPreviousPairings = true;
                tryNumber = 0;
                trySuccess = false;
                break;
              }
            }
          }
        }
        if(trySuccess) {
          let match = {
            pod: i+1,
            player1: podCopy[0],
            player2: podCopy[opponent],
            round: roundNumber
          }
          if(match.player1 === undefined) {
            match.player1 = {
              name: "Bye",
              matchScore: 0,
              uid: "Bye"
            }
          } else if(match.player2 === undefined) {
            match.player2 = {
              name: "Bye",
              matchScore: 0,
              uid: "Bye"
            }
          }
          match.player1.matchScore = 0;
          match.player2.matchScore = 0;
          // Once we have a match, put it in the Array
          // Also delete the two players from the array so they can't be matched again in this try
          podMatches.push(match);
          podCopy.splice(0, 1);
          podCopy.splice(opponent - 1, 1);
        } else {
          break;
        }
      }
      if (newMatches.length > numberOfMatches) {
        ConsoleHelper("We have " + newMatches.length + " new matches. That's more than the required matches: " + numberOfMatches)
        trySuccess = false;
      }
      ConsoleHelper(trySuccess);
    } while (trySuccess === false);
    // We have succesfully paired everyone! Now push the matches
    Array.prototype.push.apply(newMatches, podMatches);

    // Put the dropped players back into their pods
    pods[i].push(...droppedPlayers);
    ConsoleHelper("Pods including dropped players:");
    ConsoleHelper(pods[i]);
  }

  ConsoleHelper("This is the result:")
  ConsoleHelper(pods);
  ConsoleHelper(newMatches);
  dispatch(setMatches(newMatches, event.id))
  const tagManagerArgs = {
    dataLayer: {
      genericEvent: true,
      event: "Round paired",
      properties: {
        roundNumber: roundNumber
      },
      eventCategory: "Event"
    }
  }
  TagManager.dataLayer(tagManagerArgs);
}

export const setMatches = (matches, eventId) => dispatch => {

  let batch = firebase.firestore().batch();

  for (let i = 0; i < matches.length; i++) {
    let ref = firebase.firestore().collection("events").doc(eventId).collection("matches").doc();
    matches[i].id = ref.id;
    batch.set(ref, {
      ...matches[i]
    })
  }

  batch.commit().then(function (res) {
  });
}

export const changeMatchScore = (score, matchId, player) => dispatch => {
  dispatch(setMatchScore(score, matchId, player));
}

export const togglePlayerDropped = (dropped, matchId, player) => dispatch => {
  dispatch(setPlayerDropped(dropped, matchId, player));
}

export const saveSingleMatchScore = (match, event) => dispatch => {

  if (event.structure === "Swiss with Single-elim finals" && match.isFinal) {
    ConsoleHelper("We should add finals points to winner...!")
    addFinalsPointToWinner(match, event, dispatch);
  }

  firebase.firestore().collection("events").doc(event.id).collection("matches").doc(match.id).update({
    "player1.matchScore": match.player1.matchScore,
    "player2.matchScore": match.player2.matchScore,
    matchSaved: true
  })
  .then(function() {
    ConsoleHelper("Match updated!");
  })
  .catch(function(error) {
    console.error("Error writing document: ", error);
    dispatch(errorSetMatches(error));
  });
}

function addFinalsPointToWinner(match, event, dispatch) {

  ConsoleHelper("Adding finals points to winner");
  // Find the winner of the current match
  let winner;
  if(match.player1.matchScore > match.player2.matchScore) {
    // Player 1 is the winner
    winner = match.player1;
  } else if(match.player1.matchScore < match.player2.matchScore) {
    // Player 2 is the winner
    winner = match.player2;
  } else {
    // This is a draw...
    ConsoleHelper("This match was a draw");
    return;
  }

  ConsoleHelper("The winner was: " + winner.name);

  let playerToFind = event.players.findIndex((player => player.uid === winner.uid));
  event.players[playerToFind].finalsPoints++;

  ConsoleHelper("Their new finals points score is: " + event.players[playerToFind].finalsPoints);

  dispatch(updateEvent(event))
}

export const saveMatchScore = (matches, eventId) => dispatch => {

  let batch = firebase.firestore().batch();

  for (let i = 0; i < matches.length; i++) {
    let ref = firebase.firestore().collection("events").doc(eventId).collection("matches").doc(matches[i].id);
    batch.update(ref, {
      ...matches[i]
    })
  }

  batch.commit().then(function (res) {
  });

}

export const setNextMatch = (currentMatch, matches, event) => dispatch => {
  // Find the match in the next round to place this winner in
  let nextMatch = matches.find(matchToFind => (matchToFind.round === (currentMatch.round + 1) && matchToFind.matchNumber === currentMatch.nextMatchNumber));
  if(!nextMatch) {
    return;
  }
  // Find the winner of the current match
  let winner;
  if(currentMatch.player1.matchScore > currentMatch.player2.matchScore) {
    // Player 1 is the winner
    winner = currentMatch.player1;
  } else if(currentMatch.player1.matchScore < currentMatch.player2.matchScore) {
    // Player 2 is the winner
    winner = currentMatch.player2;
  } else {
    // This is a draw...
    return;
  }
  // Find the null player in the next game
  if(!nextMatch.player1) {
    nextMatch.player1 = winner;
    nextMatch.player1.matchScore = 0;
  } else if(!nextMatch.player2) {
    nextMatch.player2 = winner;
    nextMatch.player2.matchScore = 0;
  } else {
    return;
  }
  // Save nextMatch
  firebase.firestore().collection("events").doc(event.id).collection("matches").doc(nextMatch.id).update(nextMatch)
  .then(function() {
    ConsoleHelper("Match updated!");
  })
  .catch(function(error) {
    console.error("Error writing document: ", error);
    dispatch(errorSetMatches(error));
  });
}

export const saveAndCalculatePoints = (event, round, matches, allMatches, lastRound, update) => dispatch => {
/*  if (allowPreviousPairings) {
    ConsoleHelper("No solution was found - we're now allowing previous pairings")
  } */

  dispatch(saveMatchScore(matches, event.id));

  // Sorting function
  // Sorting first on score and then on OMW% and then on GW%
  function compare(a,b) {
    if (a.score < b.score)
      return 1;
    if (a.score > b.score)
      return -1;
    if (a.opponentMatchWinPercentage < b.opponentMatchWinPercentage)
      return 1;
    if (a.opponentMatchWinPercentage > b.opponentMatchWinPercentage)
      return -1;
    if (a.gameWinPercentage < b.gameWinPercentage)
      return 1;
    if (a.gameWinPercentage > b.gameWinPercentage)
      return -1;
    return 0;
  }

  // First reset players stats
  function resetPlayer(player) {
    player.wins = 0;
    player.draws = 0;
    player.losses = 0;
    player.score = 0;
    player.gameScore = 0;
    player.gamesWon = 0;
    player.gamesLost = 0;
    player.matchWinPercentage = 0;
    player.gameWinPercentage = 0;
    player.opponentMatchWinPercentage = 0;
    player.opponentGameWinPercentage = 0;
    player.played = [];
    player.dropped = false;
  }

  event.players.forEach(resetPlayer);

  // Hand out points depending on scores
  // TODO Also set the score in the match object?
  // TODO Fix pairings with Byes
    // One person should never get more than one Bye
    // One identical match should not occur
  for (let i = 0; i < allMatches.length; i++) {
    let match = allMatches[i];
    ConsoleHelper("Now setting results for match with ID: " + match.id);
    let player1 = event.players.findIndex((player => player.uid === match.player1.uid));
    let player2 = event.players.findIndex((player => player.uid === match.player2.uid));
    ConsoleHelper(event.players);
    ConsoleHelper(match);

    // Set the dropped boolean on players that are marked as dropped in the match
    if(match.player1.dropped) {
      event.players[player1].dropped = true;
    }

    if(match.player2.dropped) {
      event.players[player2].dropped = true;
    }

    // If match is a bye
    if(match.player2.uid === "Bye" || match.player1.uid === "Bye") {

      let nonByePlayer = "player1";
      if (match.player1.uid === "Bye") {
        nonByePlayer = "player2";
      }

      // PLAYER 1 WON
      // Find the winning player
      // We're subtracting one to get the index from pod number
      let winner = event.players.findIndex((player => player.uid === match[nonByePlayer].uid));

      // Add one win
      event.players[winner].wins++;
      // And 3 points
      event.players[winner].score = event.players[winner].score + 3;

      // Add both players to each others played array, to exclude in the future
      event.players[winner].played.push("Bye");

      // Calculate match win percentage
      event.players[winner].matchWinPercentage = event.players[winner].score / ((event.players[winner].wins + event.players[winner].losses + event.players[winner].draws) * 3);
      if(event.players[winner].matchWinPercentage < 0.33) {
        event.players[winner].matchWinPercentage = 0.33;
      }
      // Calculate game win percentage for bye
      event.players[winner].gamesWon += 2;
      event.players[winner].gameScore += 2 * 3;
      event.players[winner].gameWinPercentage = event.players[winner].gameScore / ((event.players[winner].gamesWon + event.players[winner].gamesLost) * 3);


    } else {
      // Add both players to each others played array, to exclude in the future
      event.players[player1].played.push(event.players[player2].uid);
      event.players[player2].played.push(event.players[player1].uid);
      // Not a bye
      if(match.player1.matchScore > match.player2.matchScore) {
        // PLAYER 1 WON
        // Find the winning player
        // We're subtracting one to get the index from pod number
        let winner = event.players.findIndex((player => player.uid === match.player1.uid));
        let looser = event.players.findIndex((player => player.uid === match.player2.uid));
        // Add one win
        event.players[winner].wins++;
        // And one loss
        event.players[looser].losses++;
        // And 3 points
        event.players[winner].score = event.players[winner].score + 3;

      } else if (match.player1.matchScore < match.player2.matchScore) {
        // PLAYER 2 WON
        // Find the winning player
        let winner = event.players.findIndex((player => player.uid === match.player2.uid));
        let looser = event.players.findIndex((player => player.uid === match.player1.uid));
        // Add one win
        event.players[winner].wins++;
        // And one loss
        event.players[looser].losses++;
        // And 3 points
        event.players[winner].score = event.players[winner].score + 3;
      } else {
        // DRAW
        // Add one daw
        event.players[player1].draws++;
        event.players[player2].draws++;
        // And 1 points
        event.players[player1].score++;
        event.players[player2].score++;
      }
      // Add game scores and records
      // PLAYER 1
      event.players[player1].gamesWon += match.player1.matchScore;
      event.players[player1].gamesLost += match.player2.matchScore;
      event.players[player1].gameScore += match.player1.matchScore * 3;

      event.players[player2].gamesWon += match.player2.matchScore;
      event.players[player2].gamesLost += match.player1.matchScore;
      event.players[player2].gameScore += match.player2.matchScore * 3;

      // Calculate win percentages
      // MATCH WIN PERCENTAGE: score / (rounds * 3) or 0.33
      event.players[player1].matchWinPercentage = event.players[player1].score / ((event.players[player1].wins + event.players[player1].losses + event.players[player1].draws) * 3);
      event.players[player2].matchWinPercentage = event.players[player2].score / ((event.players[player2].wins + event.players[player2].losses + event.players[player2].draws) * 3);

      // Calculate game win percentages
      // GAME WIN PERCENTAGE: game points / (games * 3)
      event.players[player1].gameWinPercentage = event.players[player1].gameScore / ((event.players[player1].gamesWon + event.players[player1].gamesLost) * 3);
      event.players[player2].gameWinPercentage = event.players[player2].gameScore / ((event.players[player2].gamesWon + event.players[player2].gamesLost) * 3);

      // NEW: Add result for this round to each player
      /* event.players[player1].rounds.push({
        opponent: event.players[player2].uid,
        gamesWon: event.players[player1].matchScore,
        matchesLost:
      }) */
    }
  }

  // Test to calculate opp win percentage
  // Loop all players
  for(let i = 0; i < event.players.length; i++) {
    let opponentMatchWins = [];
    let opponentGameWins = [];
    // Loop each players played opponents
    for(let k = 0; k < event.players[i].played.length; k++) {
      // Get opponent from players array of IDs of played opponents unless it's a Bye
      if(event.players[i].played[k] !== "Bye") {
        let opponent = event.players.findIndex((opponent => opponent.uid === event.players[i].played[k]));
        // Push each opponents win percentage to our array
        opponentMatchWins.push(event.players[opponent].matchWinPercentage);
        opponentGameWins.push(event.players[opponent].gameWinPercentage);
      }
    }
    // Function to get average from array
    const arrAvg = arr => arr.reduce((a,b) => a + b, 0) / arr.length
    // Calculate average opponent win percantage and push to player stats
    let opponentMatchWinPercentage = arrAvg(opponentMatchWins);
    let opponentGameWinPercentage = arrAvg(opponentGameWins);
    // Opponent game/match win percentage can't be lower than 33%
    if(opponentMatchWinPercentage < 0.33) {
      opponentMatchWinPercentage = 0.33;
    }
    if(opponentGameWinPercentage < 0.33) {
      opponentGameWinPercentage = 0.33;
    }
    event.players[i].opponentMatchWinPercentage = opponentMatchWinPercentage;
    event.players[i].opponentGameWinPercentage = opponentGameWinPercentage;
  }

  // Now sort players by score
  event.players.sort(compare);
  if(!update) {
    event.stageNumber = event.stageNumber+1;
  }
  ConsoleHelper(lastRound);
  if(lastRound && event.structure !== "Swiss with Single-elim finals") {
    event.stage = "Finished";
    // Calculate points for League
    dispatch(calculateLeaguePoints(event));
    dispatch(calculatePlayerStats(event));

    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "Event finished",
        properties: {
          eventType: event.type,
          eventStructure: event.structure,
          eventTitle: event.title,
          numberOfPlayers: event.players.length
        },
        eventCategory: "Event"
      }
    }
    TagManager.dataLayer(tagManagerArgs);

  }
  if(lastRound && event.structure === "Swiss with Single-elim finals") {
    event.stage = "Swiss finished";
  }
  dispatch(updateEvent(event));
  dispatch(setCurrentPods(event));
}

export const calculateLeaguePoints = (event) => async dispatch => {
  if (!event.league) {
    ConsoleHelper("This event was not part of a league");
    return;
  }
  try {
    await fetch('https://us-central1-mtg-event.cloudfunctions.net/calculateLeaguePoints', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        event
      }),
    });

  }
  catch(e) {
    ConsoleHelper(e)
  }
}

export const calculatePlayerStats = (event) => async dispatch => {
  try {
    await fetch('https://us-central1-mtg-event.cloudfunctions.net/calculatePlayerStats', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        event
      }),
    });

  }
  catch(e) {
    ConsoleHelper(e)
  }
}

export const updateLeague = (updatedLeague) => dispatch => {
  firebase.firestore().collection("leagues").doc(updatedLeague.id).set({
    ...updatedLeague
  }, {merge: true}).then(function() {
    ConsoleHelper("League updated")
  })
}
