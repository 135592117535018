import firebase from "../firebase.js";
import ConsoleHelper from "../ConsoleHelper.js"
import TagManager from 'react-gtm-module';
const requestFetchMyEvents = () => {
  return {
    type: "FETCH_MY_EVENTS_REQUEST"
  };
};

const recieveFetchMyEvents = (events) => {
  return {
    type: "FETCH_MY_EVENTS_SUCCESS",
    events
  };
};

const errorFetchingMyEvents = (error) => {
  return {
    type: "FETCH_MY_EVENTS_FAILIURE",
    error
  };
};

const requestFetchMyLeagues = () => {
  return {
    type: "FETCH_MY_LEAGUES_REQUEST"
  };
};

const recieveFetchMyLeagues = (leagues) => {
  return {
    type: "FETCH_MY_LEAGUES_SUCCESS",
    leagues
  };
};

const errorFetchingMyLeagues = (error) => {
  return {
    type: "FETCH_MY_LEAGUES_FAILIURE",
    error
  };
};

const requestFetchMyAdminLeagues = () => {
  return {
    type: "FETCH_MY_ADMIN_LEAGUES_REQUEST"
  };
};

export const recieveFetchMyAdminLeagues = (leagues) => {
  return {
    type: "FETCH_MY_ADMIN_LEAGUES_SUCCESS",
    leagues
  };
};

const errorFetchingMyAdminLeagues = (error) => {
  return {
    type: "FETCH_MY_ADMIN_LEAGUES_FAILIURE",
    error
  };
};

const setCreditInfoBackdrop = () => {
  return {
    type: "TOGGLE_CREDITS_INFO_BACKDROP"
  }
}

export const toggleCreditsInfoBackdrop = () => (dispatch, getState) => {
  let open = getState().coreReducer.creditsInfoBackdropOpen;
  let eventName = "Credits backdrop opened";
  if (open) {
    eventName = "Credits backdrop closed";
  }

  const tagManagerArgs = {
    dataLayer: {
      genericEvent: true,
      event: eventName,
      eventCategory: "Purchase"
    }
  }
  TagManager.dataLayer(tagManagerArgs);

  dispatch(setCreditInfoBackdrop());

}

export const togglePurchaseBackdrop = () => {
  return {
    type: "TOGGLE_PURCHASE_BACKDROP"
  }
}

export const setClientSecret = (clientSecret, paymentIntentId) => {
  return {
    type: "SET_CLIENT_SECRET",
    clientSecret,
    paymentIntentId
  }
}

export const fetchClientSecret = (volume) => dispatch => {
  fetch("https://us-central1-mtg-event.cloudfunctions.net/createPaymentIntent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      product: "credits",
      volume: volume
    })
  })
  .then(res => {
    return res.json();
  })
  .then(data => {
    dispatch(setClientSecret(data.clientSecret, data.paymentIntentId))
  });
}

export const fetchMyEvents = (uid) => dispatch => {
  dispatch(requestFetchMyEvents());
  firebase.firestore().collection("events").where("playerIds", "array-contains", uid)
    .get()
    .then(function(querySnapshot) {
      var events = [];
      querySnapshot.forEach(function(doc) {
        events.push({id: doc.id, ...doc.data()});
      });
      // Also fetch the events we are the admin of
      dispatch(fetchMyAdminEvents(uid, events));
    })
    .catch(function(error) {
        ConsoleHelper("Error getting documents: ", error);
        dispatch(errorFetchingMyEvents(error));
    });
};

export const fetchMyAdminEvents = (uid, events) => dispatch => {
  let recievedEvents = events;
  firebase.firestore().collection("events").where("owner", "==", uid)
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        recievedEvents.push({id: doc.id, ...doc.data()});
      });
      // Remove duplicate events
      const seen = new Set();
      recievedEvents.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
      const uniqueEvents = recievedEvents.filter(el => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });

      dispatch(recieveFetchMyEvents(uniqueEvents));
    })
    .catch(function(error) {
        ConsoleHelper("Error getting documents: ", error);
        dispatch(errorFetchingMyEvents(error));
    });
};

export const fetchMyLeagues = (uid) => dispatch => {
  dispatch(requestFetchMyLeagues());
  firebase.firestore().collection("leagues").where("playerIds", "array-contains", uid)
    .get()
    .then(function(querySnapshot) {
      var leagues = [];
      querySnapshot.forEach(function(doc) {
        leagues.push({id: doc.id, ...doc.data()});
      });
      dispatch(recieveFetchMyLeagues(leagues));
    })
    .catch(function(error) {
        ConsoleHelper("Error getting documents: ", error);
        dispatch(errorFetchingMyLeagues(error));
    });
};

export const fetchMyAdminLeagues = (uid) => dispatch => {
  dispatch(requestFetchMyAdminLeagues());
  firebase.firestore().collection("leagues").where("owner", "==", uid)
    .get()
    .then(function(querySnapshot) {
      var leagues = [];
      querySnapshot.forEach(function(doc) {
        leagues.push({id: doc.id, ...doc.data()});
      });
      dispatch(recieveFetchMyAdminLeagues(leagues));
    })
    .catch(function(error) {
        ConsoleHelper("Error getting documents: ", error);
        dispatch(errorFetchingMyAdminLeagues(error));
    });
};
