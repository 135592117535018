import React from 'react';
import { Link } from "react-router-dom";
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

export default function HeaderProfileMenu(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={openMenu}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        elevation={1}
      >
        <MenuItem><Link to={"/user/settings"}>Account settings</Link></MenuItem>
        <MenuItem onClick={props.handleSignout}>Sign out</MenuItem>
      </Menu>
    </>
  )
};
