import React, { Component } from 'react';
import { connect } from "react-redux";
import { Box, Typography, Divider, Container } from '@material-ui/core';

class Footer extends Component {

  render() {

    return (
      <Box textAlign="center">
        <Divider />
        <Container>
          <Box>
            <Typography variant="caption">Contact: hello@mtgevent.com</Typography>
          </Box>
          <Box>
            <Typography variant="caption">MTGevent.com is not affiliated with Wizards of the Coast or its parent company Hasbro Inc in any way. Any assets related to Magic: The Gathering including any images, text and symbols are copyrighted by Wizards of the Coast.</Typography>
          </Box>
        </Container>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
