import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Box, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ButtonGroup, Snackbar, Backdrop } from '@material-ui/core';
import { ArrowForward, Event, Room, Category, FormatListNumbered, AccountTree } from '@material-ui/icons';
import Moment from 'react-moment';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { withStyles } from '@material-ui/core/styles';

import AddPlayers from '../../Components/AddPlayers';
import PlayerListMenu from '../../Components/PlayerListMenu';
import CreditsInfoBackdrop from "../../Components/CreditsInfoBackdrop";

import { removePlayer, deleteEvent, updatePlayers, requestToJoin, joinEvent, createNotification } from '../../Actions/eventActions';
import { updateUserInMailerlite } from "../../Actions/authActions";
import { toggleCreditsInfoBackdrop } from "../../Actions/coreActions";

class EventInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      copyMessageOpen: false,
    };
  }

  render() {

    let isAdmin = this.props.currentUser.uid === this.props.currentEvent.owner;
    let player = this.props.currentEvent.players.find(player => player.uid === this.props.currentUser.uid);
    let playerStatus = "Not invited";
    if(player) {
      playerStatus = player.status;
    }
    let isPremium = false;
    if(this.props.currentUser.premium.status === "active") {
      isPremium = true;
    }
    let eventFull = false;
    if (!isPremium && this.props.currentEvent.players.length > 7) {
      eventFull = true;
    }

    const handleClickOpen = () => {
      this.setState({
        dialogOpen: true
      });
    };

    const handleClose = () => {
      this.setState({
        dialogOpen: false
      });
    };

    const handleCloseCopyMessage = () => {
      this.setState({
        copyMessageOpen: false
      });
    };

    const copyToClipboard = async (text) => {
      try {
        await navigator.clipboard.writeText(text)
        this.setState({
          copyMessageOpen: true
        })
      } catch (err) {
        console.error('Failed to copy!', err)
      }
    }

    let numberOfCredits = this.props.currentEvent.players.length - 8;
    let currentCredits = this.props.currentUser.premium.credits ? (this.props.currentUser.premium.credits) : (0);

    let creditsText = null;
    if (numberOfCredits > 0 && !isPremium) {
      creditsText = "(" + numberOfCredits + " credits)";
    }

    if(this.props.currentEvent.visibility === "hidden" && !isAdmin && playerStatus === "Not invited") {
      return (
        <Box>
          <Paper>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant="h2">You don't have access</Typography>
              </Box>
              <Typography>Sorry, this is a private event. Only invited players can join this event.</Typography>
              <Typography>If you know the event admin, ask them to invite you.</Typography>
            </Box>
          </Paper>
        </Box>
      )
    }


    return (
      <Box>
        <Paper>
          <Box p={2}>
            <Box my={2}>
              <Typography variant="h1">{this.props.currentEvent.title}</Typography>
            </Box>
            <Box display="flex">
              <Box mr={1} mb={1}>
                <Room />
              </Box>
              <Typography>{this.props.currentEvent.location}</Typography>
            </Box>
            <Box display="flex">
              <Box mr={1} mb={1}>
                <Event />
              </Box>
              <Moment format="DD MMMM LT">{this.props.currentEvent.date.toDate()}</Moment>
            </Box>
            <Box display="flex">
              <Box mr={1} mb={1}>
                <Category />
              </Box>
              <Typography>{this.props.currentEvent.type}</Typography>
            </Box>
            <Box display="flex">
              <Box mr={1} mb={1}>
                <AccountTree />
              </Box>
              <Typography>{this.props.currentEvent.structure || "Swiss"}</Typography>
            </Box>
            <Box display="flex">
              <Box mr={1} mb={1}>
                <FormatListNumbered />
              </Box>
              <Typography variant="body1">{this.props.currentEvent.league ? (
                <Link to={"/leagues/" + this.props.currentEvent.league.id}>{this.props.currentEvent.league.title}</Link>
              ) : (
                "This event is not part of a league"
              )}
              </Typography>
            </Box>
            <Typography variant="body1">{this.props.currentEvent.description}</Typography>
            {this.props.currentEvent.visibility !== "hidden" &&
              <Box mt={2}>
                <Typography variant="h3">Share event</Typography>
                <Box mt={1}>
                  <ButtonGroup color="primary" size="small" aria-label="contained primary button group">
                    {navigator.clipboard &&
                      <Button onClick={() => copyToClipboard(window.location.href)}>Copy link</Button>
                    }
                    <Button component="span">
                      <FacebookShareButton url={window.location.href}>FACEBOOK</FacebookShareButton>
                    </Button>
                    <Button component="span">
                      <TwitterShareButton url={window.location.href}>TWITTER</TwitterShareButton>
                    </Button>
                    <Button component="span">
                      <WhatsappShareButton url={window.location.href}>WHATSAPP</WhatsappShareButton>
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            }
          </Box>
        </Paper>
        {(this.props.currentEvent.stage === "Running" || this.props.currentEvent.stage === "Swiss finished") &&
          <Box my={2}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Event currently running</Typography>
                <Typography variant="body1">No changes to the players or settings can be made at this point.</Typography>
                <Box mt={2}>
                  {(this.props.currentEvent.structure === "Single-elimination" || this.props.currentEvent.structure === "Swiss with Single-elim finals") ? (
                    <Button endIcon={<ArrowForward />} component={Link} to={this.props.currentEvent.stageNumber === 2 ? this.props.match.url + "/pods" : this.props.match.url + "/single-elimination"} color="primary" variant="contained">Go to the tournament</Button>
                  ) : (
                    <Button endIcon={<ArrowForward />} component={Link} to={this.props.currentEvent.stageNumber === 2 ? this.props.match.url + "/pods" : this.props.match.url + "/round/" + (this.props.currentEvent.stageNumber - 2)} color="primary" variant="contained">Go to the current round</Button>
                  )}
                </Box>
              </Box>
            </Paper>
          </Box>
        }
        {this.props.currentEvent.stage === "Finished" &&
          <Box my={2}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">This event is finished</Typography>
                <Box mt={2}>
                  {this.props.currentEvent.structure === "Single-elimination" &&
                    <Button endIcon={<ArrowForward />} component={Link} to={this.props.match.url + "/single-elimination"} color="primary" variant="contained">Go to results</Button>
                  }
                  {this.props.currentEvent.structure === "Swiss with Single-elim finals" &&
                    <Button endIcon={<ArrowForward />} component={Link} to={this.props.match.url + "/final-results"} color="primary" variant="contained">Go to results</Button>
                  }
                  {this.props.currentEvent.structure === "Swiss" &&
                    <Button endIcon={<ArrowForward />} component={Link} to={this.props.match.url + "/results"} color="primary" variant="contained">Go to results</Button>
                  }
                </Box>
              </Box>
            </Paper>
          </Box>
        }
        {(playerStatus === "Invite pending" && this.props.currentEvent.stage === "Planned") &&
          <Box my={2}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">You've been invited to join this event</Typography>
                <Box mt={2}>
                  <Button color="primary" variant="contained" onClick={() => this.props.acceptInvite(this.props.currentEvent.players, this.props.currentUser, this.props.currentEvent.id)}>Join event</Button>
                  <Button color="secondary" variant="outlined" onClick={() => this.props.removePlayer(this.props.currentUser.uid, this.props.currentEvent)}>Decline invitation</Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        }
        {(playerStatus === "Not invited" && this.props.currentEvent.stage === "Planned") &&
          <Box my={2}>
            <Paper>
              <Box p={2}>
                {isAdmin || this.props.currentEvent.visibility === "open" ? (
                  <Button color="primary" variant="contained" onClick={() => this.props.joinEvent(this.props.currentUser, this.props.currentEvent)}>Join event</Button>
                ) : (
                  <Button color="primary" variant="contained" onClick={() => this.props.requestToJoin(this.props.currentUser, this.props.currentEvent)}>Request to join event</Button>
                )}
              </Box>
            </Paper>
          </Box>
        }
        {((playerStatus === "Join request pending" || playerStatus === "Attending") && this.props.currentEvent.stage === "Planned") &&
          <Box my={2}>
            <Paper>
              <Box p={2}>
                <Button onClick={() => this.props.removePlayer(this.props.currentUser.uid, this.props.currentEvent)}>Leave event</Button>
              </Box>
            </Paper>
          </Box>
        }
        <Box my={4}>
          <Paper>
            <Box p={2}>
              <Typography variant="h2">Players</Typography>
              {(isAdmin && !isPremium) &&
                <Typography variant="body1">{this.props.currentEvent.players.length} players - {(8-this.props.currentEvent.players.length) > 0 ? ((8-this.props.currentEvent.players.length)):("0")} free seats remaining</Typography>
              }
            </Box>
            <Divider />
            <List style={{maxHeight: '400px', overflow: 'auto'}}>
              {this.props.currentEvent.players.map((player) =>
                <ListItem key={player.name}>
                  <ListItemText primary={player.name} secondary={player.status} />
                  {(isAdmin && this.props.currentEvent.stage === "Planned") &&
                    <ListItemSecondaryAction>
                      <PlayerListMenu
                        removePlayer={this.props.removePlayer}
                        player={player}
                        currentEvent={this.props.currentEvent}
                        acceptJoinRequest={this.props.acceptJoinRequest}
                      />
                    </ListItemSecondaryAction>
                  }
                </ListItem>
              )}
            </List>
            <Divider />
            {this.props.currentEvent.stage === "Planned" && isAdmin &&
              <AddPlayers hasLeague={this.props.currentEvent.league} eventFull={eventFull} />
            }
          </Paper>
        </Box>
        {isAdmin &&
          <Box>
            {this.props.currentEvent.stage === "Planned" &&
              <Box mb={2}>
                {!isPremium && (numberOfCredits > currentCredits) ? (
                  <Button size="large" variant="contained" disabled={this.props.currentEvent.players.length < 2} color="primary" onClick={() => this.props.toggleCreditsInfoBackdrop()}>Start event {creditsText}</Button>
                ) : (
                  <Button size="large" component={Link} to={"/events/" + this.props.currentEvent.id + "/settings"} variant="contained" disabled={this.props.currentEvent.players.length < 2} color="primary">Start event {creditsText}</Button>
                )}
              </Box>
            }
            <Box display="flex">
              {this.props.currentEvent.stage === "Planned" &&
                <Box mr={2}>
                  <Button component={Link} to={"/events/" + this.props.currentEvent.id + "/edit"} color="default">Edit event</Button>
                </Box>
              }
              <Button color="secondary" onClick={handleClickOpen}>
                Delete event
              </Button>
            </Box>
          </Box>
        }
        <Dialog
          open={this.state.dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete event?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will delete the event and all results.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.props.deleteEvent(this.props.currentEvent.id, this.props.history)} color="primary" autoFocus>
              Yes, delete event
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.copyMessageOpen}
        autoHideDuration={3000}
        onClose={handleCloseCopyMessage}
        message="Link copied to clipboard"
      />
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removePlayer: (playerId, currentEvent) => {
      dispatch(removePlayer(playerId, currentEvent));
    },
    deleteEvent: (eventId, history) => {
      dispatch(deleteEvent(eventId));
      history.push("/");
    },
    acceptInvite: (players, user, eventId) => {
      let updatedPlayers = players;
      let playerToUpdate = updatedPlayers.find(player => player.uid === user.uid);
      playerToUpdate.status = "Attending";
      dispatch(updatePlayers(updatedPlayers, eventId));
      dispatch(updateUserInMailerlite({"email": user.email, "last_event_joined": new Date()}));
    },
    requestToJoin: (currentUser, currentEvent) => {
      dispatch(requestToJoin(currentUser, currentEvent));
    },
    acceptJoinRequest: (players, user, event) => {
      let updatedPlayers = players;
      let playerToUpdate = updatedPlayers.find(player => player.uid === user.uid);
      playerToUpdate.status = "Attending";
      dispatch(updatePlayers(updatedPlayers, event.id));
      dispatch(updateUserInMailerlite({"email": user.email, "last_event_joined": new Date()}));
      let notification = {
        title: "You've been added to an event",
        body: "Your request to join the event " + event.title + " has been approved!",
        read: false,
        created: new Date(),
        link: "/events/" + event.id
      }
      dispatch(createNotification(user.uid, notification));
    },
    joinEvent: (currentUser, currentEvent) => {
      dispatch(joinEvent(currentUser, currentEvent));
    },
    toggleCreditsInfoBackdrop: () => {
      dispatch(toggleCreditsInfoBackdrop());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);
