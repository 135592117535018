import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, InputLabel, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import { TextField as FormikTextField } from 'formik-material-ui';

import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import CardDetails from './CardDetails';

import countries from '../countries';

import { updateUser, stripePaymentMethodHandler, triggerCreditsPurchase } from '../Actions/authActions.js';

const divStyle = {
  backgroundColor: 'rgba(255,255,255,0.8)',
  borderRadius: 5
};

class CheckoutForm extends Component {

  handleSubmit = async (email, country) => {
      let billingDetails = {
        email: email,
        country: country
      };
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      // event.preventDefault();

      const {stripe, elements} = this.props

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }

      if (this.props.product === "credits") {
        // THIS IS A CREDITS PURCHASE

        let paymentMethod = {
          card: elements.getElement(CardElement)
        };
        this.props.triggerCreditsPurchase(this.props.clientSecret, this.props.paymentIntentId, stripe, elements, CardElement, this.props.volume, this.props.currentUser, billingDetails);
      } else {
        // THIS IS A PRO PURCHASE
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
            address: {
              country: country
            }
          },
        });

        this.props.stripePaymentMethodHandler(result, this.props.currentUser, stripe, billingDetails);
      }
    };



  render() {

    function countryToFlag(isoCode) {
      return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
    }

    const {stripe} = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{ email: this.props.currentUser.email, country: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Required')
            .email('Invalid email'),
          country: Yup.string()
            .required('Required')
        })}
        onSubmit={(values, actions) => {
          this.handleSubmit(values.email, values.country);
          actions.setSubmitting(false);
        }}
      >
        {({setFieldValue, values}) => (
          <Form>
            <Field style={divStyle} component={FormikTextField} margin="normal" fullWidth label="Email" variant="filled" type="email" name="email" />
            <Autocomplete
              style={divStyle}
              name="country"
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => {
                setFieldValue(
                  "country",
                  value !== null ? value.code : ''
                );
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <span>{countryToFlag(option.code)}</span>
                  {option.label}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Billing country"
                  variant="filled"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <CardDetails />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={!stripe || this.props.isPurchasing || (this.props.product === "credits" && !this.props.clientSecret)}>
              {this.props.isPurchasing ? "Purchasing..." : "Purchase now"}
            </Button>
          </Form>
        )}
      </Formik>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.authReducer.currentUser,
    isPurchasing: state.authReducer.isPurchasing,
    clientSecret: state.coreReducer.clientSecret,
    paymentIntentId: state.coreReducer.paymentIntentId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (values, uid) => {
      dispatch(updateUser(values, uid));
    },
    stripePaymentMethodHandler: (result, currentUser, stripe, billingDetails) => {
      dispatch(stripePaymentMethodHandler(result, currentUser, stripe, billingDetails))
    },
    triggerCreditsPurchase: (clientSecret, paymentIntentId, stripe, elements, CardElement, volume, currentUser, billingDetails) => {
      dispatch(triggerCreditsPurchase(clientSecret, paymentIntentId, stripe, elements, CardElement, volume, currentUser, billingDetails))
    }
  }
}

function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm
          stripePaymentMethodHandler={props.stripePaymentMethodHandler}
          triggerCreditsPurchase={props.triggerCreditsPurchase}
          currentUser={props.currentUser}
          isPurchasing={props.isPurchasing}
          stripe={stripe}
          elements={elements}
          product={props.product}
          volume={props.volume}
          amount={props.amount}
          clientSecret={props.clientSecret}
          paymentIntentId={props.paymentIntentId}
        />
      )}
    </ElementsConsumer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InjectedCheckoutForm);
