import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import lost from "../lost.png";

class NoSuchPage extends Component {

  render() {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="70vh" width={1}>
        <img alt="Totally lost" style={{maxWidth: 300}} src={lost} />
        <Box mb={3}>
          <Typography variant="h4">You look totally lost!</Typography>
          <Typography>We're terribly sorry, but this page doesn't seem to exist.</Typography>
          <Box mb={2}>
            <Button component={Link} to={"/"} variant="contained" color="primary" href="/">Take me back home</Button>
          </Box>
        </Box>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoSuchPage);
