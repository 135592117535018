import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";
import { Typography, Paper, Box, List, ListItem, ListItemText, ListItemIcon, ListSubheader, Divider, Button, Menu, MenuItem, IconButton, Checkbox } from '@material-ui/core';
import { ArrowForward, Add, Tune } from '@material-ui/icons';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import { fetchMyEvents } from "../Actions/coreActions";

var moment = require('moment');

class AllEvents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stages: ["Planned", "Running", "Finished"]
    };
  }

  toggleStage = (stage) => {
    if (this.state.stages.includes(stage)) {
      // Remove from array
      let stages = this.state.stages;
      const index = stages.indexOf(stage);
      if (index > -1) {
        stages.splice(index, 1);
      }
      this.setState({stages: stages});
    } else {
      // Add to array
      let stages = this.state.stages;
      stages.push(stage);
      this.setState({stages: stages});
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMyEvents(this.props.currentUser.uid));
  }

  render() {
    var events = this.props.myEvents;
    let that = this;
    function EventsList({events}) {
      if(events.length > 0) {
        let filteredEvents = events.filter(function (event) {
          return (
            that.state.stages.includes(event.stage)
          );
        });
        const eventsList = filteredEvents.map((event) => {
          let runningLink = event.stageNumber === 2 ? "/events/" + event.id + "/pods" : "/events/" + event.id + "/round/" + (event.stageNumber - 2);
          if (event.structure === "Single-elimination") {
            runningLink = "/events/" + event.id + "/single-elimination"
          }
          return (
            <ListItem
              button
              key={event.id}
              component={Link}
              to={event.stage !== "Running" ? "/events/" + event.id : runningLink}
            >
              <ListItemText
                primary={event.title}
                secondary={moment(event.date.toDate()).fromNow() + " | " + event.stage + " | " + event.location}
              />
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
            </ListItem>
          )
        });
        if (eventsList.length === 0) {
          return (
            <Box p={2}>
              <Typography>No events match your filters</Typography>
            </Box>
          );
        } else {
          return (
            <List>{eventsList}</List>
          );
        }
      } else {
        return (
          <Box p={2}>
            <Typography>You don't have any events</Typography>
          </Box>
        )
      }
    }

    return (
      <Box mb={4}>
        <Paper>
          <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">My events</Typography>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <IconButton aria-label="filter" {...bindTrigger(popupState)}>
                    <Tune />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <ListSubheader>Event stages</ListSubheader>
                    <MenuItem onClick={() => this.toggleStage("Planned")}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={this.state.stages.includes("Planned")}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                      <Typography>Planned</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => this.toggleStage("Running")}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={this.state.stages.includes("Running")}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <Typography>Running</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => this.toggleStage("Finished")}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={this.state.stages.includes("Finished")}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <Typography>Finished</Typography>
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Box>
          <Divider />
          {this.props.isFetchingMyEvents ? (
            <Box display="flex" justifyContent="center" alignItems="center" p={2} width={1}>
              <CircularProgress />
            </Box>
          ):(
            <EventsList events={events} />
          )}
          <Box p={2}>
            <Button startIcon={<Add />} component={Link} to={"/new-event"} variant="contained" color="primary" href="/new-event">Create event</Button>
          </Box>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    myEvents: state.coreReducer.myEvents,
    isFetchingMyEvents: state.coreReducer.isFetchingMyEvents
  };
}

export default connect(mapStateToProps)(AllEvents);
