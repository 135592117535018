import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

export default function PlayerListMenu(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const includedStatuses = ["Attending", "Invite pending"];

  return (
    <>
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={openMenu}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        elevation={1}
      >
        {props.player.status === "Join request pending" &&
          <>
            <MenuItem onClick={() => props.acceptJoinRequest(props.currentEvent.players, props.player, props.currentEvent)}>Approve join request</MenuItem>
            <MenuItem onClick={() => props.removePlayer(props.player.uid, props.currentEvent)}>Decline join request</MenuItem>
          </>
        }
        {includedStatuses.includes(props.player.status) &&
          <MenuItem onClick={() => props.removePlayer(props.player.uid, props.currentEvent)}>Remove player</MenuItem>
        }
      </Menu>
    </>
  )
};
