import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Container, CircularProgress } from '@material-ui/core';

import { fetchMyStats } from "../../Actions/statsActions";

class Stats extends Component {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMyStats(this.props.currentUser.uid));
  }

  render() {
    if(this.props.isFetchingStats) {
      return(
        <Container>
          <Typography variant="h4">My Stats</Typography>
          <CircularProgress />
        </Container>
      )
    }
    return(
      <Container>
        <Typography variant="h4">My Stats</Typography>
        <Typography variant="body1">Number of events played: {this.props.stats.numberOfEventsFinished}</Typography>
        <Typography variant="body1">Average place: {this.props.stats.averageFinish} out of {this.props.stats.averagePlayers}</Typography>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    isFetchingStats: state.statsReducer.isFetchingStats,
    stats: state.statsReducer.stats
  };
}


export default connect(mapStateToProps)(Stats);
