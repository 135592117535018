const initialState = {
  isFetchingStats: false,
  stats: []
};

export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_MY_STATS_REQUEST':
    return {
      ...state,
      isFetchingStats: true
    };
  case 'FETCH_MY_STATS_SUCCESS':
    return {
      ...state,
      isFetchingStats: false,
      stats: action.stats
    };
  default:
   return state
 }
}
