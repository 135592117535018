import React from 'react';
import { Paper, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#494E5C"
  }
}))

export default function CreditsProduct(props) {
  const classes = useStyles();

  let image;

  if (props.volume === 10) {
    image = '../images/1.png'
  } else if (props.volume === 20) {
    image = '../images/2.png'
  } else if (props.volume === 50) {
    image = '../images/3.png';
  }

  return (
    <Box mt={2} mx={1}>
      <Paper>
        <Box  py={2}>
          <Box>
            <Typography variant="h6" className={classes.title}>{props.volume} credits</Typography>
          </Box>
          <img alt="Credits" style={{maxWidth: 180}} src={image} />
          <Button onClick={props.onClick} variant="contained" size="large" color="primary">Buy {props.volume} credits, ${props.amount}</Button>
        </Box>
      </Paper>
    </Box>
  )
};
