import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography, Container, Paper, Box } from '@material-ui/core';
import NewEventForm from "../../Components/NewEventForm";
import {Helmet} from "react-helmet";
import ConsoleHelper from "../../ConsoleHelper.js"

import { createEvent } from "../../Actions/eventActions";
import { updateUserInMailerlite } from "../../Actions/authActions";

class NewEvent extends Component {

  render() {
    ConsoleHelper(this.props.location);
    return(
      <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>New event | MTG Event</title>
        </Helmet>
        <Box mb={2}>
          <Typography variant="h1">Create new event</Typography>
        </Box>
        <Paper>
          <Box p={3}>
            <NewEventForm
              onSubmit={(values) => this.props.createEvent(values, this.props.currentUser, this.props.history)}
              fromLeague={this.props.location.state && this.props.location.state.league}
            />
          </Box>
        </Paper>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createEvent: (values, user, history) => {
      if (values.league === "No league" || values.league === undefined || values.league === null) {
        values.league = null;
      } else {
        values.league = {title: values.league.title, id: values.league.id};
      }
      dispatch(updateUserInMailerlite({"email": user.email, "last_event_created_date": new Date(), "last_event_joined": new Date()}));
      dispatch(createEvent(values, user)).then(result => {
        history.push("/events/" + result);
      }).catch(error => {
        ConsoleHelper(error);
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEvent);
