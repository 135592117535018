import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Typography, CircularProgress, Box, Slider } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Alert from '@material-ui/lab/Alert';
import MomentUtils from '@date-io/moment';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from "react-router-dom";
import history from '../history';
import ConsoleHelper from "../ConsoleHelper.js"

import { fetchMyAdminLeagues } from "../Actions/coreActions";

import * as Yup from 'yup';

const FormikDatePicker = ({
  name,
  label,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // ConsoleHelper(rest);
  return (
      <DateTimePicker
        autoOk
        name={name}
        label={label}
        onChange={value => {
          setFieldValue("date", value._d);
        }}
        value={value}
        inputVariant="outlined"
        margin="normal"
        fullWidth
        animateYearScrolling={false}
        minDate={new Date()}
      />
  );
};

const TypeSelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <FormControl variant="outlined" margin="normal" disabled={rest.disabled} fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          ConsoleHelper(event.target.value)
          setFieldValue("type", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={95}
      >
        <MenuItem value="Constructed">Constructed</MenuItem>
        <MenuItem value="Draft">Draft</MenuItem>
        <MenuItem value="Sealed">Sealed</MenuItem>
      </Select>
    </FormControl>
  )
}

const StructureSelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <FormControl variant="outlined" margin="normal" disabled={rest.disabled} fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          ConsoleHelper(event.target.value)
          setFieldValue("structure", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={160}
      >
        <MenuItem value="Swiss">Swiss</MenuItem>
        <MenuItem value="Single-elimination">Single-elimination</MenuItem>
        <MenuItem value="Swiss with Single-elim finals">Swiss with single-elimination finals</MenuItem>
      </Select>
    </FormControl>
  )
}

const VisibilitySelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <FormControl variant="outlined" margin="normal" disabled={rest.disabled} fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          ConsoleHelper(event.target.value)
          setFieldValue("visibility", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={105}
      >
        <MenuItem value="request">Anyone with the link can request to join</MenuItem>
        <MenuItem value="open">Anyone with the link can join</MenuItem>
        <MenuItem value="hidden">Invite only</MenuItem>
      </Select>
    </FormControl>
  )
}

const LeagueSelect = ({
  name,
  label,
  options,
  form: { setFieldValue },
  field: { value },
  initialValue,
  isPremium,
  ...rest
}) => {
  let disabled = false;
  if (initialValue) {
    disabled = true;
  }
  if (!isPremium) {
    disabled = true;
  }
  if (rest.values.structure === "Single-elimination") {
    disabled = true;
  }
  if (initialValue === null) {
    value = '';
  }

  ConsoleHelper(value);
  return (
    <FormControl variant="outlined" margin="normal" disabled={disabled} fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        name={name}
        onChange={event => {
          setFieldValue("league", event.target.value);
        }}
        value={value}
        labelId="select-label"
        labelWidth={140}
        onOpen={rest.onOpen}
        >
        {(initialValue && rest.leagues.length === 0 ) &&
          <MenuItem value={initialValue}>{initialValue.title} ({initialValue.type})</MenuItem>
        }
        {(!rest.loading && rest.leagues.length > 0) &&
          <MenuItem value={"No league"}>Don't add to league</MenuItem>
        }
        {rest.leagues.map((league) =>
          <MenuItem value={league}>{league.title} ({league.type})</MenuItem>
        )}
        {(!rest.loading && rest.leagues.length === 0) &&
          <Typography>You don't have any elegible leagues</Typography>
        }
        {rest.loading &&
          <CircularProgress />
        }
      </Select>
    </FormControl>
  )
}

class NewEventForm extends Component {

  render() {

    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{
            title: '',
            location: '',
            date: new Date(),
            type: (this.props.fromLeague && this.props.fromLeague.type !== "Mixed") ? (this.props.fromLeague.type):('Constructed'),
            league: this.props.fromLeague || null,
            visibility: "request",
            structure: "Swiss"
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .min(2, 'Too Short!')
              .max(50, 'Too Long!')
              .required('Required'),
            location: Yup.string()
              .required('Required'),
          })}
          onSubmit={(values) => {
            this.props.onSubmit(values);
          }}
        >
          {({ isSubmitting, setFieldValue, values, initialValues }) => (
            <Form>
              <Field required component={TextField} margin="normal" fullWidth label="Title" variant="outlined" type="text" name="title" />
              <Field required component={TextField} margin="normal" fullWidth label="Event location" variant="outlined" type="text" name="location" />
              <Field component={FormikDatePicker} label="Event date" name="date" />
              <Field component={TypeSelect} label="Event format" name="type" disabled={(initialValues.league && initialValues.league.type !== "Mixed")} />
              <Field component={StructureSelect} label="Tournament structure" name="structure" />
              <Field component={TextField} multiline margin="normal" fullWidth label="Description" variant="outlined" type="text" name="description" />
              <Field component={VisibilitySelect} label="Who can join?" name="visibility" />
              <Field component={LeagueSelect} label="Add event to league" name="league" initialValue={initialValues.league}
                onOpen={event => {
                    if (this.props.myAdminLeagues.length < 1) {
                      this.props.fetchMyAdminLeagues(this.props.currentUser.uid);
                    }
                  }}
                leagues={this.props.myAdminLeagues.filter(league => (league.type === values.type) || (league.type === "Mixed"))}
                loading={this.props.isFetchingMyAdminLeagues}
                isPremium={isPremium}
                values={values}
              />
              {!isPremium &&
                <Alert
                  icon={<LockOpenIcon fontSize="inherit" />}
                  severity="info"
                  action={
                    <Button component={Link} to={"/pro"} color="inherit" size="medium" href="/pro">Learn more</Button>
                  }
                >
                  Get a Pro account to start using the Leagues feature
                </Alert>
              }
              <Box my={3} display="flex">
                <Box mr={1}>
                  <Button size="large" type="submit" variant="contained" color="primary">Create event</Button>
                </Box>
                <Button size="large" variant="outlined" color="secondary" onClick={() => history.goBack()}>Cancel</Button>
              </Box>
              {this.props.authError &&
                <Typography>{this.props.authError.message}</Typography>
              }
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    )
  }
}

function mapStateToProps(state) {
  // TODO: Make sure we only return leagues that isn't finished yet
  let myAdminLeagues = state.coreReducer.myAdminLeagues.filter(league => league.stage !== "Finished");

  return {
    currentUser: state.authReducer.currentUser,
    myAdminLeagues: myAdminLeagues,
    isFetchingMyAdminLeagues: state.coreReducer.isFetchingMyAdminLeagues
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMyAdminLeagues: (playerId) => {
      dispatch(fetchMyAdminLeagues(playerId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEventForm);
