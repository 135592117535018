import firebase from "../firebase.js"
import ConsoleHelper from "../ConsoleHelper.js"
import TagManager from 'react-gtm-module';

let functionsDomain;
let proProductId;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    ConsoleHelper("This is dev")
    functionsDomain = "http://localhost:5001/mtg-event/us-central1/";
    proProductId = "price_1I31MGIM2fJwOyZs9PHeOJOU";
} else {
    ConsoleHelper("This is production")
    functionsDomain = "https://us-central1-mtg-event.cloudfunctions.net/";
    proProductId = "price_1I1AYvIM2fJwOyZsdzfY43me";
}

const requestLogin = () => {
  return {
    type: "LOGIN_REQUEST"
  };
};

export const receiveLogin = (user) => {
  return {
    type: "LOGIN_SUCCESS",
    user
  };
};

const loginError = (error) => {
  return {
    type: "LOGIN_FAILURE",
    error
  };
};

const registerError = (error) => {
  return {
    type: "REGISTER_ERROR",
    error
  };
};

const verifyRequest = () => {
  return {
    type: "VERIFY_REQUEST"
  }
}

const verifySuccess = (authData) => {
  return {
    type: "VERIFY_SUCCESS",
    authData
  }
}

const requestLogout = () => {
  return {
    type: "REQUEST_LOGOUT"
  }
}

const recieveLogout = () => {
  return {
    type: "LOGOUT_SUCCESS"
  }
}

const logoutError = (error) => {
  return {
    type: "LOGOUT_FAILIURE",
    error
  }
}

const setCurrentNotifications = (notifications) => {
  return {
    type: "SET_CURRENT_NOTIFICATIONS",
    notifications
  }
}

export const setCurrentUserProfile = (user) => {
  return {
    type: "SET_CURRENT_USER_PROFILE",
    user
  }
}

export const requestPremiumPurchase = () => {
  return {
    type: "REQUEST_PREMIUM_PURCHASE",
  }
}

export const premiumPurchaseSuccessful = () => {
  return {
    type: "PREMIUM_PURCHASE_SUCCESSFUL"
  }
}

export const premiumPurchaseError = (error) => {
  return {
    type: "PREMIUM_PURCHASE_ERROR",
    purchaseError: error
  }
}

export const resetPasswordError = (error) => {
  return {
    type: "RESET_PASSWORD_ERROR",
    error
  }
}

const resetPasswordSuccess = (message) => {
  return {
    type: "RESET_PASSWORD_SUCCESS",
    message
  }
}

const setAuthData = (authData) => {
  return {
    type: "SET_AUTH_DATA",
    authData
  }
}

const linkAuthProviderError = (error) => {
  return {
    type: "LINK_AUTH_PROVIDER_ERROR",
    error
  }
}

const confirmLoginRequired = (state) => {
  return {
    type: "CONFIRM_LOGIN_REQUIRED",
    state
  }
}

const cancelSubscriptionRequest = (state) => {
  return {
    type: "CANCEL_SUBSCRIPTION_REQUEST",
    state
  }
}

const setCurrentFinishedEvents = (events) => {
  return {
    type: "SET_CURRENT_FINISHED_EVENTS",
    events
  }
}

export const setAuthBackdropOpen = (state) => {
  return {
    type: "TOGGLE_AUTH_BACKROP_OPEN",
    state
  }
}

const setReauthenticateError = (error) => {
  return {
    type: "SET_REAUTHENTICATE_ERROR",
    error
  }
}

export const setRedirectUrl = (url) => {
  return {
    type: "SET_REDIRECT_URL",
    url
  }
}

export const fetchSignInMethodsForEmail = (email) => async dispatch => {
  try {
    let providers = await firebase.auth().fetchSignInMethodsForEmail(email);
    dispatch(loginError("This account is connected with the following providers: " + providers.join(", ") + ". Please sign in with them and then connect other providers in User settings."));
  }
  catch(error) {
    ConsoleHelper(error);
  }
}

export const signInWithGoogle = () => dispatch => {
  dispatch(requestLogin());
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(provider).then(function(result) {
    var dbUser = {
      name: result.user.displayName,
      email: result.user.email,
      uid: result.user.uid,
      photoURL: null
    }
    dispatch(createUserInDatabase(dbUser, "Google"));
  }).catch(function(error) {
    switch(error.code) {
      case "auth/account-exists-with-different-credential":
        dispatch(fetchSignInMethodsForEmail(error.email));
        break;
      default:
        dispatch(loginError("An error occured, please contact hello@mtgevent.com"));
        ConsoleHelper(error);
      }
  });
};

export const signInWithFacebook = () => dispatch => {
  dispatch(requestLogin());
  var provider = new firebase.auth.FacebookAuthProvider();
  firebase.auth().signInWithPopup(provider).then(function(result) {
    var dbUser = {
      photoURL: result.user.photoURL,
      name: result.user.displayName,
      email: result.user.email,
      uid: result.user.uid
    }
    dispatch(createUserInDatabase(dbUser, "Facebook"));
  }).catch(function(error) {
    switch(error.code) {
      case "auth/account-exists-with-different-credential":
        dispatch(fetchSignInMethodsForEmail(error.email));
        break;
      default:
        dispatch(loginError("An error occured, please contact hello@mtgevent.com"));
        ConsoleHelper(error);
      }
  });
};

export const registerWithGoogle = () => dispatch => {
  dispatch(requestLogin());
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(provider).then(function(result) {
    var dbUser = {
      name: result.user.displayName,
      email: result.user.email,
      uid: result.user.uid,
      photoURL: null
    }
    dispatch(createUserInDatabase(dbUser, "Google"));
  }).catch(function(error) {
    dispatch(registerError(error.message));
  });
};

export const registerWithFacebook = () => dispatch => {
  dispatch(requestLogin());
  var provider = new firebase.auth.FacebookAuthProvider();
  firebase.auth().signInWithPopup(provider).then(function(result) {
    var dbUser = {
      photoURL: result.user.photoURL,
      name: result.user.displayName,
      email: result.user.email,
      uid: result.user.uid
    }
    dispatch(createUserInDatabase(dbUser, "Facebook"));
  }).catch(function(error) {
    dispatch(registerError(error.message));
  });
};

export const signInWithEmail = (email, password) => dispatch => {
  dispatch(requestLogin());
  return new Promise((res, rej) => {
    firebase.auth().signInWithEmailAndPassword(email, password).then(function(result) {
        dispatch(receiveLogin(result.user));
        dispatch(updateUser({"last_logged_in": new Date()}, result.user))
        firebase.analytics().logEvent('login', {
          method: "Email and password"
        });
        const tagManagerArgs = {
          dataLayer: {
            loginMethod: "Email and password",
            event: "userLoggedIn",
            eventCategory: "User"
          }
        }
        TagManager.dataLayer(tagManagerArgs);

        return res(result);
      }).catch(function(error) {
        switch(error.code) {
          case "auth/user-not-found":
            dispatch(loginError("There is no user with this email"));
            break;
          case "auth/invalid-email":
            dispatch(loginError("This is not a valid email"));
            break;
          case "auth/wrong-password":
            dispatch(loginError("Password is incorrect"));
            break;
          case "auth/user-disabled":
            dispatch(loginError("This account has been disabled. Please contact hello@mtgevent.com"));
            break;
          default:
            dispatch(loginError("An error occured, please contact hello@mtgevent.com"));
            ConsoleHelper(error);
          }
        return rej(error);
    });
  });
}

export const signUpWithEmail = (name, email, password) => dispatch => {
  dispatch(requestLogin());
  firebase.auth().createUserWithEmailAndPassword(email, password).then(async function(result) {
      var dbUser = {
        name: name,
        email: result.user.email,
        uid: result.user.uid,
        photoURL: null
      }
      await dispatch(createUserInDatabase(dbUser, "Email and password"));
    }).catch(function(error) {
      switch(error.code) {
        case "auth/email-already-in-use":
          dispatch(registerError("An account already exists with this email. Please sign in instead."));
          break;
        case "auth/invalid-email":
          dispatch(registerError("This is not a valid email"));
          break;
        case "auth/weak-password":
          dispatch(registerError("Password is too weak. Please choose a new one."));
          break;
        default:
          dispatch(registerError("An error occured, please contact hello@mtgevent.com"));
          ConsoleHelper(error);
        }
  });
}

export const resetPassword = (email) => async dispatch => {
  try {
    dispatch(resetPasswordError(null));
    dispatch(resetPasswordSuccess(null));
    await firebase.auth().sendPasswordResetEmail(email);
    dispatch(resetPasswordSuccess("An email has been sent to " + email + " with instructions to reset your password."));

    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "User reset password",
        eventCategory: "User"
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }
  catch(error) {
    switch(error.code) {
      case "auth/user-not-found":
        dispatch(resetPasswordError("There is no user with this email"));
        break;
      case "auth/invalid-email":
        dispatch(resetPasswordError("This is not a valid email"));
        break;
      default:
        dispatch(resetPasswordError("An error occured, please contact hello@mtgevent.com"));
        ConsoleHelper(error);
    }
  }
}

export const linkAuthProvider = (provider) => async dispatch => {
  dispatch(linkAuthProviderError(null))
  let providerToLink = null;
  if (provider === "facebook.com") {
    providerToLink = new firebase.auth.FacebookAuthProvider();
  } else if (provider === "google.com") {
    providerToLink = new firebase.auth.GoogleAuthProvider();
  }

  try {
    await firebase.auth().currentUser.linkWithPopup(providerToLink);
    dispatch(verifyAuth());
  }
  catch(error) {
    switch(error.code) {
      case "auth/credential-already-in-use":
        dispatch(linkAuthProviderError("The account you're trying to link is already associated with a different user account."));
        break;
      case "auth/popup-blocked":
        dispatch(linkAuthProviderError("Please allow popups in order to sign in"));
        break;
      case "auth/provider-already-linked":
        dispatch(linkAuthProviderError("This account is already linked to your user"));
        break;
      case "auth/email-already-in-use":
        dispatch(linkAuthProviderError("This email is already connected to another account"));
        break;
      default:
        dispatch(linkAuthProviderError("An error occured, please contact hello@mtgevent.com"));
        ConsoleHelper(error);
    }
  }
}

export const linkEmailAndPassword = (email, password) => async dispatch => {
  dispatch(linkAuthProviderError(null))
  let credential = firebase.auth.EmailAuthProvider.credential(email, password);

  try {
    await firebase.auth().currentUser.linkWithCredential(credential)
    dispatch(verifyAuth());
  }
  catch(error) {
    switch(error.code) {
      case "auth/credential-already-in-use":
        dispatch(linkAuthProviderError("The account you're trying to link is already associated with a different user account."));
        break;
      case "auth/invalid-email":
        dispatch(linkAuthProviderError("This is not a valid email"));
        break;
      case "auth/provider-already-linked":
        dispatch(linkAuthProviderError("This account is already linked to your user"));
        break;
      case "auth/email-already-in-use":
        dispatch(linkAuthProviderError("This email is already connected to another account"));
        break;
      case "auth/requires-recent-login":
        dispatch(setAuthBackdropOpen(true));
        break;
      default:
        dispatch(linkAuthProviderError("An error occured, please contact hello@mtgevent.com"));
        ConsoleHelper(error);
    }
  }
}

export const unlinkAuthProvider = (provider) => async dispatch => {
  dispatch(linkAuthProviderError(null))

  try {
    await firebase.auth().currentUser.unlink(provider);
    dispatch(verifyAuth());
  }
  catch(error) {
    switch(error.code) {
      case "auth/no-such-provider":
        dispatch(linkAuthProviderError("This auth provider is not connected to your account"));
        break;
      default:
        dispatch(linkAuthProviderError("An error occured, please contact hello@mtgevent.com"));
        ConsoleHelper(error);
    }
  }
}

export const createUserInDatabase = (user, method) => async dispatch => {
  // Function to get UTM-cookies from DB
  function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");
    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
  }

  // Check if user exists in DB
  let ref = firebase.firestore().collection("users").doc(user.uid);
  var doc = await ref.get();
  if (doc.exists) {
    dispatch(receiveLogin(user));
    ConsoleHelper("User already exists in database");
    dispatch(updateUser({"last_logged_in": new Date()}, user))
    dispatch(updateUserInMailerlite({"email": user.email, "last_logged_in": new Date()}))

    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "User logged in",
        properties: {
          loginMethod: method,
        },
        eventCategory: "User"
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  } else {
    let newUser = {
      uid: user.uid,
      email: user.email,
      name: user.name,
      photoURL: user.photoURL,
      premium: {
        current_period_end: null,
        cancel_at_period_end: false,
        status: null,
        start: null,
        customerId: null,
        subscriptionId: null,
        credits: 0
      },
      utm_source: getCookie("utm_source"),
      utm_medium: getCookie("utm_medium"),
      utm_campaign: getCookie("utm_campaign"),
      utm_content: getCookie("utm_content"),
      created: new Date(),
      last_logged_in: new Date()
    };
    ref.set(newUser, {merge: true})
    .then(function() {
      dispatch(receiveLogin(newUser));
      firebase.analytics().logEvent('sign_up', {
        method: method
      });

      const tagManagerArgs = {
        dataLayer: {
          genericEvent: true,
          event: "User signed up",
          properties: {
            loginMethod: method,
          },
          eventCategory: "User"
        }
      }
      TagManager.dataLayer(tagManagerArgs);
      dispatch(addUserToMailerlite(newUser));
      return "User created";
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
      return error;
    });
  }
}

export const reauthenticate = (provider, email, password) => async dispatch => {
  dispatch(setReauthenticateError(null));
  dispatch(confirmLoginRequired(false))
  let providerToLink = null;
  if (provider === "facebook.com") {
    providerToLink = new firebase.auth.FacebookAuthProvider();
  } else if (provider === "google.com") {
    providerToLink = new firebase.auth.GoogleAuthProvider();
  } else if (provider === "password") {
    try {
      let credential = await new firebase.auth.EmailAuthProvider.credential(email, password);
      await firebase.auth().currentUser.reauthenticateWithCredential(credential);
      dispatch(verifyAuth());
      dispatch(setAuthBackdropOpen(false));
      return;
    }
    catch(error) {
      switch(error.code) {
        case "auth/wrong-password":
          dispatch(setReauthenticateError("Incorrect password"));
          break;
        default:
          dispatch(setReauthenticateError("An error occured, please contact hello@mtgevent.com"));
          ConsoleHelper(error);
      }
    }
  }

  try {
    await firebase.auth().currentUser.reauthenticateWithPopup(providerToLink);
    dispatch(verifyAuth());
    dispatch(setAuthBackdropOpen(false));
  }
  catch(error) {
    switch(error.code) {
      case "auth/credential-already-in-use":
        dispatch(setReauthenticateError("The account you're trying to link is already associated with a different user account."));
        break;
      case "auth/popup-blocked":
        dispatch(setReauthenticateError("Please allow popups in order to sign in"));
        break;
      case "auth/provider-already-linked":
        dispatch(setReauthenticateError("This account is already linked to your user"));
        break;
      case "auth/email-already-in-use":
        dispatch(setReauthenticateError("This email is already connected to another account"));
        break;
      default:
        dispatch(setReauthenticateError("An error occured, please contact hello@mtgevent.com"));
        ConsoleHelper(error);
    }
  }
}

export const updateUser = (values, user) => dispatch => {
  Object.keys(values).forEach(key => values[key] === undefined && delete values[key]);
  firebase.firestore().collection("users").doc(user.uid).update({
    ...values
  })
  .then(function() {
    ConsoleHelper("User successfully updated!");
    dispatch(updateUserInMailerlite({"email": user.email, "name": values.name, "arena_name": values.arenaId}));
    dispatch(getUserData(user.uid));
  })
  .catch(function(error) {
      // The document probably doesn't exist.
      console.error("Error updating user: ", error);
  });
}

export const changeUsersCredits = (value, user) => dispatch => {
  const increment = firebase.firestore.FieldValue.increment(value);
  const userRef = firebase.firestore().collection("users").doc(user.uid);

  userRef.update({
    "premium.credits": increment
  })
  .then(function() {
    ConsoleHelper("User's credits successfully changed");
    dispatch(getUserData(user.uid));
  })
  .catch(function(error) {
      // The document probably doesn't exist.
      console.error("Error updating user: ", error);
  });
}

export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  firebase.auth().onAuthStateChanged(user => {
    if (user !== null) {
      dispatch(getUserData(user.uid));
      dispatch(setAuthData(user));
      dispatch(updateUserInMailerlite({"email": user.email, "last_logged_in": new Date()}));
    } else {
      dispatch(verifySuccess());
    }
  });
};

export const getUserData = (uid) => dispatch => {
  firebase.firestore().collection("users").doc(uid).get().then(function(doc) {
      if (doc.exists) {
        dispatch(receiveLogin(doc.data()));
        dispatch(verifySuccess());
      } else {
        // doc.data() will be undefined in this case
        ConsoleHelper("No such document!");
      }
  }).catch(function(error) {
      ConsoleHelper("Error getting document:", error);
  });
}

export const getCurrentUserProfile = (uid) => dispatch => {
  firebase.firestore().collection("users").doc(uid).get().then(function(doc) {
      if (doc.exists) {
        // SET CURRENT VISITED PROFILE
        dispatch(setCurrentUserProfile(doc.data()));
      } else {
        // doc.data() will be undefined in this case
        ConsoleHelper("No such document!");
        dispatch(setCurrentUserProfile("404"));
      }
  }).catch(function(error) {
      ConsoleHelper("Error getting document:", error);
  });
}

export const getFinishedEvents = (uid) => async dispatch => {
  try {
    let querySnapshot = await firebase.firestore().collection("users").doc(uid).collection("finishedEvents").get();
    let finishedEvents = [];
    querySnapshot.forEach(doc => {
      finishedEvents.push(doc.data());
    });
    dispatch(setCurrentFinishedEvents(finishedEvents));
  }
  catch(error) {
    ConsoleHelper(error);
  }
}

export const setNotifications = (notifications) => dispatch => {
  dispatch(setCurrentNotifications(notifications));
}

export const markNotificationsAsRead = (notifications, uid) => dispatch => {
  let batch = firebase.firestore().batch();

  for (let i = 0; i < notifications.length; i++) {
    let ref = firebase.firestore().collection("users").doc(uid).collection("notifications").doc(notifications[i].id);
    batch.update(ref, {
      read: true
    })
  }

  batch.commit().then(function (res) {
  });
}

export const deleteAccount = (user, customerId) => dispatch => {
  firebase.auth().currentUser.delete().then(function() {
    dispatch(signOut());
    if(customerId) {
      dispatch(deleteCustomerFromStripe(customerId));
    }
    firebase.firestore().collection("users").doc(user.uid).delete().then(() => {
      dispatch(deleteEvents(user.uid));
      dispatch(deleteLeagues(user.uid));
      dispatch(updateUserInMailerlite({"email": user.email, "type": "unsubscribed"}));
    })
  }).catch(function(error) {
    // An error happened.
    ConsoleHelper(error);
    if(error.code === "auth/requires-recent-login") {
      dispatch(setAuthBackdropOpen(true));
      return;
    }
  });
}

export const deleteCustomerFromStripe = (customerId) => async dispatch => {
  try {
    const res = await fetch(functionsDomain + 'deleteCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customerId: customerId
      }),
    });
    if(res.status === 200) {
      ConsoleHelper("Customer deleted");
    }
  }
  catch(e) {
    ConsoleHelper(e)
  }
}

export const deleteEvents = (uid) => async dispatch => {
  try {
    await fetch(functionsDomain + 'deleteEvents', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uid: uid
      }),
    });

  }
  catch(e) {
    ConsoleHelper(e)
  }
}

export const deleteLeagues = (uid) => async dispatch => {
  try {
    await fetch(functionsDomain + 'deleteLeagues', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uid: uid
      }),
    });

  }
  catch(e) {
    ConsoleHelper(e)
  }
}

export const signOut = () => dispatch => {
  dispatch(requestLogout());
  firebase.auth().signOut().then(() => {
    dispatch(recieveLogout());

    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "User signed out",
        eventCategory: "User"
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }).catch(error => {
    dispatch(logoutError(error));
  })
}

export const stripePaymentMethodHandler = (result, currentUser, stripe, billingDetails) => async dispatch => {
  ConsoleHelper(result);

  if (result.error) {
    dispatch(premiumPurchaseError(result.error.message));
    return;
  }

  // TODO: Set state to loading something here
  dispatch(requestPremiumPurchase());
  try {
    let res;
    if(currentUser.premium.customerId && currentUser.premium.status !== "active") {
      // This user is already a customer but doesn't have an active subscription
      // We should update their payment method and add sub, but keep the original customer
      res = await fetch(functionsDomain + 'updatePaymentMethodAndCreateSubscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerId: currentUser.premium.customerId,
          payment_method: result.paymentMethod.id,
          plan: proProductId,
          billingDetails: billingDetails
        }),
      });
    } else if (currentUser.premium.customerId && currentUser.premium.status === "active") {
      // This user is already a customer with an active subscription
      // We should not allow them to purchase another one
      dispatch(premiumPurchaseError("You already seem to have an active subscription. If this is not correct, please reach out to hello@mtgevent.com."));
      return;
    } else {
      // This is a new customer! Yay!
      res = await fetch(functionsDomain + 'createCustomerAndSubscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          billingDetails: billingDetails,
          payment_method: result.paymentMethod.id,
          uid: currentUser.uid,
          plan: proProductId
        }),
      });
    }
    if(res.status === 200) {
      const subscription = await res.json();
      ConsoleHelper(subscription);
      if(subscription.status === "active" && subscription.latest_invoice.payment_intent.status === "succeeded") {
        // Payment is completed, let's give access
        ConsoleHelper("Payment completed, setting access");
        let premium = {
          current_period_end: firebase.firestore.Timestamp.fromMillis(subscription.current_period_end*1000),
          cancel_at_period_end: subscription.cancel_at_period_end,
          status: subscription.status,
          start: firebase.firestore.Timestamp.fromMillis(subscription.start_date*1000),
          customerId: subscription.latest_invoice.customer,
          subscriptionId: subscription.id
        }

        if (currentUser.premium.credits) {
          premium.credits = currentUser.premium.credits;
        } else {
          premium.credits = 0;
        }

        dispatch(updateUser({premium: premium}, currentUser));
        dispatch(premiumPurchaseSuccessful());

        const tagManagerArgs = {
          dataLayer: {
            genericEvent: true,
            event: "Purchase",
            properties: {
              value: 5.99,
              product: "Pro"
            },
            eventCategory: "Purchase"
          }
        }
        TagManager.dataLayer(tagManagerArgs);
        dispatch(updateUserInMailerlite({"email": currentUser.email, "pro_status": "active", "pro_subscribed_date": new Date()}))
      } else if (subscription.latest_invoice.payment_intent.status === "requires_action") {
        // Payment needs additional action from user
        ConsoleHelper("Payment requires action");
        stripe.confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret).then(function(result) {
          if (result.error) {
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            dispatch(premiumPurchaseError("Your card was declined. Please try again or contact your bank."));
          } else {
            // Show a success message to your customer
            // Payment is completed, let's give access
            ConsoleHelper("Payment completed, setting access");
            let premium = {
              current_period_end: firebase.firestore.Timestamp.fromMillis(subscription.current_period_end*1000),
              cancel_at_period_end: subscription.cancel_at_period_end,
              status: "active",
              start: firebase.firestore.Timestamp.fromMillis(subscription.start_date*1000),
              customerId: subscription.latest_invoice.customer,
              subscriptionId: subscription.id,
            }
            if (currentUser.premium.credits) {
              premium.credits = currentUser.premium.credits;
            } else {
              premium.credits = 0;
            }
            dispatch(updateUser({premium: premium}, currentUser));
            dispatch(premiumPurchaseSuccessful());
          }
        });
      } else {
        // Payment didn't go through
        dispatch(premiumPurchaseError("Payment didn't go through."));
        ConsoleHelper("Payment didn't go through");
      }
    } else {
      dispatch(premiumPurchaseError("Oh no, something went wrong!"));
      ConsoleHelper("Oh no, something went wrong");
      ConsoleHelper(res);
      ConsoleHelper(res.body);
    }
  }
  catch (e) {
    dispatch(premiumPurchaseError("Oh no, something went wrong!"));
    ConsoleHelper(e);
  }
}

export const triggerCreditsPurchase = (clientSecret, paymentIntentId, stripe, elements, CardElement, volume, currentUser, billingDetails) => async dispatch => {
  dispatch(requestPremiumPurchase());

  let paymentMethod = elements.getElement(CardElement);
  let customerId = null;
  if (currentUser?.premium.customerId) {
    customerId = currentUser.premium.customerId;
  } else {
    let res = await fetch(functionsDomain + 'createCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        billingDetails: billingDetails,
        payment_method: paymentMethod.id,
        uid: currentUser.uid,
        clientSecret: clientSecret
      }),
    });
    const customer = await res.json();
    customerId = customer.id;
  }

  let updatedPaymentIntentRes = await fetch(functionsDomain + 'addCustomerToPaymentIntent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      customerId: customerId,
      paymentIntentId: paymentIntentId
    }),
  });

  const updatedPaymentIntent = await updatedPaymentIntentRes.json();


  const payload = await stripe.confirmCardPayment(updatedPaymentIntent.client_secret, {
    payment_method: {
      card: paymentMethod
    }
  });

  if (payload.error) {
    let error = "Oh no, something went wrong! Please try again later.";
    switch(payload.error.code) {
      case "card_declined" || "not_permitted" || "generic_decline":
        error = "Your card was declined. Please contact your bank."
        break;
      case "insufficient_funds":
        error = "Your card has insufficient funds."
        break;
      default:
        // code block
    }
    dispatch(premiumPurchaseError(error));
  } else {
    dispatch(premiumPurchaseSuccessful());
    dispatch(changeUsersCredits(volume, currentUser));
    dispatch(updateUser({"premium.customerId": customerId}, currentUser));

    const tagManagerArgs = {
      dataLayer: {
        genericEvent: true,
        event: "Purchase",
        properties: {
          product: "Credits",
          volume: volume
        },
        eventCategory: "Purchase"
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }
}

export const cancelSubscription = (customerId, user) => async dispatch => {
  dispatch(cancelSubscriptionRequest(true));
  try {
    const res = await fetch(functionsDomain + 'cancelSubscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customerId: customerId
      }),
    });
    ConsoleHelper(res);
    if(res.status === 200) {
      const subscription = await res.json();
      if(subscription.cancel_at_period_end === true) {
        // Subscriptions has been canceled
        ConsoleHelper("Subscription canceled");
        dispatch(cancelSubscriptionRequest(false));
        dispatch(updateUser({"premium.cancel_at_period_end": subscription.cancel_at_period_end}, user));

        const tagManagerArgs = {
          dataLayer: {
            genericEvent: true,
            event: "Subscription cancelled",
            eventCategory: "User"
          }
        }
        TagManager.dataLayer(tagManagerArgs);
      } else {
        ConsoleHelper("Hm... there was something wrong");
        ConsoleHelper(subscription);
        dispatch(cancelSubscriptionRequest(false));
      }
    }
  }
  catch(e) {
    ConsoleHelper(e)
  }
}

export const addUserToMailerlite = (user) => async dispatch => {
  if (process.env.NODE_ENV !== 'development') {
    try {
      await fetch(functionsDomain + 'addUserToMailerlite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: user
        }),
      });
    }
    catch(e) {
      ConsoleHelper(e)
    }
  } else {
    return;
  }
}

export const updateUserInMailerlite = (user) => async dispatch => {
  if (process.env.NODE_ENV !== 'development') {
    try {
      await fetch(functionsDomain + 'updateUserInMailerlite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: user
        }),
      });
    }
    catch(e) {
      ConsoleHelper(e)
    }
  } else {
    return;
  }
}
