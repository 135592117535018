
const initialState = {
  currentUser: {},
  isAuthenticated: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  verifyingError: null,
  signInError: null,
  signUpError: null,
  resetPasswordError: null,
  resetPasswordSuccess: null,
  notifications: [],
  currentUserProfile: null,
  isPurchasing: false,
  purchaseError: null,
  purchaseSuccessful: false,
  currentAuthData: null,
  linkAuthProviderError: null,
  confirmLoginRequired: false,
  isCancellingSubscription: false,
  currentFinishedEvents: [],
  isFetchingFinishedEvents: false,
  finishedEventsError: null,
  authBackdropOpen: false,
  reauthenticateError: null,
  redirectUrl: null
};

export default (state = initialState, action) => {
 switch (action.type) {
  case 'LOGIN_REQUEST':
    return {
      ...state,
      isAuthenticated: false,
      isLoggingIn: true,
      signInError: null
    };
  case 'LOGIN_SUCCESS':
    return {
      ...state,
      isAuthenticated: true,
      isLoggingIn: false,
      currentUser: action.user
    };
  case "LOGIN_FAILURE":
    return {
      ...state,
      isAuthenticated: false,
      isLoggingIn: false,
      signInError: action.error
    };
  case "REGISTER_ERROR":
    return {
      ...state,
      isAuthenticated: false,
      isLoggingIn: false,
      registerError: action.error
    };
  case "VERIFY_REQUEST":
    return {
      ...state,
      isVerifying: true
    };
  case "VERIFY_SUCCESS":
    return {
      ...state,
      isVerifying: false,
    };
  case "SET_AUTH_DATA":
    return {
      ...state,
      currentAuthData: action.authData
    }
  case "REQUEST_LOGOUT":
    return {
      ...state,
      isLoggingOut: true
    }
  case "LOGOUT_SUCCESS":
    return {
      ...state,
      isLoggingOut: false,
      isAuthenticated: false,
      currentUser: {}
    }
  case "LOGOUT_FAILIURE":
    return {
      ...state,
      isLoggingOut: false,
    }
  case "SET_CURRENT_NOTIFICATIONS":
    return {
      ...state,
      notifications: action.notifications
    }
  case "SET_CURRENT_USER_PROFILE":
    return {
      ...state,
      currentUserProfile: action.user
    }
  case "REQUEST_PREMIUM_PURCHASE":
    return {
      ...state,
      isPurchasing: true,
      purchaseError: null
    }
  case "PREMIUM_PURCHASE_SUCCESSFUL":
    return {
      ...state,
      isPurchasing: false,
      purchaseSuccessful: true
    }
  case "PREMIUM_PURCHASE_ERROR":
    return {
      ...state,
      purchaseError: action.purchaseError,
      isPurchasing: false
    }
  case "RESET_PASSWORD_ERROR":
    return {
      ...state,
      resetPasswordError: action.error
    }
  case "RESET_PASSWORD_SUCCESS":
    return {
      ...state,
      resetPasswordSuccess: action.message
    }
  case "LINK_AUTH_PROVIDER_ERROR":
    return {
      ...state,
      linkAuthProviderError: action.error
    }
  case "CONFIRM_LOGIN_REQUIRED":
    return {
      ...state,
      confirmLoginRequired: action.state
    }
  case "CANCEL_SUBSCRIPTION_REQUEST":
    return {
      ...state,
      isCancellingSubscription: action.state
    }
  case "SET_CURRENT_FINISHED_EVENTS":
    return {
      ...state,
      currentFinishedEvents: action.events,
      isFetchingFinishedEvents: false
    }
  case "REQUEST_FINISHED_EVENTS":
    return {
      ...state,
      isFetchingFinishedEvents: true
    }
  case "ERROR_FETCH_FINISHED_EVENTS":
    return {
      ...state,
      finishedEventsError: action.error,
      isFetchingFinishedEvents: false
    }
  case "TOGGLE_AUTH_BACKROP_OPEN":
    return {
      ...state,
      authBackdropOpen: action.state
    }
  case "SET_REAUTHENTICATE_ERROR":
    return {
      ...state,
      reauthenticateError: action.error
    }
  case "SET_REDIRECT_URL":
    return {
      ...state,
      redirectUrl: action.url
    }
  default:
   return state
 }
}
