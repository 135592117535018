import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { Container, CircularProgress, Box } from '@material-ui/core';
import firebase from "../../firebase.js"
import {Helmet} from "react-helmet";

import NoSuchPage from "../../Components/NoSuchPage";

import EventInfo from "../EventInfo/EventInfo";
import EventSettings from "../EventSettings/EventSettings";
import Pods from "../Pods/Pods";
import Round from "../Round/Round";
import Results from "../Results/Results";
import EditEvent from "../EditEvent/EditEvent";
import SingleElimination from "../SingleElimination/SingleElimination";

import { getCurrentEvent, setCurrentEvent } from "../../Actions/eventActions";

class Event extends Component {

  constructor(props) {
    super(props);
    this.state = {
      noSuchDocument: false
    };
  }

  componentDidMount() {
    this.fetchCurrentGame();
  }
  componentDidUpdate(prevProps, prevState) {
    // If we have recieved props, and the ID is not the same we need to fetch new data
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchCurrentGame();
      // Also, make sure that we no longer serve a 404 (if the previous ID was faulty)
      this.setState({
        noSuchDocument: false
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.props.setCurrentEvent({});
  }

  fetchCurrentGame = () => {
    // We need this otherwise "this" is out of scope further down
    let that = this;
    // If this is not defined, we're not listening yet
    if(that.unsubscribe) {
      // If it is, we need to stop listening
      that.unsubscribe();
    }
    let eventId = this.props.match.params.id;
    // Start listening for changes
    this.unsubscribe = firebase.firestore().collection("events").doc(eventId)
    .onSnapshot(function(doc) {
      if(doc.data()) {
        // If we find data, update state
        that.props.setCurrentEvent(doc.data());
      } else {
        // If we don't, show a 404
        that.setState({
          noSuchDocument: true
        });
      }
    });
  }

  render() {
    if (this.state.noSuchDocument === true) {
      return (
        <NoSuchPage />
      )
    } else if(Object.keys(this.props.currentEvent).length === 0) {
      return(
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh" width={1}>
          <CircularProgress />
        </Box>
      )
    } else {
      return (
        <Container>
          <Helmet>
              <meta charSet="utf-8" />
              <title>{this.props.currentEvent.title} | MTG Event</title>
          </Helmet>
          <Route exact path='/events/:id' component={EventInfo}/>
          <Route exact path='/events/:id/settings' component={EventSettings}/>
          <Route exact path='/events/:id/pods' component={Pods}/>
          <Route exact path='/events/:id/round/:round' component={Round}/>
          <Route exact path='/events/:id/results' component={Results}/>
          <Route exact path='/events/:id/final-results' component={Results}/>
          <Route exact path='/events/:id/edit' component={EditEvent}/>
          <Route exact path='/events/:id/single-elimination' component={SingleElimination}/>
        </Container>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentEvent: state.eventReducer.currentEvent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCurrentEvent: () => {
      dispatch(getCurrentEvent());
    },
    setCurrentEvent: (eventData) => {
      dispatch(setCurrentEvent(eventData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);
