import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { Grid, Container, Button, Typography, Paper, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles} from '@material-ui/core/styles';
import ConsoleHelper from "../../ConsoleHelper.js"
import logo from "../../logo.png";

import { signInWithGoogle, signInWithEmail, signUpWithEmail, signInWithFacebook, setRedirectUrl } from "../../Actions/authActions";

import SignInForm from "../../Components/SignInForm";
import FacebookButton from "../../Components/FacebookButton";
import GoogleButton from "../../Components/GoogleButton";

const styles = theme => ({
  grid: {
    minHeight: "100vh"
  },
  paper: {
    padding: '30px',
    textAlign: "center"
  },
});

class SignIn extends Component {

  signInWithGoogle = () => {
    const { dispatch } = this.props;
    dispatch(signInWithGoogle());
  }

  signInWithFacebook = () => {
    const { dispatch } = this.props;
    dispatch(signInWithFacebook());
  }

  signInWithEmail = (email, password) => {
    const { dispatch } = this.props;
    dispatch(signInWithEmail(email, password)).then(result => {
      ConsoleHelper(result);
    }).catch(error => {
      ConsoleHelper(error);
    });
  }

  signUpWithEmail = (email, password) => {
    const { dispatch } = this.props;
    dispatch(signUpWithEmail(email, password));
  }

  componentDidMount() {
    const { dispatch } = this.props;
    let redirectUrl = this.props.location.state?.from.pathname || null;
    if (redirectUrl) {
      dispatch(setRedirectUrl(redirectUrl));
    }
  }

  render() {
    const { classes, isAuthenticated } = this.props;
    if(isAuthenticated) {
      return <Redirect to="/" />
    } else {
      return(
        <Grid
          className={classes.grid}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Container maxWidth="sm">
            <Box my={2}>
              <Paper className={classes.paper}>
                <Box mb={2}>
                  <img alt="MTG Event logo" style={{maxWidth: 100}} src={logo} />
                </Box>
                <Typography variant="h3">Sign in here</Typography>
                <FacebookButton onClick={this.signInWithFacebook} text="Sign in with Facebook"  />
                <GoogleButton onClick={this.signInWithGoogle} text="Sign in with Google"  />
                <Typography component="p">Or sign in with your email</Typography>
                <SignInForm onSubmit={this.signInWithEmail} />
                {this.props.signInError &&
                  <Alert severity="error">{this.props.signInError}</Alert>
                }
                <Box mt={3}>
                  <Box>
                    <Button component={RouterLink} to={"/reset-password"} color="secondary" href="/reset-password">I forgot my password</Button>
                  </Box>
                  <Box mt={1}>
                    <Button component={RouterLink} to={"/signup"} variant="outlined" color="secondary" href="/signup">Create account</Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Container>
        </Grid>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.authReducer.isLoggingIn,
    signInError: state.authReducer.signInError,
    isAuthenticated: state.authReducer.isAuthenticated,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(SignIn));
