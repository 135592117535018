import React, { Component } from 'react';
import { connect } from "react-redux";
import { Box, Button, Typography, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from "react-helmet";

import { getCurrentUserProfile, setCurrentUserProfile } from '../../Actions/authActions';

import ProfileStats from "../../Components/ProfileStats";
import NoSuchPage from "../../Components/NoSuchPage";

class Profile extends Component {

  componentDidMount() {
    let userId = this.props.match.params.uid;
    this.props.getCurrentUserProfile(userId);
  }

  componentDidUpdate(prevProps, prevState) {
    // If we have recieved props, and the ID is not the same we need to fetch new data
    if (prevProps.match.params.uid !== this.props.match.params.uid) {
      this.props.getCurrentUserProfile(this.props.match.params.uid);
    }
  }

  render() {
    let isPremium = false;
    if (this.props.currentUser.hasOwnProperty("premium")) {
      isPremium = (this.props.currentUser.premium.status === "active");
    }

    if(this.props.currentUserProfile && this.props.currentUserProfile === "404") {
      return (
        <NoSuchPage />
      )
    } else if(this.props.currentUserProfile) {
      return(
        <Container>
          <Helmet>
              <meta charSet="utf-8" />
              <title>My stats | MTG Event</title>
          </Helmet>
          <Box mb={4}>
            <Typography variant="h1">My stats</Typography>
            <Box mt={1}>
              <Typography variant="body2">Studying your stats will help you grow your game. Are you better in Draft or Sealed? Why? Maybe Constructed is your forte. Find out here!</Typography>
              <Typography variant="body2">Stats are not calculated for single-elimination events.</Typography>
            </Box>
          </Box>
          {isPremium ? (
            <Box>
              <ProfileStats uid={this.props.match.params.uid} player={this.props.currentUserProfile} />
            </Box>
          ) : (
            <Box width={1}>
              <Box mx={"auto"} textAlign="center">
                <Typography>Stats is a Pro feature. For $5.99/month you get access to your historical record, MW%, GW% and more across all event types, or just the ones your interested in. Of course you also get all the other Pro features. </Typography>
                <Box mt={2}>
                  <Button component={Link} to={"/pro"} color="primary" variant="contained" size="large" href="/pro">Read more & get Pro here</Button>
                </Box>
              </Box>
            </Box>
          )}

        </Container>
      )
    } else {
      return (
        <Box width={1} height={"100vh"} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authReducer.currentUser,
    currentUserProfile: state.authReducer.currentUserProfile,
    isFetchingStats: state.statsReducer.isFetchingStats,
    stats: state.statsReducer.stats
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCurrentUserProfile: (uid) => {
      dispatch(getCurrentUserProfile(uid));
    },
    setCurrentUserProfile: (user) => {
      dispatch(setCurrentUserProfile(user));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
